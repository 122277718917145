import React from 'react';
import PropTypes from 'prop-types';

const IconLambda = ({ customClass }) => (
  <svg className={customClass} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
    <title>Lambda Icon</title>
    <image id="image0" width="256" height="256" x="0" y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAY
u0lEQVR42u3deXxU5bkH8PfMZA8JCZBAyJ5MgFrtYivWq1W0VWuL1Sqt2sXaSmVTNmWTRUBEkD0g
6K1eq9Z7aatctVWL3mqXq1K11Wpxyb6QBQjZ92Wmf6CVYfK8c87JnPOcmff3/Xz8xye8eWaAHydn
3vO82ke3JPoEACjJxd0AAPBBAAAoDAEAoDAEAIDCEAAACkMAACgMAQCgMAQAgMIQAAAKQwAAKAwB
AKAwBACAwhAAAApDAAAoDAEAoDAEAIDCEAAACkMAACgMAQCgMAQAgMIQAAAKQwAAKAwBAKAwBACA
whAAAApDAAAoDAEAoDAEAIDCEAAACkMAACgMAQCgMAQAgMIQAAAKQwAAKAwBAKAwBACAwhAAAApD
AAAoDAEAoDAEAIDCEAAACkMAACgsiruBcJL4+ekic+5+7jYs0fX+y6Ju17e52wCb4QpAp7RrN0bs
X34hhEg84xIx6cFO7jbAZggAnVIvm8/dgi1ic7/E3QLYCAEAfnLv/BN3C2AjBAAEcCelc7cANkEA
QIDCLeXcLYBNEAAQSNOE5o7m7gJsgI8BR+j4k6tEy0s7udswJWPWEyLp7KuGrRXtbREls0ZxtwgW
wxWAwhoe/IG07kpI5W4RLIYAUFxv9d/Jmmd7DXd7YDEEgOJqNl5IFzVNaG78lBjJEAAgeiveJGue
XUe52wMLIQBA1Gy+mKxp0bHc7YGFEAAghBBisK2RrBXta+VuDyyCAAAhhBAVSz1kTXNFCSE07hbB
AggA+DdvbwdZ8+ys424PLIAAgH8rW5BB1lzxydztgQUQAOBnqLuNrOWt/Rt3exBiCADwU744m6zF
ZEzmbg9CDAEA/nxeIbxDZDn/3g+5O4QQQgBAgNL548la9Jgs7vYghBAAEMA30HvySoCQvfRl7hYh
RBAAMKyS2fRd//jCqdztQYggAIDkk9wLyLj5F9ztQQggAIAk2xeQNHUGd3sQAggAIPn6u6X1jJmP
crcII4QAAKmq1V8ga0nnXMvdHowQAgCk+o+VSeuply3mbhFGAAEAQZXfkU/W0q5dz90ejAACAIIa
6jguradcMpe7RTAJAQC61G65nKylX3cfd3tgEgIAdOkpe1VaT5p6A3eLYAICAHSr3/d9spZx88+5
2wMTEACgW+c7z0rr0eMncbcIBiEAwJC6+68ja/nr/25gJXACBAAY0vXuc9J6XMFXuFsEAxAAYFjz
C9vIWs6y/+NuDwzQProl0cfdRDiY9GDnsP/f290mvH2dBlfTx+fziRPP3iPaX3+c++Xrfj+EEKJi
2WQx2IopwuEAAaCT7A+8HZx2VHfaNRtE6uULiapPlMxK4m4RdMCPAGEi42ePcbfg5/iBVZKqJrTo
eO4WQQcEQJhI+vI13C0EaD/0K7JWtOe4gZWACwIgjIz/0f3cLfhpfORmad09Ko27RQgCAaBT13sH
uVsQoy/4MXcLAXor3yJrhdsquduDIBAAOtXtuVa0vvwgdxsi7brt3C34qdk0jbsFGAF8CuBAsRM/
K3Lv+itZd9onAtm3HxTxk84ftubr7xGlt+FHAafCFYAD9dUfltZHn++sHwVqt9GPCmsx+DTAyRAA
DlWxpJCsjb/RWTcDhRBiUDI0pGhfm4GVwE4IAIcabD8qrSdfcBN3i34qlhSQNc3lFkJzc7cIw0AA
OFj9Az8kaxN+tIe7PX8+nxjqbiXLnl313B3CMBAADtb59tPSerznq9wt+ilfRB8c6opN5G4PhoEA
cLiGh35C1rKXvMDdXgDZvYC8u9/lbg9OgwBwuI43fyOtx2R8hrtFPxWSEeIx6QUGVgI7IADCwLH9
S8la3to3udsL4BsaJGuFW6u424NTIADCQOsre6X12KzPcbfop2x+OllzJ43jbg9OgQAIE62v0FN3
c1e/xt2eH99gvxA+L1nPWf4n7hbhYwiAMHFs/yJp3RWfzN2in5LZdD9x+V/ibg8+hgAII62v0A8j
eXY4cASXj37MJGPWE9zdgUAAhJVj+2+ni5rmuM/ayxZmkrWks6/ibg8EAiDsdJfQR3R5io8aWMl6
3t52aT1j1n9zt6g8BECYOSJ58k4I5z19V3nnmWQt6exvc7enPARAGOqtfpuseXY1crfnZ+BElbQ+
dvpK7haVhgAIQzUb6WcATj5556zf1rIFE8na2CtXcLenNGf9SQHdemvfI2tOuwoIdi8g9dL53C0q
CwEQpmo2nEfWXLEJjrsKqN54IVlLm7GRuz1lOetPCRgy1NVK1iY90K5/IRv0VctPDk4+/ybuFpWE
AAhj5Yuz5F+gadwt+qnbM4OsTbjRYQNOFIEAYBKbeaYo3HFEeHbUibhc81tjh7payFrh1mrul+mn
673fy9+TnLO5W1QOAoDBxHlPitw1h4Q7IUW4EkaLnDv/JLIW/97UWuWLs8mae9QY7pcaoG7P98ha
7so/c7enHASA7TQx6nPfCPi/CZMvMH3J7u3rImt5dzlrXkDXe89L6/FFF3C3qBQEgM0KtlSQtaLd
5g7ULFtIf84eM9FZE4OEEKLp6Q1kLfsOc1dCYA4CwGZRyfQpOVp0nLlFvUPScv6mj7hftp/mFzZJ
6zHpHu4WlYEAsFHGzb8I+jW5a94wtXbZ/AlkLTo108BK9jjx/Baylnf3O9ztKQMBYKOkqTOCfk1s
5hmm1vb2dQqfl57Fl3nbM9wv38+JZ9ZJ65o7mrtFJSAAbJJ+ww7dX5uz/I+mvkfpXPquf+KZX+N+
CwK0/eUxsla0t8XASmAWAsAmKdN+pvtr4/K/bO6b+LzSWXwZMx/hfhv8HP3lXGndnZSucyUwCwFg
g8SzvmX41+Td9TdT36tsIb0vIOmc73K/FQF6K+ktwoVbKwysBGYgAGyQeeuvDP+amImTTX0vb6/8
JN4JP/0v7rfDT82mC+Vf4IribjGiIQAsFnRji+QjvPQfFJv6npWrv0DWks/9nv6FbNL9wR/JWtFu
Z405izQIAIvJNrY0PPwzUTI3laynXPhTU99z4FiZtJ5y8Rzut8XPkZ3TyZoWFcvdXkRDAFgoekyu
tN7xxv9Ib9oJIUT693eZ+t7lSwrpNa/fYmAle/QfryRrRfvaDKwERiAALJR/7z/JWvMpG2Gq1k0l
vy7loptNfe+hdvmlc8rFc3WuZI+qVWeRNc3lxr4AiyAArKJpQgj64Z6mUzbC9Ne/L11qzOVLTLVQ
V/wdspZ+/X28788wZI82e/Y0cbcXkRAAFpkkuWxtf/OpgP9Xvf4r5NePu+YuUz10HX5JWh/1xat5
3hyC7NFmzeXmbi8iIQAsoEXFSmfyNT7044D/11f3T9mSImnqdaZ6qd97A1mbOPuXTO8QbbCNHmia
f89h7vYiDgLAAp7dx8jaQPMRslazcRpZy7j5YVO9dP7jt9J6bNZZOleyR8VS+knA6HG5BlYCPRAA
FpBdrlaumELWeqvfkq6bdI65q4CGB28ka7mrX7f53dFBcqiobJ4CGIcACLG8dfSpPQNNNUF/feOj
9N35jJnmrgI6/n5AWo/Ln6pzJXuU3kbPTIhKxvMBoYQACLGYCUVkrXJl8Ed92197TFqPyTA34af5
RXo/Qc7yl216d/TxDfRK90fkrHyNu8WIgQAIofwN7418ESFEw8MzyVreWnMz/pqekp/B50501gDR
ktnJZC0u53Pc7UUMBEAIRaflk7XS2/Rfuna8sV9aj5lg7kGh5oM7yVrhNmeNEBdCCN/QAFmbOMf4
A1YQCAEQIunflzy44/MJX3+3ofVOvLCdrOWtM/eocNOBVXRR04QrfrSl75FRZQsyyNqoLxh/xBoC
IQBCJOUi+sGdkjnJBlY66cTTa6T16HH5Olfy1/kOPZbbs7POonfHHN9Ar7Seteg57hbDHgIgBMZc
EWSrruRjLZm2/6dvCObfY+5+Q/0++ePA7sSxoX1zRqhiBX3TM2HKRdzthT0EQAiMu5reqlu19hzT
6x59XP7AjhaTYGrdnlL6s//C7c66FzDYXCutj7t6nc6VYDgIgBFKPONyab2/4YMRrd9+iL4hWFRs
blhG7dZL5V/gsENFS+fRn1CMueJ27vbCGgJghDIXPEXWjuz6joGVhtf4CP2R4Mkbd8bvLwghRE8Z
fRVQVHzMwErW8w32S+uplyMEzEIAjEB0WqG03v3+SzpXkuurox8X9uysN7Vm7Rb6KkCLiZc+zMSh
5p6vkrW0a/BjgFnO+l0OM/kb/kHWGh+dF7LvU71evlXX7LAM2fP3kx5oD1n/odBb87a0njJtFneL
YQkBYFJUivy4rfbXHg3p9+urpe/6e0zeCyi/PUf+BQ67F1BXfA1ZS79hG3d7YQkBYFLB5g/JWvtr
T4T8+1VvOI+saVEx5hb1+cRQB30isWf7EQOLWa/r8IvSenzh+dwthh0EgBmaS8jGfTU+as3lqGxw
ZuEOc39Zy++gNxS5Epy1M1AIIWq3fpOsZS89yN1e2EEAmOAppqfWdL5r3fn2ssGZ7oQU0zfuZPcC
8u9+17LXY0ZP6Z+l9fjJ2BxkBALAKJdbuCQbcOrvD34C8Ej4BvrIWtH95gZnyu4FRKcXWPp6zDj+
1Gqylr0Y24ONQAAYNGlvK1nzdls/v142LENzxwjZjyYkn0/4JCcUFZr8qNEqLS/KT1qOGV+kcyVA
ABgluTNetijL+u/v88ov2Td9ZGrZMsnjym6Tm42s1PTsPWQtb/3bBlZSGwLAgJxV9CSaoc4TQghz
D/0YJRufHZ060dSavsE+4Rukn7/PXuasqUHNz90rrZt9TkI1CAAD4rLpSTTlt9s7sVb2qGzumjdM
rVk6jz6nML7AWXMDhRCi5eX/JGtFu521ndmpEAA6ZS36HXcLfmQThmIzg88eNGPiXGdN4Tn+q8XS
etToCdwtOh4CQKeEKdPImmxyjWWCHCqat/4fOhc67bUspO9jjPq886bwdH/wClkruK/MwEpqQgDo
kDZjs7Tu7e1g6UsWPDHjCw2sdMpr6WmV1jNm79e3kE2O7LxSWtei47lbdDQEgA6pl9IP9pQv4fuc
3NvbIb0SyJj5mIHVPlV552fJWtIXp7O9XkrX4T+QtaLd5p6TUAUCIIikc6+X1ofaeW82ld5K7wtI
OucaAyt9auCEfCrQ2CtX6lzJHnXFV9FFhz3W7DR4d4LI+OlDZK1q7bnc7QnfYJ+0PuEnPze1btlC
+uPEsdNXcL/sAP0NJWRNdlKz6hAAEglTLpbW+xuccVptxXJ6cGbyV24wsNKnvD3yeQBjvnEH98v2
U7X2bLrocp/8DwIgACSyFtEn6zY+Ooe7vX8bbKkd+SLDqNl0CVkb95213C87gGyHJPYFDA8BQHDF
Jkrr7a89zt2i5Xor5RuKks/7EXeLfmQ7JLWoWO72HAkBQJA98nv8yVUGVrLe+B/uIWvB7hEEI/uY
bcJN+7hfeoDB1gayln/vhwZWUgMCYBjuUeOE7Km6lpd2crfoZ/RXbyJrI92kJNtoI4QQcQX8N0JP
VbGMfhIweowND2uFGQTAMAq3VZG1rvdf0b+QDZLPv0laDzZSW4+64mvJWs6yPxhYyR6ymQmFW6u4
23MUBMDpgtwtrtt1pc6F7DHhRvryv/ru/wjJ9+g6LB+1lTB5Gvfb4KdsAf0MgDtpHHd7joIAOI1s
EGZP2SHu9vxEjcmW1vuOhG6cV9MzG8ha1mJnPSjlGxoQQjLgJH+DuXMVIxEC4DSu+CSyVrvl69zt
+Sm4lz527EixuV2AlObnN0nrwQ5JsVvJHHqgaXSauZOVIxEC4BQeyWTdoc5m7vb8uOJTpPXuICO0
zTjx241kTXZIChfZ/Y/M2w5wt+cICIBTuBJSyJrdAz+C8eygN/90vPWUgZX0O/G7jdK6K95ZY8RL
b6V/3k888zLu9hwBAfCxnBX0uOmTAzPtGfelm2Q2YcPPf2zZt217ld4A5dlZx/qWBAgyMyFz4W91
LhS5EAAfi8uj95KXznHWv2wFm0vJWm/l3yz93kcfk2+BdtoUnorlk8la4mcuNrBSZIribsAJxt+4
ly76HPYvvxAiKoXe3FOzyfqDMbpLXhUJk4Y/hqvgvjJRMmsU23tzusEW+VXJxLm/ET0f/tHU2u2v
PyGGggxQcTrto1sSnfcn3GaTHuwka2ULMtgm/gwnd9XrIjabPiHIrr98svesZPZoIXxDBlazlisu
SXh2NYx8Ier1OijwjFL+R4CxV66W1p30l18IIf3LXzo31cBKIyOfwuOsJ++s/j2UhaHTIQCmLyNr
1euctc99zBVLyJrPO3RyA4xNZFN4tOhYx03iqVrzJe4WHMlZv0s2i/fIt8r21Ttj4Mcnxl19F1kr
Z5hMPNBUQ9YmPdBuYCXr9R81d2JSpFM6ALKX0JtlQrWPPlQSz/qmtO7t77a9p8qV8vMHNHe07T3J
1Gx21k5OJ1A2AOLy5SfdhHIffShk3vprsnZk51UGVgot2fP3nmJnTeTtrTgkmp65m7sNR1H2Y8Cc
5fRZd8efWsPdnp+Tp/7Suj/geyS3YlkReRNMi4oxuJr1mp/fLJqf32zq16Zft02kXDKL+yWElJJX
AK64JGm95cXt3C36KdpLP4dw7JcLuNuTXgUUbMIUHidTMgBkD/00Hyzmbs9PsJNtWv/yMHeL0ik8
UamYwuNkygWAFh0nHfrRdOBO7hb9eIrpf117Kt7kbk/na3DWvgD4lHIBULSniaz1lJs7VttKmou+
TVO72Tl72UvnjSVrrtgE7vaAoFYABBn3VXvfJToXskfu6r+Stf7GUgMrWc832CedQJyz6nXuFmEY
SgWAbNzXwPFK7vYCxGbRh3RW3fVF7vYCyK4C4iRbmIGPUgEgG/dVucpZf0CzFj3P3YIpvqFBspa5
4Bnu9uA0ygRA7hr653unjfsSQoiEKReSNdnBndzKF9JbkhPP+Bp3e3AaZQIgNpPetlp+ew53e36S
zvkuXfT5gh7cycnb3yOtZy97WedKYAclAiBnxV+4WzAkY+YjZK1sUbaBlXhULPWQtfiCqQZWAqsp
EQBxefQNM9khEiy9Fp4nrXvDYALNYFujkM1QTJtxH3eL8LGID4Cx01fSRZ9XeHudNcwhZ+lLZK3h
kfDZh14yO5mspV46l7s9+FjkB8CVK8jaSA/OtFvHoSe4W9AvyCzFsd921gNXqoroAEj52jxp3dvX
xd2in6K9LWSt+bkt3O0ZJpupMPZbS7nbAxHhAZD+Pfqxz9otl3O350dzR0kHaDQ9u467RcOCzVRI
/fp87haVF7EBEOysup6yV7lb9OPZRQ/PGGx11mANI2q3XkHW0r670cBKYIWIDQDZWXV1u2dwtxdA
i44laxXLnHXwphE9pfKPYBM+eyl3i0qLyACIGV8krXf98/fcLfrJWvQcWRtsbeRub8RqJbP4sub/
L3d7SovIAMhb/zZZa37BWdN+hBAiYQp9mo9s2Ea46Kk4JK0nnnWFzpUg1CIuAIKN+2p62lkfP0mP
JRNCOO5QUpOO7afv+mfe+hvu9pQVcQFQKBn31fGG8/6gjT7/RrJWsTT8//X/ROsr8qCLyfgMd4tK
irgA0CRDPxoe/gl3e37iCuQnDw22WXeeHYfjv15O1vLWhsd4s0gTUQHg2X2crPVWvsXdXoCcZfQ4
74rlU7jbC7mWP+yR1t2JY3WuBKESOQHgcgtXDD1Bt2bTNO4O/QS75B1sOaJzpfDSfHA3WSvcXs3d
nnIiJgA8O+rJmm+wn7u9ALJL3qYDa7nbs0zTgRXSelSq8x93jiQREwCuuESyVjpvDHd7hjQf3Mrd
gqU63/kdWSvY9AF3e0qJiACQzc/zdrdxtxfAs4ve3NP26uPc7Vmuft/10roWgzHidomIAJDOz1uU
yd2eP00TrrhRZPnoY3O4O7RF59v0VUBRcfjvfgwXYR8Ambce4G7BEM9O+l6Ft6eDuz3b1D8guQrQ
XEHPcIDQCPvTgRPPukxap06udSLHXa1YrO/IYfLsg0n72kTDQ87atxE93jPyRRwmrANg9IW3cLcQ
Mt7eDiF8Xu42bFV997nSgJYNR4XQCOsfAcb/wHkP9phVtlCtf/0/MdgcmfsdwkVYB0BEUexf/09U
rIi8HY/hBAHgAFWrnXfOn50GTtRwt6CssA6AxodncrcQEv3HnHXSr90q7zxj5Isw6m8o4W7BtLAO
gPY39ovard/gbmME/T8pSmaNGvlCEaBy9ee5WzBlqKtFVK09m7sN07SPbkmMjIkTAGBYWF8BAMDI
IAAAFIYAAFAYAgBAYQgAAIUhAAAUhgAAUBgCAEBhCAAAhSEAABSGAABQGAIAQGEIAACFIQAAFIYA
AFAYAgBAYQgAAIUhAAAUhgAAUBgCAEBhCAAAhSEAABSGAABQGAIAQGEIAACFIQAAFIYAAFAYAgBA
YQgAAIUhAAAUhgAAUBgCAEBhCAAAhSEAABSGAABQGAIAQGEIAACFIQAAFIYAAFAYAgBAYQgAAIUh
AAAU9i/IOJYSfB9gRwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wNS0xM1QyMzo0NTozMCswMDow
MCHbLv8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMTNUMjM6NDU6MzArMDA6MDBQhpZDAAAA
AElFTkSuQmCC" />
  </svg>
);

IconLambda.propTypes = {
  customClass: PropTypes.string,
};

export default IconLambda;
