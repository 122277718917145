import React from 'react';
import PropTypes from 'prop-types';

const IconStorybook = ({ customClass }) => (
  <svg className={customClass} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="280px" height="280px" viewBox="0 0 280 280" enableBackground="new 0 0 280 280" xmlSpace="preserve">
    <title>Storybook Icon</title>
    <image id="image0" width="280" height="280" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARgAAAEYCAYAAACHjumMAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAg
oUlEQVR42u3deXzV1Z3/8de5uUluNkgAIWzKKgouqGAVF1LRqq1r26m11do6VTujY6fW6WJnmqTT
Wh1rOx2trdpfrbVuY12qFcWCgvuouIEgq4IssoQlQPZ7z++Pk0AkgWz33s/5fr+f5+NxTQS99/O9
Sd452/ccUEoppZRSSimllFJKKaWUUkoppZRSKviMdAFKRUnlxHNyad5VjDXFYIuBBNg8rGnGUEec
VdXvz6mXrjNdNGCU6oPKyspYVVVVGQ2UcP6NwyBVDpRjbDnWlIMtB1OKteUYU461Jft/RmOBJ4H/
MrOue0H6+vpKA0ZFQmVlZQwoAlpbDRTv45GoqKgorqio6PTvOvmzIiCHltQOzrqhpEdFde0jjjjo
nqrH7vpF9bI5W6Xfw97QgFGBZi/4VQm7mstJtuxpMRjKsbZ0979fMn04F55wUMaLOeP6FiCe9uc1
1FatmHNi9bJnF2T8GtIs/W+GUnupPOasGNvrExhTDKnW8QfcGIQ1xRgSWFsMprhi2PiSiqKRRWDa
/j4BthhDMXZ3q6EE13IoYltdbM8r2U98aPfJ9qxcqKERm4GfKUu/qjEz/kbKXFS9Yk6guk0aMKpX
7OW35/LRjmHY5sEY68YdrCmFVLuWhCnHMgxsKYN68uS7/0HH0OiFeKwlK29KLNZCMpWpZz+watwp
z2OYWr18zhtZuZ400IBRu+3ubrQkS/eEBi4s9oRGKdYOY9Xm0j3/496ftIVCX1IhjXJiGfup/4SY
SZLM7EtUjT31axowKmOml0+IVRSPaN/d2DPwaG0xxhS3dTcwFFZNOr2QpmQxNrVnatR1N/YesOzH
trqc3S/UIRs8C42eyI1n+Me+VTwnRXOmXyr1Nfvn5+82F508PyvX1Ne3RLoA1dbdqCnFxMohWU7K
lIMp393dMLhpTkw52MH05OvW0Nz+lT7xITLysvRtnpdjyfwKlv78+YWHrbWjjTHefyU1YHqgctLp
eTSnikml9vrtb9u1DkwCa0uqjjm7iNp6NzBpbGsLo3XAcu+pzlWbC9wrtP32s3yiu9Fx0FL1RH48
O29ctoLMchBnXn8NcHN2XrD3Ih8w9tzflZCs7TjNmaIMGNK6UKoc7DDcOoju2V7X7kU6fKKyKZEX
6/uTdEN+bhaXfZj/rDzmrF9Vz/9bdsaXeikQAdOjac4DJhZXlJYXdzHNuWfhVENN7p5X2lcXQoMh
0BK5OX1/km7Iz9LrAFhbwPb6kcCqrL1mL3gbMPacm6fQ1PQVbGo6cLToNKcKtkRedn7wE7nZ/nka
hQZMz1SOm3FQ1bgZ1TQ2XAjkSdejQiAvJzvf57nx7LVgnP5Zfr0e8yJgpo+aTkVO/KSqg2ecS8p+
A2sHSNekQiQ/N7fvT9Kd14ln+xditgOtx7wImLlHXPhlGmvuJ6X9GJUBidz8rLxOfta7SN7fS5id
0fV9mD5qOvacm79JY81t0m+ECrHceHZ+ELM4xhsUoi2Yinh8Oo0Nd0q/CSrksrU+JesNGP+JvSOV
FZWmKj/veuk3QEVAliaRtAXTkVgXqSqRdwEwTfoNUCEXizUSy9K3ubZgOhAJmMrKSsBcJ33xKgJy
TGPWXisuOqTpJZl35M8vTMbaw6UvXkVAfm4WA0a7SHsTilwzRfrCVUQU5DVl7bXiWdp3JkBEAqZq
wqmHSF+4ioii/OzsBQNuPxj1CTItmJT/S5xVSJQUZO+1sjUdHiBCXSRbJH3hKiJKEr1bZFdbDzsa
evb/5GVpQV+AyASMtdqUVNnRv6jnzYpVm+Drt8H1j/Ts/8vTUd69yQRMjOzs8q5UYX7PbnRsScEv
n4S6RnjrQ/jJw3R7n12dpu5Aagymh21PpXqptLB7uxDW1sNdc+GS38CSdXv+/OUl8B8PwurNXT+H
LrTrQCZgsrn4SUVb/8LCLv+blIXqv8CDL0PNjo5///aHcPkd8Me5+38evVWgA5nItWjAqOwozt//
L9GXlsDts2FjNw5/fOBlWLsV/uk0GFDc8e+1BdOB0CBvFldXqmgr3k8P6b6X4KcPdy9c2rywGL7x
W1i5sePfZeumygARCZi5w+uzt7pSRVthJ3tNbaqFnz4Cf5rXu72aG5vduMzTb39yALhmp/TVekcm
YObN1UFelR0b9mqdPPKam4J+8f2+PW/NDvjvmXDr09DYAuu3wr2BOpc+K4TGYExz359EqW6490WY
Otatsp29AO6Ynd7nn/WOe6hOyQSMsToGo7Jj5Qa44g7oVwjLP5auJnKEhr11mlpl0fpt7qGyTmrp
oY7BKBUBQtPU6CySUhEgdTe1tmCUigCZgDE6BqNUFEh1kXSaWqkIEGrB6L1ISkWB1CySBoxSESB1
+6cGjJRELpQWQVG++1iScNsM5Mbdx7w45MTAGGhJQlOLu9+mufVjQzNs2wVbd7mPjbp3mNo3oZW8
GjBZM7gfHHcwTBkDBw91oZJONTth2Tr4v+Vuo6YPNvbuBkIVSlL7weg0dboZA+PLYdIIGDcURg6E
QSVQVuT+LlMGFsPAg12IAeyoh49q3A5wS9fDwtWwukb63VFCpG521BZMuhTlw/nHukdRft+fr69K
CmDiCPc4Y7L7s7Vb3MZOj78Bm3f06elVsMgETMxqx72vBpXANyrg5In+b9U4fAB86Xj47FFw/0su
aLq7kbYKNKGAMdpF6o3CPDhhAkybAEeOcv8eJMUJuGwGnDsFXloKLy6G99ZIV6UySCZgihPaReqp
Y8fBv38+HKcHDu4P5091j/kr4Xd/d+M2KnRE1sFUz/9bCqMDvV0qLYTPHws/vQB+/IVwhMvejhkD
t18O154FY4dIV6PSTO471prtYLt3Zk0UDSqBmy6CoWXSlWRezMCpR7jHXc/Bg69IV6TSRC5gDLVY
9FfW3nJz3IDoBdPC2WLpytemw/ih7gyiNVukq1F9JNiCoVb64r0zpD/84mI4oJ90JXJyYnDiIXDo
CLj2T7oTXcDJHaZrqJO+eO9ccWq0w6W9gcVw66VujEYFllzAaAtmj2kHu/GWaROkK/FLUQJ+8iW3
fkYFkvQYjDpzMnz7s9JV+CsnBled4Q47m7NQuhrVQ3ItGOjBeZ0hZIDTj3ALz9T+xQxce45boKcC
RXIMJtpdpBMmwHfO6vxoU9WRwYXxOJ14DBK5gElFuAVz2Ej41mnSVQRPPAduvAgmDJOuRHWTXMDE
TDRbMEPL3IDuIJ0t6pWifLjydOkqVDcJziLZeumLFzHjsMzuzxIFBw+F046QrkJ1g2DAmOh1kQ4d
rgOV6fIvZ8BnNGR8J9hFitgg77hy+K+L3IZMqu/y4nDNWW6vGeUtnabOhoHF7o5o3zeGCqIvT5Ou
QO2HYAsmFp0WzGeOTP9m28o59QgYM1i6CrUPug4m04oTe/amVelngB+c545jUd7RMZhMu+Zz7i5p
lTkHDoIpY6WrUJ2QC5jy0vDvaNe/UG9gzJbDD5SuQHVCLGDMHVc0A03Sb0BGnXiIdAXRcdIhrjuq
vCI5iwRhP0L2JA2YrBlQDBfqjJJvNGAyZfRgd8qiyp7PHu3O2lbe0IDJlMtOcQfKq+wpyIMjD5Ku
QrUjHTDhHOgdWAKTR0lXEU26xaZXpAMmnIO8h42EmPRbG1EaMF4R/ikwzdJvQEaMHChdQXQN7u+W
BygvCAeMDWcXSQd3ZZ05WboC1Uq6HR/OLtL4odIVRJueQuAN6WmO8LVgxg4J7oKvmp2wYRvsbHC7
+FsgP+7WmAwtC851De4PR4+GNz+QriTyhAPGNBK2s0uCuHr31WXw5xdg+cf7/m9iBo4bD2cfA0eN
lq64a587WgPGA9ItmPCtgzkhQPcevbQE/vIqvL+265xPWXh5qXtMHQtXnAYjPN7saepYtylVU4t0
JZEmOwZjQhYwIwa6O3uD4MX34WePwOJuhMveXl8Bl98OqzZJX8W+5cX1LnYPyAaMJVzT1MNKpSvo
nnmL4PpHXaukt1IWnnhT+kr2Tzf5EqctmHQaE4BDwRavhZse71u4tJn1NsxfKX1F+za0VLqCyBNu
wRgNmGyb+Sa0pNLzXM1JqHoI6j1dbVBeKl1B5MkGTI4J1zS173vDNrW48ZN0ak7Cy0ukr6xz2oIR
J7zQLj88LZicmBvk9dn8lbCtLjPP66MDdJBXmmjAzG3cEZ6AyQ/AptPPL87M8763RvrKOqdnUImT
DZhV8zztvPdCnvSSom5YlKEg2LAdPt4mfXUd9deAkSZ9s2N4pqnj0rd1dcPmHZl77qXrpa+uo4I8
6QoiT3oWKTyDvL7vXtfcAsk0zR515rVl0lfYUX4u9NNWjCThdTA2PGMweRE/FnaJhy0Y0LOrhUmv
5A1PwGCkC9i/3DgUZrDLsG4rNHg4pKa3C4gSbsGEaKFdSwBuqsvkwrNkys+B3qBsMRFSwi2YEHWR
mpLSFXTtpEMz+/zb66WvsCMd6BWlLZh0aQ5AC+bTkzL7/LUZWMTXVwkNGEnSLZjwTFOn6/6eTCov
dUeqZEq9h1/OIKxPCjHpu6nDM01d1xiMzY0+NS6DT+7h7oQFAVhhHWLS62A8nHbopZSFNTXSVXQt
k90kH8+C0i6SKG3BpNMHG6Ur6NqkkXD6kZl57phHU/W7GmH2ArclqBIj20EN234wKzfCDOkiuhAz
8J3PuVMD5i5K73MvXgunHCZ3bda6fYYfnw8LVnnZY4sa2YAJ00pegHc+lK6g+757ljumZMHq9D3n
E/PdepgTD4FDhkFhfnprTqWgth627IRNO2B7nTtmZf1WWF0DH9W44FTeEB5iD1nALN/gbigclMGZ
mnTJjbuWzGW3p/cepZlvuUc8BhOGwZGjYNQBMLDYnVfUr6DzrS1SKWhodgGyqdbdob1xe2uI1MLG
bbB2qwZIwMgGTCwnfN8tS9fBoIAcXTKsDL53Dvz8sfQ/d0vK7RPT2V4x8RjktYaMwQVc20FvKlRk
B3ljsXC1YMCdGxQk0yfCP38muwO0LSk3rV/X6AZjGzRcwko2YBKJ8AXMC+9ndluETDhnCtz4VciN
+B3hKu1kd7Trv60FQnY2UmMzLPN064L9OfxA+M8LYKTn+wqrQBENmHnz5oFhu/SbkHaL10pX0DuT
R8Edl8NlM/xa06ICS37ppaVWuoS083UT7O4wBr7wKfjpBTC+XLoaFXDyAUMIA+atD9yiryA7egzc
cimcO0W6EhVgPgSMh/f499GuRhcyYfDNGXDW0dJVqICSDxgTwhYMwB1zoCUAm1B1JTcHrjoDfn4h
TBmjM02qRzzYLMPUhnIRxIebYMk6d3NhGBw12j1qdsKP7nfXp1QX5Fsw2PDNIrWZvVC6gvQbWAy/
uBiuOM2tBFZqP+QDxsbC2UUCmLswGJtQ9VRxAs6fCrdemvltOFWgyQeMCXELpr4Zbno8eCt7u6sw
H75/LtzwFThufDDO51ZZJR8w1oa3BQPu1oEgbePQG5NHQdU/wH1Xw8QR0tUoj8gHTCzm4VkXafbq
cukKsqMoHyq/CEePlq5EeUI+YGwqvF2kNnMWRGfWpX8hXH8h/PgLcNhI7w+8VJklHzAmJ9xdJHAL
775/L2zdKV1J9kyb4GabvnWadCVKkAcBE+JB3va218F374HN4c/TTzh3Ktx5BXzzFLejnYoUDwIm
FZ2fuHVb4X8juMv9yIHwxePg15fAuCHS1agskg+YZH50AgZg1jvwzDvSVcgoK4b/uRSuPhMO6Cdd
jcoC+YBJRGAMpr3GZvjlk+G5GbKnYgY+exT86Uo4frx0NSrD5AMmd3j47qbujr+9KV2BLGPgu2fr
lHbIiQeMeehLTUAI19N34eUl8OuZbuPrqCpOuCntX34NDh0uXY3KAPGAaRW9nzILPPU23PuidCXy
Jo5wtxt8apx0JSrNfAmYcJ1R3RN/fQPungfbdklXIis/F6q/BFeenv4TIZUYXwKmSboAMS1JuP8l
+Obt7iTDqDv7GLj7n2H4AOlKVBr4EjDRbcG02dkAVQ/Bio+lK5FXUgA/+zIcf7AbDFaB5UvARG8M
pjMrN8KVf4CFH0lXIq+81N04ecOFuk1ngPkSMOE6fK2vfj8n+KcSpMuRo+Dzx0pXoXrJl4DRFkx7
76+Df/o9LFgtXYkfvnoSXHwy5HuwhbTqEQ0YX324Ca67362Xibq8OHz1RBcyKlA0YHzWnISfPAzf
uRsWBfi0yHQ5bypccrKOyQSIHwFjNGD2a/Fa+OF9MH+ldCWy4jlw4Yl62mSA+BEwVqepu9TYAj96
AH4zy+0tE2VfORFmHCZdheoGPwJGWzDd98R8uPjWaHeZCvPh385x4zLKa34EjDXRu9mxL5pa4OeP
wbot0pXIuugkGKErfn3mR8DoSt6e21QLl90B//6AG6OJImPcvUsxXe3rKz8CxljtIvVGMgVvrIRr
74HH3wjlEd9dOmo0/OOnpatQ++BHwOg0dd8kU3DbM/CDe91MUyqkJ0nuyxeOg2PHSlehOuFHwMRi
GjDp8M6q1pmmZ6Qryb6Lp0tXoDrhRcDMrV2tAZNOT74Jl98Bd82FzTukq8mO8eVw4QnSVai9+BEw
Hy/RgEm31ZvhwZfh23fB8ohsAXHJdBg9WLoK1Y4XAQNG76bOlJqdcPVd8MpS6UqyY6qOxfjEj4Cx
OouUUSkLP3sUrn8UlqyTriazPnME5Pjxba18CRhjNGAyrSUJzy+Ga/7kuk7JkM40jRgI5+i9Sr7w
I2Cs1YV22ZJMucHfi26Bv74ezqC5+CSYMFS6CoUvAWMivOm3lK274Ld/hyvudKuCw6QwH649W7oK
hS8Bo7cKyFlT4/abeegVt/F4WIwc5KaulShPAkbHYERt3gH/7zn48YNuk6uwmKIzStI8CRjd9NsL
i9bCv/4RPtosXUl6jNMWjDRfAkZbML5YsQG+9Xt3pG3QB4APH+l2wVNi/AgYXQfjl2QK7nnejc3U
BfhL069QF94J8+MciKjsaJebA0X5bpajIM8dwxGPQ24MYntlfTLl1q40J93nDc3Q0AT1TbCr0S2e
y7Sl6924zHfOCu5RrudOic4qZg/5ETBhHOSNx9xeJVPGwNghMHqIC5d0sBY2bIc1W2BzrTtHack6
d/9Rurs1C9fAZbfDrZfCmCHZfx/7avIo6F+o+xgL8SRgQrDQrigfTjzE/RBOGgFjBndslaSLMe5o
1fJS9+9nTHYf65tg3Vb4cKPb5W51DSz6CFr6GDop624z+MF5wRw4HTMY3vpQuopI8iRgQtCC+f65
cOw42RoK8lxraewQmHG4+7PnFsKNj/f9uddsgX/5A/zHF2HawbLX2VMjBmrACPFjkDdGsDf9zonB
0aOlq+jcp8an77kscONjUBOwPWbKiqUriCw/Aibo5yL1L/B3OtSkeUPsxha4dZb0VfWMnjwgxo+A
KQj4lpklBdIV7FsmJpteWQqPviZ9Zd03rEy6gsjyImDmrl/bDAHuJhUnpCvYN5uh6ew75wRnb5my
IukKIsuLgJm3aREYE7COfTs+B0ymzgxKWZj5lvTVdU+pBowULwIGAEtw9wzweQe1TNb24vtQG4D1
JTkxGKrdJAn+/GSYAAdMpta7pEMmA2ZXIzwSkLGYUQdIVxBJ/vxkWALwq3AffD65NCfmVhVnyuwF
wThRsn+hdAWR5E/ABLkF47sBGVwHsnkHrNsifYVdK8yTriCS/AkYa4MbME2eT4BleqHZ6gDsH5Ou
+8BUj/gTMMZsly6h1+o831J45MDMPn8QTo8s1ICR4E/ABHkWqd7zdYKZHuAMwp4x2oIR4U/AGILb
gqn1/E6HCcMy+/zNnncRoe93lKte8SdgCHALZrPnpU8ckdnp6rxc6Svs2o566QoiyZ+AMQT3OyBl
odHjfctzYlCawWnaXE9v9Gyv3vNxspDyJ2BsgLtI4Bad+SyTu9H5fLNnmx2ed2NDyp+AiQW4iwT+
N8EzeQjZ8AAsw/f96xNS/gRMKuAtmC27pCvYv8kZ2hArngPDMzwNng7bPP/6hJQ/AROPBbsFs3Wn
dAX7d9jIPXv4ptNph0O/AHSRVmyQriCS/AmYZIBX8oL/20jGDPzofEikccanKB8uOkn6yrq2fZf/
Y2Qh5U/AJAIeMBsC0MMbP9S1ONLlzMkwsET6qrq22fPWZYj5EzDxRHDvpgbYGpA+/plHpe+5Tp4o
fTXdE4Q9a0LKm4Cpmri9HvB4MUkX1m+VrqB7xgyB/74ETpnU+8V3IwfCzRfDwUOlr6Z7NnnefQ0x
bwKmuroaMMHtJq3Zkrn9b9PtkOHwvXPhuvN7vkju05PgV5fApJHSV9F9awJwt3dIeXLwWitDLZYA
zHl2oqkFXl8hf/haT5wwAf5yDby7Gpatd7/p6xpaz8Fuhry4G8gd1A8mDIXDDgzmBtrLdQZJil8B
E+Q7qgHmLgpWwADk58LUse4RVjpFLcabLhIQ7DuqwZ0X5Pt6mKh5f60efC/Ir4AJegumvgmefke6
CtXeLP16SPIrYEyAN/5u89py6QpUm2QKXlwiXUWk+RUwQW/BACxeC2sDsAl2FCxYrTc5CvMrYMJy
ssDrK6QrUABv6NdBml8BE/Q9YdpoN8kP8z+QriDy/AqYoO8J0+btD7VpLm3Bavhgo3QVkedXwISl
BZOycPts6Sqia8M2+MUT0lUofAuYWIBvFdjb7AXBuT8pbG56Ihh3t0eAXwGTsuHqV7ypYwBZt34r
LPxIugrVyq+ACfpK3r3NfMutxVDZowsdveJZwAR828y9rdgAj70uXUV07KiHv+r77RPPAibA51Pv
y93zYE2NdBXhV1sP37/X3QWuvOFXwOTmhasFA24bB23FZN7Mt2ClTkv7xquAqVr4TDi3HnvmXZi3
SLqK8FqxAR5/Q7oK1QmvAqZ6+VNJIHxnfDa1wM8fg2cXSlcSPhu3w7fvgi26TYaPvAqYVuHtRN/y
tK7wTbeZb0GLztT5yseACe8BNvVNcOcc6SrCwQKz39XxLc/5tWWmE96AATceE8+Bq8+UriS4Utbd
CqBdTu/52IJpkC4g42a+BX+cK11FMCVTcMtTGi4B4WMLJnyDvJ154GUoyIMLpklXEhwtSfjx/+ot
GAHiY8CEvwXT5q65boHY1yt6fj5R1GzeAbfN0nAJGB+7SOEeg9nbw/8HV/0B1umd1/u0dD18/Tfw
8lLpSlQP+Rgw4Z2m3pdVm+Dqu2Cd7uXbQWMz/OpJnYoOKB+7SNFqwbTZ2QDXPQAXHA8Vk9z4TJSt
3QKPvQYvLIFtu6SrUb2kAeOTj7fBr5+Cv78LP7swuiHz+gq44THYFd1vhbDwMGBMo1tFFWGL1sJX
/wfOOxbOOQZKA3gedE+1JN0+uk+8CS/rWUZh4WHARLgF015dE9z3onscOw6uOh0G95euKjO27YLr
H4V3V0tXotLMv4AxNES9AdPBa8vhWx+5oKmYCMeMhlz/vnQ98vE2d13PvedmiXTnv1Dy8LtUu0id
qmuEue+5R17cDQb/w/Hu8yDZVAu/fUannCPCv+9Oa1ukS/BeUwvc8wI8/BqcMAG+PA2GD5Cuav+2
7oSHXoVHX9PfHxHiX8DETAMp/Q7slrpGN+P07EIYWgpjhrjH2CEwZjD0L3Q3VmZTKgU1O+Gjza7r
s2Sde2zRqeYo8i9gbLwxKrcjpU0yBWu2uMfzi/f8uQEmDIPDD4QjDoLDR0IiA1PfqzbB/JWwfIP7
uL1O+h1RnvAuYObuXNFYUTRSuoxwsMD769zjoVfdnxUnoCTh1tgUJ6CkAIry3b1Q8dZHTsw9AJqT
0NzS+rH1sb0Otu+CbXVuBkhbnGof/AuYj5c2VozVgMmYnQ3uoVQW+HcvktV1MEqFhX8BY4wGjFIh
4V/AYKN3N7VSIeVhwGgXSamw8C9grHaRlAoL/wIGq1McSoWEfwFjjK6yUyok/AsYqy0YpcLCv4DR
aWqlQsO/gAG9m1qpkPAwYLSLpFRY+BcwNqZdJKVCwr+AMVYDRqmQ8C9gtAWjVGj4FzCxCJ1NrVTI
+RcwObqdnVJh4V/AxHL1bmqlQsK7gJm76cMmICldh1Kq77wLmHmbFgHskK5DKdV33gUMAIZa6RKU
Un3nZ8BYowGjVAj4GTDYTdIVKKX6zs+AMWahdAlKqb7zNGBYKV2CUqrvPA2YwmfQqWqlAs/LgDEz
/3UR8L50HUqpvvEyYADIz50lXYJSqm+8DZiqxbN+L12DUqpvvA2Y6mVzFhOLfQ/Qe5OU6pyVLqAr
3gYMgHnqhzeBuU+6DqU8VSddQFe8DhiAquVzbgXjfVIrJcD7e/a8D5jqFXPeqFo5ZxIx8xv03Gql
2tsoXUBXvA8YcOMx5qnrriI/cR7ojndKYdhM3oAV0mV0JUe6gJ6oXvLMcnJit5IfXzYqt3QXkACK
gbh0bUplleEnn37llhely+i6zBCwZ15/AMZMIMUBWFtGzJZhTRmWAWCLMJSBGYC1Ze5zyrAkpOtW
qlcMKzhw0KHmjiu8n2ENRcD0RmXFJTHWrU+QTCaIUYClANciKmh95GIpwFCAtQkwBRibcP+dyas6
5hv57FpbSCqZwNoCLAmMKQCbt/tzazs+p/uYL339KrB2cNFJ08zFJwfihuDIBow0+7mbB2GSZbS0
lBIzA4EiUra11YVraVnKMAwAinCtrgFg+6MBFV0mdpt5+odXSpfR7XKlC1A9Zy++qYitzWW02AFg
ijDtg8mUYXf/e1FrULUPrRL06x5YVSvnHFG97NkF0nV0l36jRcz0oQebipJRBVhysakCaO3Gue5g
W3eutZvXruto3MeqiV/MpWlrATFbQIqC1rGs1q7k7i5g+67hnud0nwdi5tJDH3LRSZeZi0+eLV1I
T2jAqKyprKyk6rWB/aCpDJMqI5UqI2YHYE2Ra3XZT7bC2rqHljJgANhCovg9a0x91bLZU6tXPPue
dCk9Ll26AKW6a/qQMbGKsrEFJFOtg+02F0xbi6m1tWRbW10mF2PbD94nqg47s4DGZAEkc90gfNsA
fqctrYJO/jzbNs7d8sHv5m5deVv18mc3SL//vaEBo1Q32Krn4rz9djENTWXkpAaQahuEbzfetWdQ
vm2Avgxax8ncjGL3GPM4cANP/fBVY4J9m4wGjFJZUHnMpTlsW1tATrKAVFurybhWmCUBthljasjJ
2Vi95Bk9VUMppZRSSimllFJKKaWUUkoppZRSSimP/H+pR8icnrKo+gAAACV0RVh0ZGF0ZTpjcmVh
dGUAMjAyMy0wNS0xM1QyMzo1MzozMCswMDowMO1LgXgAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMt
MDUtMTNUMjM6NTM6MzArMDA6MDCcFjnEAAAAAElFTkSuQmCC" />
  </svg>
);

IconStorybook.propTypes = {
  customClass: PropTypes.string,
};

export default IconStorybook;
