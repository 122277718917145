import React from 'react';
import PropTypes from 'prop-types';

const IconSQL = ({ customClass }) => (
  <svg className={customClass} xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 122.88 121.83">
    <title>SQL Icon</title>
    <g>
      <path className="cls-1" d="M102.42,37H81.92a8.52,8.52,0,0,1-8.85-8.7V7.53H21a.58.58,0,0,0-.41.18.45.45,0,0,0-.18.42V113.71a.7.7,0,0,0,.18.41.51.51,0,0,0,.41.18h80.84c.18,0,.17-.09.26-.18s.34-.28.34-.41V37Zm7.47,79.08a5.77,5.77,0,0,1-5.76,5.76H18.66a5.77,5.77,0,0,1-5.76-5.76V5.76a5.7,5.7,0,0,1,1.69-4.07A5.77,5.77,0,0,1,18.66,0H76.9a4.22,4.22,0,0,1,2.46.82l29.75,30.12a2.57,2.57,0,0,1,.78,2.6v82.53ZM80,27.69,79.57,9.63,100.66,31l-18.14-.81A2.4,2.4,0,0,1,80,27.69Z" />
      <path className="cls-2" d="M6.71,46.28H116.17A6.73,6.73,0,0,1,122.88,53v45.1a6.73,6.73,0,0,1-6.71,6.71H6.71A6.73,6.73,0,0,1,0,98.09V53a6.73,6.73,0,0,1,6.71-6.71Z" />
      <path className="cls-3" fill="green" d="M13.88,79.38l9.25-.58a7,7,0,0,0,1.22,3.42,5.18,5.18,0,0,0,4.3,1.91,4.76,4.76,0,0,0,3.21-1,2.82,2.82,0,0,0,.07-4.46c-.71-.65-2.37-1.25-5-1.83q-6.4-1.42-9.13-3.82A7.69,7.69,0,0,1,15.07,67a8.25,8.25,0,0,1,1.41-4.58A9.4,9.4,0,0,1,20.71,59a19.89,19.89,0,0,1,7.75-1.23q6,0,9.21,2.25c2.12,1.5,3.37,3.89,3.78,7.16l-9.16.54a5,5,0,0,0-1.54-3.12,4.9,4.9,0,0,0-3.23-1,3.91,3.91,0,0,0-2.56.72,2.17,2.17,0,0,0-.86,1.75,1.76,1.76,0,0,0,.69,1.35,7.84,7.84,0,0,0,3.26,1.16,40.6,40.6,0,0,1,9.13,2.79,9.58,9.58,0,0,1,4,3.49,8.84,8.84,0,0,1,1.26,4.67,10.06,10.06,0,0,1-1.67,5.59A10.57,10.57,0,0,1,36.1,89a18.94,18.94,0,0,1-7.58,1.32q-8,0-11.14-3.09a12.19,12.19,0,0,1-3.5-7.86Zm61.48,5.8c1.21.85,2,1.38,2.37,1.6.55.31,1.31.68,2.25,1.1l-2.69,5.45a31.53,31.53,0,0,1-4-2.35,33.43,33.43,0,0,1-2.8-2,20.4,20.4,0,0,1-8.08,1.4q-7.18,0-11.34-3.74-4.91-4.43-4.91-12.44c0-5.19,1.43-9.21,4.29-12.09s6.86-4.31,12-4.31,9.27,1.4,12.12,4.21S78.79,68.79,78.79,74q0,7-3.43,11.17Zm-7.47-5A13.09,13.09,0,0,0,69.06,74q0-4.76-1.78-6.81a6.17,6.17,0,0,0-4.9-2,6,6,0,0,0-4.72,2.09q-1.81,2.09-1.81,6.51,0,5.16,1.76,7.24A6,6,0,0,0,62.46,83a8.65,8.65,0,0,0,1.87-.2,12.19,12.19,0,0,0-3.89-2.23L62,77.1a7.89,7.89,0,0,1,2,.58,23.84,23.84,0,0,1,2.82,1.8c.33.23.69.46,1.08.71ZM84,58.28h9.73V82.06H109v7.75H84V58.28Z" />
    </g>
  </svg>
);

IconSQL.propTypes = {
  customClass: PropTypes.string,
};

export default IconSQL;
