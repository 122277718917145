import React from 'react';
import PropTypes from 'prop-types';

const IconChatGPT = ({ customClass }) => (
  <svg className={customClass} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
    <title>ChatGPT Icon</title>
    <image id="image0" width="256" height="256" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAv
FElEQVR42u2dd0BUV/r+nzsMXWmCgqCCvWIXxd6jYGPUWBKjmO8mm/3tZt3IaNQoJpoopm52N2XD
WBKNUQZUECv2jr0iKIiKAoIgvQxzf38oLjEoc+/ce8+9w/n8ZznnvOfqeTnlfZ+XgYXBsiyzMHr9
QJY1DmSBAIDtAsCXtF0URZIGhrnCVOEMyzBH1kyefQwMw5I2SkgY0gaYw7wtP7mpVar3wbLvgYE7
aXso9QiWzWHA/FtlX/7NquD38kibwxdlOQCWZeZH6ZYyDD4CYEXaHAqlGgYwgMXK1ZNDlzMK2iXI
3gGEh4erirs0/w9Y9h3StlAoHPje8eq9v4SHhxtJG/IqZOsAtFsix7MqRIP+pKcoGRZGI8u8/sXU
0CjSptSG7ByANiryRxb4P9J2UChCw4JZ//nk0Nmk7aiJbBxAWFTkAQBDSdtBoYgOyx5dM+XtQaTN
AGTgALT6yDiWRRBpOygUqWGBPZ9PnvsaSRuIOYD5Ubq/MWC/ITl5CkUOMAzmR2jmfkFkbKkH/HD9
vxoZHO0fkRibQpEtDMMaqozuX019+7GUw6qkHCxsa2SswdE+B3TxUyi/h2UZtYrJDYvS7ZByWEkW
4vwNGxwZh8oiKSdGoSiZYmNZw/9M/Yvoa0b0HUDYlp/ep4ufQuGGo8qucP7WyL+LPY6oOwBtVORl
Fugi9iQoFAvm8prJc7uK1bloDiAsKtIAGsVHoQhB1ZrJc9VidCy4A6DnfQpFHBxtDY7h494pEbJP
Qe8AtNsjm9LFT6GIQ3G5ulir/6+PkH0K5gDCota2YSuRIf1noVDqDyyruqfdEtlOqP4EcQBPDTIm
k/ssFEr9gVUh6YOote2F6MvsO4B5W9Z7q1WG+6Q/CoVS32Cs4R0xYe4Ds/owp3F47A8OxeXqYtIf
gkKpr5h7MWjWEYAufgqFLOauQd4O4Nk7P4VCIYw5a5GXAwjbGnkZNMiHQpELVmFRkZf4NOTsAMKi
1r4Phob3Uigywz8s6qf3uTbidAlIo/woFHnDNYuQ0w6ALn4KRd44quwKufx9kx2A1EIFFAqFH1zW
qklHgKcluJhc0hOjUCimYTCyjUyRFzNpB6C2UuWQnhCFQjEdU9dsnQ5g/pbIf4BlqYYfhaIkWJYJ
i1pb56tAnQ6AUYGIXDGFQjEX49d1/Y1X/mSfHxUZzwBjSE+D8j9s1dbo3qwl/H380MrDCwzDbXNW
YahESvZDXLyXissZaWBZxRSypfAjbs3kueNe9oevlBmii588Hg2cMaZzL3T2biFIfzZqa3Rq2hyd
mjbHTAwBALAsi5OpSdh5JRGVVTTC28IIftUfvvTHR9hW3REw7EDS1tdHfFzcMTtwBJzsHYiMfyc3
C+tPJKC4ooz0p6AIAcMcXqMJHVLbH718B0AXv6QwDIPZ/Uagg1cz0qbAt1ETLBs3AwCw59p5JCRd
JG0SxRxYdvDL/qjWHUBYlC4SYENJ210fsLe2wcIxU2FvbUPalFeSnJWBn47tIW0GhScMEBkxee7b
tfz+HwmLiqQ3QyJjq7bGkqBpsFVbkzaFE6k5mfj+cDxpMyg8WDN57h/W+x9+Q7t1bQjLGPWkjbVk
PhgZgiZOLqTNMItDNy8j/upZ0mZQOMAyCPlcMzem5u+p//iXjFtIG2qp9GvZAZO69yNthiAMaeeP
Ie388Wn8b8gvpcJQSoAxslF4QcfjdzuA8PBwVXHnZlWkDbVEVkyYBRu1KMVdiHPtwV2sP7mftBkU
E3C8es8qPDzcWP3r30UCFndu9h1pAy2Nlh5eiNCEWuziB4BOTZtjtWYOVByDkijSU9y52b9r/vrF
UOA/kTbQkpgdOBLvDqofsVQMGKwKmQM/9yakTaG8mndr/uK5A2Bpwo+gLB83Ex1l8KYvNX8eHITR
HXuQNoPyCmqu9ecXAqWdm4eDeRYbSjGL1SFzYC3Rlr/cUInkzAycTU/BqdQkHEu5hqMpV5GSnYGb
WffxMP8xSisqYGdjI9mTY0sPT/i5N8G5u7ckGY/Cjb3XL1Sd2LrjCFDjElAbFVnJ1pEbQHk1DIDV
GnHjp86kJWP/jYvIL+WvzsYA6NeqA0Z17AEHG1vRbM3Iz8U3CdtF/R4UPrCGNZPftgZqLHi6+M1D
zMW/7/pF7LtxXrD+WAAnbt/Aids3AABNnFwxt/8ouDg4Cmq3t0sjzBsxCV/tjzG/M4qAMM/XOgNQ
yS8hiBB48ZdVVuDLfTGSv7GP7dwLQ9r5C9rnreyH+PHoLrR094S/jx86ejWDi0MDTn2UVlYgJSsD
l+6n4eqDdJrGbCZW5eVuq2a+l8cAwIIo3XIj2KWkjVIqq0KEewIrq6zAp/FbUGaoIDqnwW26IMi/
N1Eb6oIFi5O3k7Dr6lmUGypJm6MoGBbLI6bMDWcAICwqMgdAI9JGKZHFY1+Hs70wW+dvEnYgI19e
8ouh/UehvacPaTNMIiMvFxtOJSCvhKrX1wnL5qyZ8rZH9VmALn4eTO45QJDFn5R5H7rje0lPp1Z0
x/fC3toGy8e/QdqUOvF2bYQPx0wF8PSOY9vFk6RNki8M4w7Qiz/eeDq7oo9vW7P7+Wr/Njx8Uqd6
M1FKKyug1evwzsAxaNXYi7Q5JhHYqgMCW3VATlEBvtgXjSqj0fxOLRAVDQDixz9GTDKrvZFlsUCv
k/3ir8kPR3dhw8kE0mZwwr2BEz6bNBsLX5tKQ5VfhGUZJmyLbjBU7CHStiiJJWOnmSXXVVhWik92
/kp6GpwZ0s4fYzv3Im2GWdx4eA9rT+wjbYYsMKowUAXGSKW/ONDKw8usxZ9TVKC4xe/p7IYITaji
Fz8AdPBqhghNKNo28SZtCnFUVRikZhlVHwb0TdVU3jEjuSevpAgRe6JIT8FkrBgVPgqeLmq0ICne
HjAaBaUlWBG/mbQpJAlQMWCFjfqwYGb0Gcq7bbmhEp/tUo7WyuzAEfgsZLZFLv5qnOwdEKEJhXsD
J9KmEIFh0FkNQBjB+XpAt2Z+vNt+tP1n0uabRIBfe2h6BJI2Q1K0oyfjQNIl7L52jrQpksICfvQZ
0ET+OnQc77aLt20gbX6duDo0eP6GXh8Z1r4rOjVtji/21au8BYY6ABNp5ubBq93mxCOyrrbDAFgc
NA1OdtIXISmtrEB6ThYyC/KRX1qE8sqn4bw2ajWc7B3QuKELWrg1lqxAShMnV6yYMAtLtsvfYQsF
dQAmMDtwJK92RWWlOC/jnPjpvQeje/NWkoxVWlmBvdfP4+TtGzCakcjT1aclRnXsDo+GzqLYaaNW
Y1XIHCyMXivJdyENQ2sA1A3fTD+tXkfa9Frp6uOHmQH8LzRNJb+kGLrj+5BZIE6wk4ONLWb1HY6W
Hp6C982yLBbUAydAdwB10JtnuO+BpEukTf8DDW3tsSRoGueKwlyRqmZASUU5vj/ytEhJV5+WmBkw
RLC+GYbBZ5Nm48OYdaLPgyTUAdTBlJ4DeLWT243ygtGT0Ujk5664y4k4knKFyPwu3U/FpfupaOnu
iXcHjxWkTyuVCuHjZiI8diOROUkBdQAisOXsMdImPGdit34IbNVB1DHkVBcgNScTWr0OA1t3xriu
fczuz8HGFu8MGosfjlhmOTTqAF5Ca4+mGN8tgFfbs+nJpM1H28beeHvgaFHHKKkox8dxm8y61BOL
o7eu4uitq1giwAtHKw9P9Gze2iJFTqkDeIarQwNM6h5otvjF0ZRrROdhb22DpcEzYKVSmd/ZK/h8
bzSyC/OJztUUVuzcLEhJttd7D8LVB+kWpzxUr18B1CorzOo3XFDFG5I3/+8PmwBvV3G1XXZeScTh
ZDLnfHNwsLFF+LiZZvcj15cdvtTLHUCbxt54e8AowW/DDUYyZRVf69QTw9p3FXWMtJwsfHd4J5H5
CUFJRTm0eh1Wh8wx6999TuBIi0onrlcOoFeLNpjaS7zs5yiJL/9auDXGX4YGizpGhcGA5XGbZB3N
yIUF0Wvx6cS3oLay4tW+g1czNLC1Q1F5GempCEK9OAI0d2uM/yfyQgGk2x5aW6mxLHiG6AVH/3No
J+7kZkkyJ6n5dNJbUKv4OQEWLBboLSNIyKJ3ACqGwfLxb0hWEksK/jw4SPQCnIduXkH81UTSUxWV
RTHreUd4MmAQ4NcOp9Nukp6G2VisA5DiXFyT248yRe1/cNsuCOoirk5/VkFevcqGWxC9FqtD5vBq
q+nRnzoAOcIAWGnG9o4vJ25fF6XfJk6u+GCkeQKkdWFkjfg47leUVJSLOo7cYFkWa/bqETZKw6v9
xG79FC89blEOwMfVHX8bNp7I2FcfpAvan4pRYakEclwbTiYIbruSeFT4BIl3knnlfAS26kAdgFyY
0LUv+rfuSGx8IWvVze43Eh2bNhPV3nPpt/Db2SNifxZFsPXcMfRq0YbX8+DoTj2xR2Z5H1wQN1xM
IuYNn0h08QvJ6pA5oi7+J6XFWKDX0cX/Ast2/MKr3XAJ75nEQPE7gKXB09HA1p60GQLNZYaoqbqf
7dpC6+a9hDJDJW4/ykQrHtoCzd0a4+7jbNJT4IWiHcAnE960mCc+BxtbNLC1E6Vv/fnjxG+sR3bo
jpEdu//u906n3YT+/HGidtXkhyPxvJ4G/zTwNcXKiCnWASwbN1PwxV9ZZcBPx/bgz4ODJJ9PSHfh
lXjlUHS0o1dzzA4cUeufBfi1Q4BfO8ReOo2jt8gmUVVz/u5t9OAokyZ2QJaYKNLyha9NgaOAt+NP
SouxencU71h+Ia7/2jURLiGptLICH8dtIloQ097aBsvGzYCKqfuaaVzXAIzrGoDP9+qRXfiEmM0A
sDnxMGcHAAD9W3fE8VviPAWLieIcwIw+Q+Dm2FCQvsoNlfgkbjMqqsxM8RTgBcDWWpjdzDcJO5CR
nyNIX3yZN2IivJzdOLebP0qDkopyLI/bJOirClcy8nPh7cItqzK4Sx/qAMSmi7cvujVrKUhf/z26
BynZGaSnJBh7rp1HQtJFojYEdemNwW27mNWHg40tVofMwaX7adh4+iCRefxwOB4fT3iTUxux9RfE
QjEOwNpKjTf7DjO7nyelxVgZ/xvp6QjG/bwc/PPADqI2iJFs1dXHD119/PBr4mFcuHtb0vmU8RT9
aNzQRREiKTVRjANYOXGW2X3EXT6DIylXSU9FEAxVVVget4moQo21lRrh42bA2kq8/0bTew/GtF6D
sGLnZhSWl0o2t5OpSejXsj2nNsPad8XmxMOS2SgEinAAc/ubr223avdWPC4uJD0VQfjx6G7cyn5A
1Ib3BgfBV+SsxGoYhsFHwdORU1QgWXXl2EunOTuAHs1bUQcgNC72jmjnaV4t90Ux64mp9YgBycU/
qE1nBPubr7bLB/cGTojQhOLE7evYdvGUqGNZ0v+XVyF7B7Bo7OtmtV8YvVaWqrVKQ4qsRFMJbNUR
ga06ir4TKq2sgL21DenpioqsHcCIDt3Nav9hzDq6+M1ExTD4KGg6HEWKUjSHPw18DQZjFZbHinMX
cup2Eoa29+fUxqOhMx4RjmXggqzfLkZ15O8APon7lWggjCUwq+9wrAqZI8vFX41aZYVPJrwpShr4
2fQUzm3aNG5K+pNwQrYOILT/KN5t153YL+mNsaXR27ctIjSh6OzdQrA+iyvKkZR5XzSbfVzdEaEJ
xfD23QTr81ER95/kLd29RJujGMj2CMBXqz/1USauP7xL2nxF4mzvgEVjp0HofMQv9sUgqyAPAGDF
qPCRiEInozv1wOhOPYhFRDZ2EqdsuVjI0gHM6jucd9vvLbSGm9gsGvM6XBwcBe2ztiIiVawR4bEb
4eXshnkjJoo2n/eHjyeSE+HRgDoAs+G79Vweu4m06Ypjco8B6OPHrwT6y0h9lFmnI3745DG0eh2G
tPXH2C69RJmbvbUNPps0GzczMxB5fI8oY7yI0kKCZecA+Cr7pOVkobjCMoo1SEEHr2aYEzhS0D4r
DQaEx25EJYc39EPJl3Eo+bKogUXtPL0RoQlF9PkTOJWWJMoYSkV2DmBC17682im5bJWUONjYYmnw
dJPSdLnwr4OxuPv4Ee/2/zm8U/TQ4pAegQjpEUiVkWogKwdgzVPK+9DNy6RNVwR803RfRULSJcFE
MSurDFi8bYPolZw+HDMVBaUlWBm/WRAtByUjKwcwI2Aor3bxV8+SNl3WjO3cC0PacQtoqYsH+Y/x
dcI2Uey9+zgbWr0Oozv1FE1008neAas1oTibnoItZ4+KMoYSkJUD6NS0Oec259JvkTZbtojxk7TK
aMTyuE0oq6wQ3f49185hz7VzopY979WiDXq1aIOfTyXgSob59RGk+C5CIhsHwFcNl8pb/xGxztI/
Hd2DZAIiKt8c2A57axssDZ4h2i37m32Hw8ga8cnOzSg2o/IvaUkzrsjGAQzlsUW1lJLVQiLmbfqg
tp2JOADgaWLOhzHr0LaJN94eYH56eG2oGBWWBc9AdmE+Pt8bzauP6oAnpSCbR0s+IZy/EJKMkiOD
2/ojQhMqao5+2yZPn9P6texAbJ7JWRnQ6nU4eVu857zGDV0QoQnF5B4DOLe9lf2Q2Lfhg2wcgLUV
9xeAGw/vkTabONX/WYNECqapjUnd+yFCEwr3Bk7E5h1z8QS0eh1yigpEG4NPgFRylrJ0JmVzBOAK
W88fcFQMg6XBM0QvHvoqtKMno6CsBCt3/kbs3yNiTxQa2tpjSdA0UasqmYrSgtFk4QBaeXDPoBJz
Cyh3ZvUdLmimnjk42TlgtWYOLt5LxaYzh4jYUFheigXRa+Hv7Yc3+vJ7Sq6vyOII0MXbl3ObF5NM
6hNyWfw16dasJSI0ofD38SVmw+WMNGj1OslVhKsxVClPRkwWDqCDJ/dquDSU03xW7d4qeG2+NwKG
YTVhEZFfEw9jgV6HgrISScc9pMAfSrI4Arg6NiBtQr2iZlLM6bSbOJ12E6H9R6I9D0dcGwzDYFnw
DOQWF2L17q1E5sgCWLFzM1wdGuDDMVMlGVOJIemy2AFQpCHp4T1o9bpaM+J0x/fhw5h1gkayNXJs
iAhNKMb7BxCbc15JEbR6HaLPnxB9rAoFxqXIYgfAlYJSabd2SsdUYYwqoxFLd/yCps5u+LuAYh0D
2nTCgDadiNYzOJWWhFNpSZgdOAIdvbiHnNdFrkJrTijSAWQqLNqKJHyksR48E+sY3r4bRnfqIZgt
Yqv4msK6E/ufRfxNh72AT6i7rwqTESk1ijwC0B1A3ey7fgFavc4sXbyEpIvQ6nW4lyectp6YKr6m
YmSNWBa7kXe4b23MDBgCW7UwFZ6lRJEOoNygvLOWVNzPy4FWr8O+GxcE6/PbAzuwdPsvglbL+Z+K
rzjpvqaQXZgPrV4nWL3ITzhWFJYDinQA9T0KsDYMxios3f6LaJWCywwVWBSzHj8dFVZbb3SnnojQ
hAouVMKFuMtnsChmPVgBisismGB+EVspUaQDcCQY/ipHIo/txaKY9SgziJ+Lnpz9NBnnxO0bgvY7
b8RELB//BjFRTYOxCgui1yL1UaZZ/dio1ZjSk3sSESkU6QCc7B1ImyALTqUmQavX4WaWeAU3Xsa2
iyeh1esErbhcreJrTlEYc/n+SLzZT4a9fdvCWSH/RxXpAEhuF+VAXvGzt+0L4r9t18Wq3Vvxyc5f
Bdk+V9Pe0wcRmlAEEko7PpWWZPZRavHYaURs54oiHQDJDDg58NnuLaRN+B2FZU+TcYRm4rO0YzfH
hpLP6X5ejtlRjG8GDJPcbq4o0gFQ6hcLX5uCxSKULKuL3OJCfLEvhnf7Lj6+UPNUupYKWTiAzCc0
sIfyapyfqfhO6z1Y0nGzCvLwyyn+ylNLg2dIai9XZOEArvNQ9mni5ErabAoBejRvhQhNKK8Ucr5c
zkjDlYw7vNraWVujEYEjjKnIwgFcyUjj3IaPiCjFcniz7zCsCpkDRxtp0o5/PnWAtwht2OjJUn4a
TsjCAWTk53Ju06N5K9JmUwijYhgsGzcD80eFSDLe4m0beNvp6iDPlHdZOAClwzAMXGT6DyxHbmU/
FFTSvVoYNdi/j+i2860iNH+kNE6KK7JxAIVlpZzb9G7RhrTZz1k0ZiqWELipViLVNQC/Oxxvfmc1
GNSmMyI0oWjp7ima7WfTU+pMq64Na7VaFqKlLyIbB8AneUXTs78otvANaamuNze150BR7LI00nIy
nybjJAuTjFPNu4PHYuXEWbARqcrw8rhNvNrN6jtcFHvMQTYO4FQqd5VfFaMS5SduYlqyWe17+baR
/KZaycRdOQOtXoeHTx4L1qe1lRorJs7CmM7C10soq6zAEx4p6XxqX4qNbBwAX2YHjhS8z6jzxwTp
p/qmuqGtvdSfRZF8tX8bwmM3wshy32K/jKHt/BHg105wW/lGCZIsplIbsnIAx29d59ymg5cwQpYv
IpTsuIph8FHwdMluqpVOSUU5Fkavw/oT+wXrU9ND+KOiwVjFS9VoZh951S2QlQPYcekUr3ZinLl3
XkkUNOW1+qZ6fFdyAplK4trDu9DqdUi8Y95xrJoezVsLbuN3h7hfYopV5pwvsnIALMArq6yXrziv
AdsunsRH238WVAlnQOtOz26quVdDshS4CLpsPXcMC/Q65JcUmzVm35bCHwMePOEevwJAVvkBsnIA
ALD+ZAKvdv8YMUkUe8oNlVgUsx4/HtktaL/vDh6DTye+pUgdOalhAXy66zd8Gs+/BqGLvaMotiVl
ctdiGNS2syi28EF2DuD6w7u82nk6u4qaNnrr0QNo9Toc43FP8TLUVk8FMv/f0HGi2W1J5JcWY4F+
LbacFeaSVgi2nD3CuY2cwthl5wAA/hVWFr42RXTbdlw6Ba1eh0eFTwTrs7mbh+h2WxJn04W5FxCC
onLu1YDltOuTpQOIv3qWd9t5Aha0eBVr9uqxPG4TjAIq4VCUSaUCi4JWI0sHAPB/hvNydkO/lu0l
sbG4vAwLo9fi51MHpPw0ysfCfOaxW9c4t2loJ4/YENk6gJ1XEnm3ndQ9EB4NnSWz9UrGHUGfrCjK
gs+/O5+K2GIgWwcAABt4vggAQNgoDewkPmtVP1nxCROlKJecogLObVp5yOMZWNYO4OqDdLPSRj+e
8CasRUoIeRksgJXxm/Fp/G+SjktRFs1kcvErawcAAEu3/2JW+5UTZ6GBrTSqMTXJLy2GVq/D1nP8
8sdfxWqZ5Rd08fZFhCaUtBmKQi45AbJ3AFWsEbvMeBUAngozipkj/ioS76RAq9fxjm+oDUYm+QWO
NrZYHTIHb/aVv/w1pXZk7wAA4ODNyyiu4P7eWpN3B4/F1F7k8vTXndiPhdHrUFJRLlifJPMLwkZp
sGzcTFmKXFBMRxEOAACWx/ITYahJrxZtsGLCLGKqPUbWiPDYjfjSDK352qjOL5DiYinYvw8iNKGS
vrIoASMPlSA5oBgHAADLYzea3YeNWo3VmlAMaN2J2DwyC/Kg1euw8zL/p87aeGfQGKycOEuUi8+W
7p6I0IRiUBv5xLHLCoXuhBTlAIoryhF5bK8gfY3vGoDVIXNESxIxhcMpV6DV65CWkyVYn9ZWaqyc
OAvvDQ4SrL8VE2bh3cFjBbPRwuKAADzVfVAiinIAAHAz6z7Sc7MF6YthGCwa+7okOQSv4rvDO7F4
2wZUGoRTyvV1b4IITSgGt+nCu4/3hgQ/1dZTS/uUSpEOxTkAAGjRqLGg/bk5NkSEJlQU5RhTqawy
YPH2DfjXwThB+w3y740ITSinSkrV6rq+An9nivxQnANgRLzCC/BrhwhNKNp7+hCb393H2dDqdTiQ
dEnQfj8YOQnLgme8cqsqpb5+fSe/pIi0CQAAxe3tpBBTCO0/ClVGIz6O24TSygoi89x97Rx2XzuH
eSMmwsvZTZA+HW3tsCpkDq49uIv1J/+nuadiGHwUNB2OBAKm6ivpuY9ImwBAgQ5gWPuukoxjpVJh
+fg38CA/F18nbCc236/2b4O9tQ2WBs+AlUqYDVunps0RoQnFb4lH0LFpC3TxbkFsfpZAUx4OOi03
k7TZABR4BLC3tpF0vKYujRChCcVrnXoSm3NpZQU+jFkH3fF9gvb7eu9BdPELQACP9PMbPCpii4Hi
HAAphrXvighNKFq4NSFmQ1LmPWj1OpwUUK1YKMwN11YyfXnUHciTyR2AohxAUxfyksp/GRqElRNm
EVV2jbl4Elq9Do+LC0l/DqTnPr20PMhTxs0SUHI4tKLuADo3lcd21VqtxqeT3kJqTha+P7yTmB2r
dm9FQzv7p0VJJf5PWFllwPLYTajgla5tOaFATRq6cG4jJwkxRe0A2jRuyrnNrewHotnTUoBgG3Mp
LCvFgui12Jx4WLIxvz8cj8XbNvBc/JbF670HcW7DR0JMLBS1A+ATAPTj0d1Qq6ywNHgG7KzFUQgK
8u+NIP/e+GJfDLIK8oh8m/N3b+P83duY0WcIujVrKcoYx25dw45Lp4nMT674uLpzbpNw4yJps5+j
KAfAF4OxCkt3/AwfV3f8bdh40cb5YOQklFSU42OCasFiCE1kFz7B53v1ROYjZzp68av2K6edU71w
ANXcz8uBVq/D6I49MLxDN1HGcLCxrTXYRmxGduiOkR27C9qnkWWxcudmFJaXSjYPJfFWv+Gc2whZ
T0IILNoBFJXV/h93z/Xz2HP9POYNnwgvF2Gi7F7kebDN2aM4l54i2hy9Xdzx/nDhdzUbTx/Epftp
otmtdJztHXldvEp5V2MKFu0ACuuo2vJVgvBRdi/yeq+BmNpzAD7btQX5peYVuKyJWPcaF++lYtOZ
Q6J8C0tCO1rDq929vBzSpv8Oi3YABhPOWtVRdh08m2FO/5Gi2FGddpxXUoTPdm0xu7+3B4xG2ybe
gtpYUFaClTs3W9ADnXg0bujCS3QlNUce4b81sWgHoLYyPVjnxrMou5DugegrUmUhV4cGiNCEIvFO
Ci+14L5+7RHSI1BwuyL2RPHStueL0qup8T1y/XR0D2nT/4BFOwBHG+7ZbdEXTiD6wgksfG2KaNWG
e/u2QW/fNlh3Yr9JasEu9o5YNPZ1we3YdvEUTtwWrtqxqSg4cA7+Pn68fvpXGY0wGOUTAFSNRTsA
J3sH3m1X7d4KJzsHLA6aJpoCwezAETAajVj+krRjBsCisa/DWWDZspSsDPz3GLmfRkreAbwRMJRX
u28P7CBteq1YtAMwl4KyEizQ69CzeRu83lscSXHVs7Tj7MJ8fL43+vnvT+k5AL192wo6VrmhEstj
N8nyJ5ESWDFhFu+2D548Jm1+rVAHYALn7qbg3N0UzO43Ah2b8gv+qItqNZ6TqTfQr2UHwfv/Z8IO
3M+X1w20kngjYChvbcRvD8SSNv+lKMoBPMh/jKYivdubwrqT+6FiGCwNngEHG1tRxhB68SckXcKe
a+ek+DwWS5emvvD38ePVtspoxL08eaj/1IaiHEBK9gPODsCjgTMeFQkXfWVkWYTHboSnkyv+MXIS
6U/yUh7kP8bXCdtIm6F4PJ1c8WY//qXPPtn5K+kpvBJFZQNef5DOuU2/VuI86VUX94i/Ii8hjCqj
EUu3/0IXvwA0cnQyy8nfffxI0FJwYqAoB5CWy72ARv9WHUW16VDyZWj1OsFqFZiD7vhefBizDmUG
MkKmloS3SyMseG2yWX3866B8z/7VKOoIwAephDL+fSgONlZqLBs3E9YcApCE4FRqEqIvnJB0zBcZ
3r4b0fGFpI9fO0w2s0bEd4fjSU/DJBTnAAzGKs5yXD6u7rgvQQx2RZUBi7etR0t3L7w7eIzo4z0u
LsSq3VtFH+dVeLs0wvvDJxC1QUj+PGgs/DzMKyWfXZiPNBmG/daGoo4AAHA0mbuaitT161NzHkKr
1+FI8lVR+mdZFp/G/0Z08atVVvh4/BsWs/jVKiusDplj9uIH8Lt4DrmjuB3AvhsXMLS9P6c2rg4N
iNgad+UM4q6cwfxRGjQWqJz2b2eP4Fz6LSLzqeb/BryGNk24y7PJlaAuvTG4rTCybou3bSA9HU4o
zgHwjWIb0LoTMS22z/fq0cDWDkuCpvOuIiu1wEhtBLbsgInd+xG1QUiEVoj67nA8KmWk9mMKinMA
AJD+OBst3LjpA47vGkBUjLGovAwLo9fC39sPb/Q1PZ68uLwMn+z8lZjEGPBUZkw72rwb8ZqQXiRe
zm74+/AJgl4QJ9y4qJhzf00U6QDWn9iPpcEzOLdr38QHSVn3idp+OSMNWn0apvcejO7NW73y75IU
GQWevqAsGTsNDe3sBe339qOHROYzrH1XUSo8XbyXij3XzxOZk7ko0gEU1aH08zJCB4yCVq8jbT4A
4NfEw9iceBh/GjQGrTy8nv8+C2BL4hGcu0v2nP9GwDD4+/iK0veZO8mSz8fFoYEoiz85M0PRCkqK
dAAAcCT5Kq9Kwb1atMXZdOn/A9YGC+CHI7tIm/E7ejRvjWk8tO5NJTkrg/QUBcMS5NMU9wxYTdyV
M7zaTe01gLTpssTZ3hGrQ+aIuviLK8rxE0EdAiHZf+Oi4hc/oOAdAABkPsmDp7Mr53Z/GzYB/zxA
ruS33BBT/aiaQzcvI16gAqKka/F9f3gXUnPI3GMIjaIdwNcJ27AqZA7ndj6ujdDMzQP3Hss3TVMK
ND36I4BHZVsuZBbk4ct9MYL22baxsIKoXFhiYSXRFO0AjCyL3KICNOJRDeevQ8dhgV5XL1VwO3g1
w5xAcRSQqzEajfg47leUVAqfDVfX64kY5BQVIGJPlOTjig0TFhWp6DXAAFitCeXVlmVZLIheS3oK
kmFvbYNl42ZAxYh79WOq2ClfVofMkfQY8OOR3bj1SLwisyRR9A4AeHqTfiUjHV28uZcOZxgGi8dO
w8r4zaSnITofjAxBEycXUcc4nXoT+gvHRZ+LVIv/fl4O/ilTMU+hULwDAICfTyUggucuwNneAfNG
TMJX+4U9p8oFIePcX4aUWYkNbIUNSqoNQ1UVVu76DcU8402UhEU4AOBp0YW3B47m1dbL2RXzRkzE
V/u3kZ6GYLR098S7g8eKOgbLslgZ/xsKykokm9ek7sIXRqk5n68TtuOhTBV8xcBiHEBydgYKykrg
ZMevFoCXsxs+Cpouew23upBKlIRUViKfo15dlBsq8cW+GOSXFEk+H9Io/hLwRfgeBaoxsiw+jF6r
yNeBvw4dh2ZuHqKOcfn+Hfxy+gCR+TnZOWBJ0DTB+juddhP68+LfWcgZi3MATV0a4e8CiFR8eyBW
1nLONRnRoRtGdewh6hiFZaVYEb8ZLMGsRCEClk6n3UTspdMW9ZZvDhZzBKjmQX4uEu+koLdvG7P6
+euwcbibm41/HYojPaWX4u3izrtQJRfW7NXjUaFw0up84bP4r2ak43DyFaQ/Ji/aKkcszgEAwNZz
R9HO05v3fUA1zRs1RoQmFOtO7MP1h/dIT+s5apUVlgZPh521jajjkCoeWht8A5c2nEogbbqssUgH
AAArdm4WLGBkduBIsCyLz/fF4FFhPrE5MQD+Nmw8vF3dRR3nZlYGImWWtNPBqxnnNmk53GXk6xsW
dwfwIuZeCtbG2uP7cCNTuh2BvbUN/jpsPNx5hDxzoayyAsvjNqHKaJRsbqbwj5GT4OnEPenL0uL2
xcBidwDVLNDreIcKv4w5/Z9uR28/eoifTx0QrfrLyA7dMbJjd9G/EQB8vX+bLCvYNrC157X4WYAu
fhOw+B0AADBgsFrDPWuQC/klxTiQdBFn7iTz1u9r0agxRrTvhnaePpJ9m93XzuFA0iXJxuMK3x1c
5LG9uElY/k0J1AsHUM1qTSikzCRnWRYPC/KQXZCPovIyVFY9VTRuaGsHFwdHeLu4w95G3Iu8l5Ge
m41/y/iFAwDe6jcCnXiWY5eL9JvcsfgjQE0W6HUIHzdTtNLeL8IwDJo6u6GpM7mS5i9SWWXA8thN
st8ee7u48178W84dI22+YqhXDgAAwmM3Ylbf4egsQkip3PnhyC5iirxcUDGMWfENZwmIjioVNcsi
g2FATmKFABtOJcC3URO8NySItCmScDj5CnZeSSRthsnwUXmq5nuFFOWUCyoVw1wmbQQJ7uRmQavX
oazScktpZxfmQ6vXKWrxfzZpNu+2FQYDUhVYnIMkVoFTx7UFmMGkDSHFwZuXkV9ShE5NLedIYDQa
sTx2Ew6niFOcVCw+nfgW1GZkMS7ZvoForoISUYNljkt6NS5DEtNTkJieIkk2ndhIHaQkFOZGbR5O
viK7ACYloHK8do9MbqcM+fZgLBbFrEdBqXQCF0KyZq9ecYvfztoGEZpQsxa/wVilqGOOnGAAoD7F
ApiKjZUa80ZM5KU4TJKDNy9jl0D6+2LTrVkrzOhj/ulzQfRauvXnCXUAJjCl50Cz04ulpMJgwEfb
N8ha1GTB6MmCONd/H4pDei5N9eULAwDarZGFLIMGpI2RO26ODTGt9yD4NmpC2hSTOJB0CbuvnSNt
xu/o6uOHmQGml0d/FSdu38C2iydJT0nRPHUAet3nLMt+QNoYJWFjZY3RnbpjQOtOxEtV1YUc4uI9
Gjhj/qgQwb5V5pM8fGmhSs5S8swB/NeHZVXKuj2SIW0be6OLjy/aNPaGm6PpGyqD0YjURw9x/eFd
XLqXiuKKcsG2yDXZeu4YEiWOkmvh1hjvDQkS1EmWVJQjPHajpPOwVJ7/q9B7APmxLHgGHG3tBO/3
Tm4Wfjl1UDQ5b4ZhENI9UJS6gxUGA5Zs3yCK3fUR6gBkzkdB09HQTrxiGI8Kn2Dv9Qu4dD/VrH68
nN0wumMPdOSZwGMKZZUVWLrjF9H6r488dwDarZFfsQz+Ttogyh+Ronx3TXKLC3E3NxtZBfl4XFKI
ckMl8oqLYGdtA2d7R3g0dIJfoybw8/CEWiVu/YFq8kqK8NmuLZJ9g/rCcwfww9kfrG/dUVtuYLzC
qa8ZjACQnJWBn2SmUWgp/O5mhh4D5E0f37aY3HMAaTMkZcvZozibnkLaDIvld3WiWRaWVwDdgjhz
JxnL6tEZOHzHRrr4ReZ3DqDc0esN0gZRXk1pZQW0eh0e5MtPwFMoMvJzodXrUFIpjtgq5X/84XGW
HgOUg1+jJvizhYmafLkvBpkFeaTNqDf84Qo3cMr4TIZhgkkbRqmb/NJi7LtxAd6ujeDR0Jm0OWZx
5k4yvknYjqLyMtKm1CtqDc+iuwDlwQBYIPFzoRDQkF6y1CoKyrDYyTKwrL2lhcMCWLV7K6xUKnww
MkT0KkLmcj8vB98ejKVpvIR5aYA23QUoH02P/qKE45rD0ZRriL18mrQZlGe81AFooyJvskBb0gZS
zMe9gTNC+48ktivIyMvF2hP7RMs9oPDnpQ4gPDxcVdy5WRVpAynC4mTngAld+6KLj6+o45y4fQO7
r52zaNVlS+CVOZphW3UXwLDdSBtJEQ9rKzW6N2+J7s1aw8+9CVQc03YNVVVIzsrAlYw7uHg/lQpz
Kow6/7XpXQCFYrmo6voLLIuvSRtJoVDEwaT9Ht0FUCiWSZ07AABQq9TyekuiUCiCYJID+CzkrWQG
uETaWAqFIiycrnzpUYBCsSxM2gFUU6VmxBN8o1AoksPJAXw5MfQegO9JG02hUISBl1h7WFTkQwCe
pI2nUCjmwbtaA70PoFCUD6cjQE2e5BpsSBtPoVDMg7cD+PGddypVRkP91KmmUCwE3g4AAFZPfecu
C6Yv6UlQKBR+mOUAAODzyaGnYWTHkJ4IhULhjmAlW8Oi1o4FjDtJT4hCoZiOoIXttfq1fVnWeJL0
pCgUimkI6gAAYGHMWt+qKmMa6YlRKJS6EdwBAED4li02xapCWtaFQpE5ojiAasKiInMBuJGeJIVC
qR2zXwFexZrJcxuxwM+kJ0mhUGpH1B1ANQu3/NS6SsXQMq8UisyQxAFUo90amcIyaE160hQK5Smi
HgFeJGLK3DYwMD1JT5pCoTxF0h1ATbRRunUs2LdIfwAKpT5DzAEAAMuyjFavSwfQjPSHoFDqI0Qd
QDXhB9faFeca00BFRigUSZGFA6gmfEu4TbGq+UmA7UHaFgqlPiArB1CTsCjdcoBdStoOCsWSka0D
qEar/6+PkbWKZsD2Jm0LhWJpyN4B1ES7PbIpW4kfAASTtoVCsQQU5QBeZL5eN4Ixsu+DoQ6BQuGD
oh1AbSyIWtcJqBptBAaCRV8w9GWBQnkZ/x9Rg5ZQa/ILOwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAy
My0wNS0xM1QyMzo1MzozMCswMDowMO1LgXgAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMTNU
MjM6NTM6MzArMDA6MDCcFjnEAAAAAElFTkSuQmCC" />
  </svg>
);

IconChatGPT.propTypes = {
  customClass: PropTypes.string,
};

export default IconChatGPT;
