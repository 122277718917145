import React from 'react';
import PropTypes from 'prop-types';

const IconMeeting = ({ customClass }) => (
  <svg className={customClass} xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 700 600">
    <title>Meeting Icon</title>
    <defs>
      <symbol id="t" overflow="visible">
        <path d="m26.375-1.5781c-1.3555 0.71094-2.7734 1.2422-4.25 1.5938-1.4688 0.36328-3.0078 0.54688-4.6094 0.54688-4.7812 0-8.5703-1.332-11.359-4-2.793-2.6758-4.1875-6.3047-4.1875-10.891 0-4.582 1.3945-8.207 4.1875-10.875 2.7891-2.6758 6.5781-4.0156 11.359-4.0156 1.6016 0 3.1406 0.18359 4.6094 0.54688 1.4766 0.35547 2.8945 0.88281 4.25 1.5781v5.9375c-1.3672-0.92578-2.7148-1.6094-4.0469-2.0469-1.3359-0.4375-2.7422-0.65625-4.2188-0.65625-2.6367 0-4.7148 0.85156-6.2344 2.5469-1.5117 1.6875-2.2656 4.0156-2.2656 6.9844s0.75391 5.2969 2.2656 6.9844c1.5195 1.6875 3.5977 2.5312 6.2344 2.5312 1.4766 0 2.8828-0.21094 4.2188-0.64062 1.332-0.4375 2.6797-1.125 4.0469-2.0625z" />
      </symbol>
      <symbol id="d" overflow="visible">
        <path d="m19.297-15.672c-0.59375-0.28125-1.1953-0.48828-1.7969-0.625-0.59375-0.13281-1.1953-0.20312-1.7969-0.20312-1.7734 0-3.1367 0.57031-4.0938 1.7031-0.94922 1.1367-1.4219 2.7617-1.4219 4.875v9.9219h-6.875v-21.531h6.875v3.5312c0.88281-1.4062 1.8984-2.4297 3.0469-3.0781 1.1445-0.64453 2.5195-0.96875 4.125-0.96875 0.22656 0 0.47656 0.011719 0.75 0.03125 0.26953 0.011719 0.66016 0.046875 1.1719 0.10938z" />
      </symbol>
      <symbol id="a" overflow="visible">
        <path d="m24.797-10.828v1.9688h-16.094c0.16406 1.6172 0.75 2.8281 1.75 3.6406 1 0.80469 2.3984 1.2031 4.2031 1.2031 1.4453 0 2.9258-0.21094 4.4375-0.64062 1.5195-0.4375 3.082-1.0859 4.6875-1.9531v5.2969c-1.625 0.61719-3.2578 1.0781-4.8906 1.3906-1.625 0.32031-3.25 0.48438-4.875 0.48438-3.8984 0-6.9297-0.98828-9.0938-2.9688-2.1562-1.9883-3.2344-4.7695-3.2344-8.3438 0-3.5078 1.0625-6.2695 3.1875-8.2812 2.125-2.0078 5.0391-3.0156 8.75-3.0156 3.3828 0 6.0938 1.0234 8.125 3.0625 2.0312 2.0312 3.0469 4.75 3.0469 8.1562zm-7.0781-2.2812c0-1.3125-0.38281-2.3633-1.1406-3.1562-0.76172-0.80078-1.7578-1.2031-2.9844-1.2031-1.3359 0-2.418 0.375-3.25 1.125-0.83594 0.74219-1.3555 1.8203-1.5625 3.2344z" />
      </symbol>
      <symbol id="i" overflow="visible">
        <path d="m12.953-9.6875c-1.4297 0-2.5078 0.24609-3.2344 0.73438-0.73047 0.48047-1.0938 1.1953-1.0938 2.1406 0 0.875 0.28906 1.5625 0.875 2.0625 0.58203 0.49219 1.3945 0.73438 2.4375 0.73438 1.2891 0 2.3789-0.46094 3.2656-1.3906 0.88281-0.9375 1.3281-2.1016 1.3281-3.5v-0.78125zm10.516-2.5938v12.281h-6.9375v-3.1875c-0.91797 1.3047-1.9531 2.2578-3.1094 2.8594-1.1562 0.59375-2.5625 0.89062-4.2188 0.89062-2.2305 0-4.043-0.64844-5.4375-1.9531-1.3867-1.3008-2.0781-2.9883-2.0781-5.0625 0-2.5312 0.86719-4.3828 2.6094-5.5625 1.7383-1.1758 4.4688-1.7656 8.1875-1.7656h4.0469v-0.54688c0-1.082-0.43359-1.875-1.2969-2.375-0.85547-0.50781-2.1875-0.76562-4-0.76562-1.4805 0-2.8555 0.14844-4.125 0.4375-1.2734 0.29297-2.4492 0.73438-3.5312 1.3281v-5.25c1.4688-0.36328 2.9453-0.63281 4.4375-0.8125 1.4883-0.1875 2.9766-0.28125 4.4688-0.28125 3.875 0 6.6719 0.76562 8.3906 2.2969 1.7266 1.5312 2.5938 4.0234 2.5938 7.4688z" />
      </symbol>
      <symbol id="c" overflow="visible">
        <path d="m10.828-27.641v6.1094h7.0938v4.9219h-7.0938v9.125c0 1 0.19531 1.6797 0.59375 2.0312 0.39453 0.35547 1.1797 0.53125 2.3594 0.53125h3.5469v4.9219h-5.9062c-2.7188 0-4.6484-0.56641-5.7812-1.7031-1.1367-1.1328-1.7031-3.0625-1.7031-5.7812v-9.125h-3.4219v-4.9219h3.4219v-6.1094z" />
      </symbol>
      <symbol id="h" overflow="visible">
        <path d="m17.953-18.375v-11.547h6.9219v29.922h-6.9219v-3.1094c-0.94922 1.2617-1.9961 2.1875-3.1406 2.7812-1.1367 0.59375-2.4531 0.89062-3.9531 0.89062-2.6484 0-4.8242-1.0508-6.5312-3.1562-1.7109-2.1133-2.5625-4.832-2.5625-8.1562 0-3.3203 0.85156-6.0352 2.5625-8.1406 1.707-2.1016 3.8828-3.1562 6.5312-3.1562 1.4883 0 2.8047 0.29688 3.9531 0.89062 1.1445 0.59375 2.1914 1.5234 3.1406 2.7812zm-4.5312 13.938c1.4688 0 2.5859-0.53516 3.3594-1.6094 0.78125-1.082 1.1719-2.6484 1.1719-4.7031 0-2.0508-0.39062-3.6133-1.1719-4.6875-0.77344-1.0703-1.8906-1.6094-3.3594-1.6094-1.4609 0-2.5781 0.53906-3.3594 1.6094-0.77344 1.0742-1.1562 2.6367-1.1562 4.6875 0 2.0547 0.38281 3.6211 1.1562 4.7031 0.78125 1.0742 1.8984 1.6094 3.3594 1.6094z" />
      </symbol>
      <symbol id="g" overflow="visible">
        <path d="m14.766-4.4375c1.4766 0 2.6016-0.53516 3.375-1.6094 0.76953-1.082 1.1562-2.6484 1.1562-4.7031 0-2.0508-0.38672-3.6133-1.1562-4.6875-0.77344-1.0703-1.8984-1.6094-3.375-1.6094-1.4805 0-2.6172 0.54297-3.4062 1.625-0.78125 1.0859-1.1719 2.6406-1.1719 4.6719 0 2.043 0.39062 3.6055 1.1719 4.6875 0.78906 1.0859 1.9258 1.625 3.4062 1.625zm-4.5781-13.938c0.94531-1.2578 2-2.1875 3.1562-2.7812s2.4844-0.89062 3.9844-0.89062c2.6445 0 4.8203 1.0547 6.5312 3.1562 1.707 2.1055 2.5625 4.8203 2.5625 8.1406 0 3.3242-0.85547 6.043-2.5625 8.1562-1.7109 2.1055-3.8867 3.1562-6.5312 3.1562-1.5 0-2.8281-0.29688-3.9844-0.89062-1.1562-0.60156-2.2109-1.5312-3.1562-2.7812v3.1094h-6.875v-29.922h6.875z" />
      </symbol>
      <symbol id="f" overflow="visible">
        <path d="m0.48438-21.531h6.875l5.7969 14.609 4.9219-14.609h6.875l-9.0469 23.562c-0.91797 2.4062-1.9805 4.082-3.1875 5.0312-1.2109 0.95703-2.8086 1.4375-4.7969 1.4375h-3.9844v-4.5156h2.1562c1.1641 0 2.0156-0.1875 2.5469-0.5625s0.94141-1.043 1.2344-2l0.20312-0.59375z" />
      </symbol>
      <symbol id="e" overflow="visible">
        <path d="m3.6094-28.703h7.4062v25.922c0 3.5703-0.97656 6.2383-2.9219 8-1.9375 1.7695-4.875 2.6562-8.8125 2.6562h-1.5v-5.5938h1.1562c1.5391 0 2.7031-0.42969 3.4844-1.2812 0.78906-0.85547 1.1875-2.1133 1.1875-3.7812z" />
      </symbol>
      <symbol id="b" overflow="visible">
        <path d="m13.547-17.125c-1.5234 0-2.6836 0.54688-3.4844 1.6406-0.80469 1.0938-1.2031 2.6719-1.2031 4.7344s0.39844 3.6484 1.2031 4.75c0.80078 1.0938 1.9609 1.6406 3.4844 1.6406 1.5 0 2.6445-0.54688 3.4375-1.6406 0.80078-1.1016 1.2031-2.6875 1.2031-4.75s-0.40234-3.6406-1.2031-4.7344c-0.79297-1.0938-1.9375-1.6406-3.4375-1.6406zm0-4.9219c3.707 0 6.6016 1 8.6875 3 2.082 2 3.125 4.7656 3.125 8.2969 0 3.543-1.043 6.3125-3.125 8.3125-2.0859 2-4.9805 3-8.6875 3-3.7109 0-6.6172-1-8.7188-3-2.0938-2-3.1406-4.7695-3.1406-8.3125 0-3.5312 1.0469-6.2969 3.1406-8.2969 2.1016-2 5.0078-3 8.7188-3z" />
      </symbol>
      <symbol id="s" overflow="visible">
        <path d="m21.031-5.2344h-11.578l-1.8281 5.2344h-7.4375l10.641-28.703h8.8281l10.625 28.703h-7.4375zm-9.7188-5.3281h7.8594l-3.9219-11.406z" />
      </symbol>
      <symbol id="r" overflow="visible">
        <path d="m17.469-29.922v4.5312h-3.7969c-0.98047 0-1.6641 0.17969-2.0469 0.53125-0.375 0.34375-0.5625 0.95312-0.5625 1.8281v1.5h5.875v4.9219h-5.875v16.609h-6.8906v-16.609h-3.4219v-4.9219h3.4219v-1.5c0-2.3438 0.64844-4.0781 1.9531-5.2031 1.3125-1.125 3.3359-1.6875 6.0781-1.6875z" />
      </symbol>
      <symbol id="q" overflow="visible">
        <path d="m23.266-17.953c0.875-1.332 1.9102-2.3477 3.1094-3.0469 1.1953-0.69531 2.5078-1.0469 3.9375-1.0469 2.4766 0 4.3633 0.76562 5.6562 2.2969 1.2891 1.5234 1.9375 3.7344 1.9375 6.6406v13.109h-6.9062v-11.234c0.007812-0.16406 0.015625-0.33594 0.015625-0.51562 0.007813-0.17578 0.015625-0.42969 0.015625-0.76562 0-1.5195-0.22656-2.625-0.67188-3.3125-0.44922-0.6875-1.1719-1.0312-2.1719-1.0312-1.3125 0-2.3242 0.54297-3.0312 1.625-0.71094 1.0742-1.0781 2.625-1.1094 4.6562v10.578h-6.9219v-11.234c0-2.375-0.20312-3.9062-0.60938-4.5938s-1.1367-1.0312-2.1875-1.0312c-1.3242 0-2.3438 0.54297-3.0625 1.625-0.71875 1.0859-1.0781 2.6328-1.0781 4.6406v10.594h-6.9219v-21.531h6.9219v3.1562c0.84375-1.2188 1.8125-2.1328 2.9062-2.75 1.1016-0.61328 2.3164-0.92188 3.6406-0.92188 1.4766 0 2.7852 0.35938 3.9219 1.0781 1.1445 0.71094 2.0156 1.7148 2.6094 3.0156z" />
      </symbol>
      <symbol id="p" overflow="visible">
        <path d="m24.953-13.109v13.109h-6.9219v-10c0-1.8828-0.042969-3.1758-0.125-3.875-0.085938-0.70703-0.23047-1.2266-0.4375-1.5625-0.26172-0.44531-0.625-0.79688-1.0938-1.0469-0.46094-0.25-0.98438-0.375-1.5781-0.375-1.4297 0-2.5547 0.55859-3.375 1.6719-0.82422 1.1055-1.2344 2.6367-1.2344 4.5938v10.594h-6.875v-29.922h6.875v11.547c1.0391-1.2578 2.1445-2.1875 3.3125-2.7812 1.1641-0.59375 2.4531-0.89062 3.8594-0.89062 2.4883 0 4.375 0.76562 5.6562 2.2969 1.2891 1.5234 1.9375 3.7344 1.9375 6.6406z" />
      </symbol>
      <symbol id="o" overflow="visible">
        <path d="m3.6094-28.703h8.2656l10.453 19.688v-19.688h7.0156v28.703h-8.2656l-10.453-19.688v19.688h-7.0156z" />
      </symbol>
      <symbol id="n" overflow="visible">
        <path d="m3.0781-8.375v-13.156h6.9219v2.1562c0 1.168-0.011719 2.6328-0.03125 4.3906-0.011719 1.7617-0.015625 2.9375-0.015625 3.5312 0 1.7305 0.046875 2.9766 0.14062 3.7344 0.09375 0.76172 0.25 1.3125 0.46875 1.6562 0.28125 0.44922 0.64453 0.79688 1.0938 1.0469 0.45703 0.24219 0.98438 0.35938 1.5781 0.35938 1.4258 0 2.5508-0.54688 3.375-1.6406 0.82031-1.1016 1.2344-2.6406 1.2344-4.6094v-10.625h6.875v21.531h-6.875v-3.1094c-1.043 1.25-2.1484 2.1797-3.3125 2.7812-1.1562 0.59375-2.4336 0.89062-3.8281 0.89062-2.4805 0-4.3711-0.75781-5.6719-2.2812-1.3047-1.5312-1.9531-3.75-1.9531-6.6562z" />
      </symbol>
      <symbol id="m" overflow="visible">
        <path d="m24.953-13.109v13.109h-6.9219v-10.031c0-1.8633-0.042969-3.1445-0.125-3.8438-0.085938-0.70703-0.23047-1.2266-0.4375-1.5625-0.26172-0.44531-0.625-0.79688-1.0938-1.0469-0.46094-0.25-0.98438-0.375-1.5781-0.375-1.4297 0-2.5547 0.55859-3.375 1.6719-0.82422 1.1055-1.2344 2.6367-1.2344 4.5938v10.594h-6.875v-21.531h6.875v3.1562c1.0391-1.2578 2.1445-2.1875 3.3125-2.7812 1.1641-0.59375 2.4531-0.89062 3.8594-0.89062 2.4883 0 4.375 0.76562 5.6562 2.2969 1.2891 1.5234 1.9375 3.7344 1.9375 6.6406z" />
      </symbol>
      <symbol id="l" overflow="visible">
        <path d="m3.6094-28.703h12.297c3.6445 0 6.4453 0.8125 8.4062 2.4375 1.957 1.6172 2.9375 3.9219 2.9375 6.9219 0 3.0117-0.98047 5.3281-2.9375 6.9531-1.9609 1.625-4.7617 2.4375-8.4062 2.4375h-4.8906v9.9531h-7.4062zm7.4062 5.3594v8.0156h4.0938c1.4375 0 2.5469-0.34766 3.3281-1.0469 0.78125-0.69531 1.1719-1.6875 1.1719-2.9688s-0.39062-2.2656-1.1719-2.9531c-0.78125-0.69531-1.8906-1.0469-3.3281-1.0469z" />
      </symbol>
      <symbol id="k" overflow="visible">
        <path d="m3.3125-21.531h6.875v21.141c0 2.8828-0.69531 5.0859-2.0781 6.6094-1.3867 1.5195-3.3906 2.2812-6.0156 2.2812h-3.4062v-4.5156h1.2031c1.3008 0 2.1953-0.29688 2.6875-0.89062 0.48828-0.58594 0.73438-1.7461 0.73438-3.4844zm0-8.3906h6.875v5.625h-6.875z" />
      </symbol>
      <symbol id="j" overflow="visible">
        <path d="m20.703-20.859v5.6094c-0.9375-0.63281-1.875-1.1016-2.8125-1.4062-0.9375-0.3125-1.918-0.46875-2.9375-0.46875-1.918 0-3.4141 0.5625-4.4844 1.6875-1.0742 1.1172-1.6094 2.6797-1.6094 4.6875 0 2.0117 0.53516 3.5781 1.6094 4.7031 1.0703 1.125 2.5664 1.6875 4.4844 1.6875 1.082 0 2.1016-0.16016 3.0625-0.48438 0.96875-0.32031 1.8633-0.79688 2.6875-1.4219v5.625c-1.0742 0.40625-2.168 0.70703-3.2812 0.90625-1.1055 0.19531-2.2148 0.29688-3.3281 0.29688-3.8867 0-6.9297-0.99219-9.125-2.9844-2.1875-2-3.2812-4.7734-3.2812-8.3281 0-3.5508 1.0938-6.3203 3.2812-8.3125 2.1953-1.9883 5.2383-2.9844 9.125-2.9844 1.125 0 2.2344 0.10156 3.3281 0.29688 1.1016 0.19922 2.1953 0.49609 3.2812 0.89062z" />
      </symbol>
    </defs>
    <g>
      <path d="m542.97 0c30.16-0.019531 59.102 11.895 80.5 33.148 21.398 21.25 33.516 50.109 33.703 80.27v236.29c-0.019531 30.285-12.059 59.32-33.473 80.734-21.414 21.414-50.449 33.453-80.73 33.473h-119.72l-63.012 90.578c-2.2773 3.4297-6.1211 5.4922-10.238 5.4922s-7.9609-2.0625-10.238-5.4922l-63.012-89.789h-119.72c-30.281-0.023438-59.316-12.062-80.73-33.477-21.414-21.41-33.453-50.449-33.473-80.73v-236.29c0.019531-30.285 12.059-59.32 33.473-80.734 21.414-21.414 50.449-33.453 80.73-33.473zm-51.195 305.75v-138.93c0.19531-3.8398-1.8242-7.4531-5.1992-9.2969-3.2734-1.8516-7.2812-1.8516-10.555 0l-66.789 43.32v-40.324c0.17188-2.8242-0.82812-5.5938-2.7695-7.6523-1.9375-2.0586-4.6406-3.2227-7.4727-3.2188h-188.24c-2.8555-0.050782-5.6055 1.0977-7.5781 3.1602s-2.9961 4.8594-2.8203 7.7109v151.62c-0.15234 2.8359 0.87891 5.6094 2.8516 7.6562s4.7031 3.1797 7.5469 3.1328h188.24-0.003907c2.8438 0.046875 5.5781-1.0859 7.5469-3.1328 1.9727-2.0469 3.0039-4.8203 2.8516-7.6562v-40.645l66.633 43.559c3.2344 1.793 7.1641 1.793 10.398 0 3.4336-1.8008 5.5195-5.4219 5.3555-9.2969z" />
    </g>
  </svg>
);

IconMeeting.propTypes = {
  customClass: PropTypes.string,
};

export default IconMeeting;
