import React from 'react';
import PropTypes from 'prop-types';

const IconCDK = ({ customClass }) => (
  <svg className={customClass} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
    <title>CDK Icon</title>
    <image id="image0" width="256" height="256" x="0" y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAABK
MUlEQVR42u2dd3wUxfvHP7t36b03IIReRUGUogiIYAMLUm00kY6IWBFBEP2iIhZUEFFUVEQR5Ie9
iwgIIoJIDyEJ6b1d398fl5ArMzuzl0tyd9l5vXiR25n5zPPM7bxvd3b2GWHw6GMS6pIAm2T3of4o
qwyHhu1hgVmGrVH3QWDkK7VVEGQryEoTKjs0R6vIX0ZgaQhyBjq3IXCUUZKv3F9CTYGiV/dJYIrJ
fP2sMmwN2wzBDRrkPzk0WOPFrsvqy4h2BSTqh/qjrDIcGraHJWYZtkbdB4mRr9RWiVIN8tWolSVG
vlIDJJaGJGegcxsSRxkl+Yr9JdUk/1n/SVKmYV+CVYatYZshuUGD/CeHBmu82HVZfRmRLkQXa7kQ
4NGgViY0p0JApjDrT6gQoEnwQ0AkllUhoMxXmcMqBFQIuKLRVBAQqWVVCCjzVeawCgEVAq5oNAUE
RFkxFQLKfJU5rEJAhYArGo0NAZEppkJAma8yh1UIqBBwRaMxISByiakQUOarzGEVAioEXNFoLAiI
4BVTIaDMV5nDKgRcgADpO1chwKchM15ESkkVAhxlfAICCgZws0LAroIKAQdltgZlvIgyJVUIcJTx
egjwtKNCQKaMd0NAZJRsGRBwQUPeV5a/1MqE5lQI0CuoEHBQZms49J0oudoxvgQBFzXovvL4K1tZ
hQDTVkJNFQJ8GjZ9J9KN4RBTISDjK4+/spVVCDBtJdRUIcCnUXv84i2ACgHXNei+8vgrW5kwKFwb
XPXZKgRUCNQfF9nGcIipEJDxlcdf2cqE5lQIkCuoEHBQZmo4LQRSIeC6Bt1XHn9lK6sQYNpKqKlC
gKlBXAikQsB1DbqvPP7KVlYhwLSVUFOFgKwGdSGQCgHXNei+8vgrW1mFANNWQk0VAtR82YVAKgRc
16D7yuOvbGUVAkxbCTVVCBAPiiyDVQi4rkH3lcdf2cpcXcPSsM9WIdASIUAPCKJCQGZceSIEGj6A
VQigxUFAPiCICgGZpt0FAZaGfOVmgYCCW45mhwCppgqBi3+xA4KoEGhkCPBoUCsTmmsCCCicd2hW
CND6WoUAAIkzIIgKARUCxAZVCHg7BPgDgqgQUCFAbFCFgDdDQFlAEBUCKgSIDaoQ8FYIKA8IokJA
hQCxQRUC3ggB1wKCqBBQIUBsUIWAt0FAdN0pFQIqBEgNehEESN95C4OAyFtQhQBUCIATAupiIa+B
gMhbkMdgFQLK7fBJCPC0o0JApkzTQUBkG8MjpkLA6ZALGvK+svylViY0p0KAXqFlQUDkM4ZHTIWA
5AYNuq88/spWViHAtJVQ08choLU1RoBQX1CgicmVqT8oSYDgiobdMbIh9tqEMhwa9mbY+E4sw9ao
++BUUoFGpzR/9O0VjG4dApDW2h9+WgGCAPhpnesZTRIkyfr/f2f0OHZSj39P63HirJ7WUQSTqV+S
rIZ9tgShgRq2+fRTj1+jYf7C+fuinrK1n1weLzzjjq3BHnd0DWHQLf/YccFuIFD7ilWm/qDgqoZA
/UDRFlzSsDeDVYatUfdBYGi0beWPUcPC0LdXMEKCRYSFaJjnJk8ymSRU1VhQUWnBj39UYeePFSit
sHCYzGicYRwTAhwatvmCGzTo/vLYSqhJPWUFltG0ijaH+ccUTzsCp4YTAPiM4TFIhYDtIT8/Addf
E4abrw1HYpwfQoIpSzAaIRWWmHHirB7vflaKc9lGGZNVCJAr+C4EiADgM4bHIBUC11wZirE3RyCt
dQAC/N3wE9/AlFdowt6/a/D+9jKUVZhVCDBtJdT0IQgIg0b9I7luDI9BLQ8CoSEiJo2OwdVXhCA2
WgtPTAajhEP/6vDe9lKcSjc4+KdCgFzB9yBgBUCDjOExqGVAIDpSg6njYzC4XygCA5ruEr8hyWIB
/jmhw8atpTh+Rm/jlgoBcgXfgoAwaNRhiWWwCgG5MgL8tAImj43GyGERCA7yjoHvmCwSsOdgNV7/
oBiFJWYVArIVfAcCtQBgG6xCgFzm6itCMeueOMTHNOxSv6TMjJNn9Th6Uoe8QhPyC00oKDahrNIC
nd5S32BtCvAXEBGuQXy0FgmxWiQnaNGzSyA6tQ1AaIjrENIbJGzZVYYPtpepEJCt4BsQsAEA22AV
AvXJz0/AQ9MTMPSqMIguzO3p9BL2HKzCP//V4ODRGuQWGCFJ/JOLcr62a+OPnp0DMaB3EHp1C4LG
BR5k5Rix6q0iHD9rYBdWIeC1EHAAANtgFQJAp3aBWLEoCTFRyn71q6ot2H+4Gt/+WoE/D1e7bAcP
BOpSSJCI4YNCMbR/CLq0D1Bkr9ki4d1PS7Hlywp24YZCQOHVhgoB5RqkcUcAANvglgyBEddEYOH0
eGg1/D/7J87o8fUv5fj6l3IYjPVLsehNuxcCdalrhwCMviEcA/sEK7L/yAkdlr1aiPJKi3zBlgQB
Wl97GQQoAGAb3BIhMPOuONxxUxTXD4TFAhw+VoN3Py3C0RM6ajtNDQEAiI7SYNrYKAzpFwKtlg8E
OoOEhSvzcOoc45ZAhYB3QWDQyMMSs2ALh4AoAisWpaBf7xDwpPMXDFi7qRAH/ql2zvQQCEAAkuK1
WDg1Fr26BnL5BQBL1hRg39818mE/VAh4DQSsAOAxqIVCQKMRsOqJFFzWPRispNNL+OT/SrDp0yL5
DvEgCADAFZcE4YEpMYjjXLT06vsl2PVTBSxydwQqBLwCAprUzjOX8hRsmDE8BnkeBLRaEaseT8Fl
PdiD/3y2AQuWZeG3/ZUOOp4Pgew8E3Z8V4GkeC3apvgzz/crewVBb7C+hSj7lq0KAY+HgCa184yl
iu4bWggEtBoBzz2egt492Jf93/1ajseeu4CyCjPFFs+HgEUCdh+oxrFTOgzoHQx/P/kTvnf3QBUC
8oVZf8ITIMC5M5BNhsvBDXja8YygIqIAzLg7jjn4LRYJL6zLw7Ov58JklgvW4T3hxf76V4e7Fmbh
Qp4RrDR1TCTG3BAuvw5CDTTq0UFFRImzII/BvgKBUcMjcfsNUZBLFouEuUsy8eVPZc6+E6W9BwKV
VRZMfiQbuw9Ug5WmjonEyGFh8oXUQKMeCwHR/pgKgUu7B2PelATIJYtFwvRHz+O/U7qmCS/WDBCw
WIBlr+TjtfeLwEqz74zCwD5B8oXU8GIeCQHR+VjLhUBinBarn2oNVho/Ox1nM2xDb1F8JzbfAAi4
oEHz1UGQWm3HdxVYsCKH2SdPzY1Du9Z+8oVUCHgcBERydsuEwKrF7ME/ZWE6CotNDDMaCQIuapB8
le0Th0NHT+q5ILD68QQEBzImylQIeBQEnHYGaqkQuHdMLFol+UMuPfpsFs5lGcAz+HwRAouezZU9
54ODRDw5Jw7MpELAYyBA3BmopUGgS4cg3D06FnLpg21F2H+oiqpBNsO3IPD3fzq8sblYtp/69AjE
mBvCIPNl0wxwyFYh0BQQEO0aI9bxbQj4aQUsnp8EUeaV2TMZemz8uJCqIW+Gb0Hg82/L8c1vlZBL
k0dHomOqP1QI0Cp4DgRk1wG0BAiMuTkKKYn0S/8anQVzF5+X1WCb4VsQeOntQmRk09cJaLUCHpwa
U1tPhQC5gmdAQGSJ+TIEoiO1uGcM/dLfYpGwaHkmdHozVYPhMNl3YhnvgYDZAsxfnuO88tEmtW/j
j4mjIlQIyFZofgiIPGK+CoH7JsYhwJ9+7f/NL+U4dqqGLtKCIVBVbcGKtQWyLwSNuykcsVEaFQKy
FZoXAqKsGPGob0CgQ1oghl8TSauIkjIzVq93nPVWIWB76O9jOny/hz4fEBQoYsad0Tb1lA0uP62A
1knai//iojlim6kQUDTutHQx4eJ/Dkft/nIsw9KwNaY59yKcNDZOduLvpfW5MJkkgjbBELtDVIdt
zPCMvQhJZZz7Ud7fl94uQq8ugUiIJZ9KA3oHoWNbf2sgEQmAQP2y0feSIPTtGYgObf3RKsEPkeHO
X5DFYt3cJDPHhBPnDNh9oMZutyOKE9R8+qnHr0HvXon9AhHp+6Ke9vJjimdcOo474eqbDikOCEJ8
98qL3iJMbR2AjavbUQFw8qwO9z9yzlnBhXgC8q6wynjKW4Ty/l7RKwjPLKQvnz56Uo8Hn8kliifG
anH7iDAM7R+C8FDXohmnZxnx9W9V+PLnqtqQa1QnqE4261uEdhWa9i1C4jqA+kS+7PD224G7R8dQ
B7/ZLOG5tRfICt5yO6DQjobeDuw/XIMDR2pAS906BqBjms2TFklCbJQGD06Jwabnk3HrdWEuD34A
SGvlh5kTIvHJy0kYd5Pti0nq7QBr3Imsgr4GgfBQDQZeQX977fCxaqSf11MNadkQoGu8sqno4i2T
YxIFYM7d0ZBgjbB0xw3h+PClFFw/KMQtOyLXpaBAEVNGR+DD1Uno3tFf8bxDs0OAVLORISC6YwB7
EwTGjIqmbttlNkt4dWMeuT3boy0WAnSNnHwTvv6VPiHYuV0AOqT649lF8Zg+Xv5V64ammEgNVj8W
jwkjw6G5eH8tkzwFArS+bkQINCggCNEYhRr2uY0PgcEDwmnCOHqiBucy9VwDR4WAs8b720uhN9Cv
At54OgmXKghA2tA06bZwLJoWDX+tABUC5DLMhUBEMS+FQLvUANkXfj7eXkTRVSHAA4HiEjO+2y2/
TFhJKi03IzPHiONnDDiRbkB2nglVNRZFGkP6BeP5R+IQ4KdCgFRG61xQ+eM9+6KuadjnNs4jwrtG
099Uy8k3Yu9fldQmaIZ4zSNChXaQyjB9BbDt63LcMDgUGhf2S7NYgPMXjPhlfzW2f1dBHuyCgNRk
P9w2PBR9egQigWNPxi7t/PHAvVF48Z2S2tBtXvCIkPZdsMadwvGi5S1ILONlEOjRhR615tc/yska
KgQU+ZqZY8Seg9W4ui/fHgp16cRZPZa9UoDCUsYvvCQh44IRa94tAQBc2SsQD0yKRkykRrba0P7B
yM43YfMX5bVXWioEIAAi+WrE9dsBqYEa9rnuux1ISvBDXAw5Yo0kATu+KaFrqLcDinz98if+24Dq
GgseejYPc5flWrclV7jkd99hHe5ceAE7f6yEhVH17lvC0ad7YK3J6u0ApLqYgG6EgP1Hz4HALSPo
M8+n0nXIyTPIa6gQ4Pb1wJEa5BaYwEqlFWbcNjMTh4/rlA0KhzIWi3WzkgUr82A2y9ddMjcGQYGC
90GA9J27AQL1MQF9HAJ9LgmlVcSBw5VcGioE+H3d/m055FJWjhFj5mTZ/Wo3BAIA8N9pAyY+mCP7
glKAn4B7bo2ob7CFvztgtw7AlyGQEEcPWLl7XwWXhvMxFQI0X/84RA8pXl5pDTtOOuEaCoGScjNm
LZMPXXb78FCkJvtRNeTa8DUIOK0D8EUIxERpER5GniSqqrbg+OkapgYt2ycg4IKGvK8SLuSZsO0b
56sAvUHC2LmZtMqE5pRD4Ox5I97bIX8FMm1MhKyGXBu+BAHiOgBfg0C/PvSlvyfO1Ljmry9BwEUN
uq/WMus+LMar79WvrThxVo9x8zKd79MbAQKbd5ShvJJ+L9D3kkD7qwCednwQAtSAIL4Egf5yADhd
I9PfKgSU2OrYjxaLhC++q8CIe8/hxikZmPNUDqqqKIOyESAwY0kuzJRHA4IAjBwawtSQy/cFCMgG
BHELBIjFmxYCrZLpq/9OpetkfOWwVYWAjK/WMhYLYDRyfF9cEOAfXIUlZpxIN1CLDu0fDD+tIKvB
asPbIUB5B9ONEKD+2XQQCAqkv2p6Prt+hx8VAq5r0H3l8Ve2MqE5/sG1ZE0hdX1ASJCIK3sFMjVY
+d4MAZFnAHs7BGhv/wFAZrb9Rh8qBFzXoPvK469sZZchUF5pwYU8+pqEAb2DmBo8+d4KAb6AIPBu
CPj7k5dbVlaZoTdYnCqqEHBdg+4rj7+ylV2GwJYv6U8ELusWwKXBk++NEOAPCAI3QYCszK/hAgRo
AKioNDupyPvKYasKARlfefyVrewSBH7YU40aHblcdIQGbZK1TA3efG+DgEiuTDfDG58O0N5MI8e1
VyHgaxAwmSzIK6LfBvTqwrgKoNhBy/cmCIj0yvQMb4QAKVVXWyhlVAh4HwTk039n9NS8zmn+LXbf
AVG+Mj3DVyBAL6NCgN51nggB+S969wF60NKUBL9aiZYHAZEq5EsQoKSQEHJAJH5fOWz1JQgotIMP
AiwN+cq8g+LvYzpqdmKcxkaiIQY629HsECDVtPlTlBVqBgi4U6NuINBeEQ0P1XC0o0KgcSGg8Nxz
EQJGk0TdyzA6QoOZE6MwsHcQ4qI1bAhwzDt4DARofV37p9ZOSGBFiKHbKchE46Fr1GZQi7qmAVh3
qO3RJRjXDYqESJkErF8gxGqn/qDSHYjI/Uh2Ro0sJKdBrUxojvwlVVZbEBlOfinstutCcdt11lfG
T6Qb8PWvVTh0TI8cWlwDCbK7HDnaQT99pWaNLGT//EMRBByGqodAIDJCi4FXhmPOlEQEB8mHiYqM
0EIUUfv+uAoBVjveDoHKKr5L5s5p/uhcu5HJkZN6bN5RjiMnDbXxBB0b9G4IOD8A9VIIxMdqMf62
eIy+OQZKUkKcv000IBUCrHa8GQI880KOqWenADy3yBpMdtWGEvx+sBo6vcOltBdDQCTf9iq5L5M4
qrp2Py9xaAQHibhrTDy2vt1V8eCXJKC6hr4YyJfnBLRaAckJfmiV6Fd7K+T7cwLpWUY0JD08LQo7
3kjBtf2DEWC7uMyL5wSEgdcfkAAKlWg0oUJGYFQV+DUEWk3rX6IIXNojFM8taYsAf9f2ldu9rxyL
nzvP9MUdG5ISNQTqB4q24JKG7WFRENApLRDPPJKMqAjrLZJOL+GF9XnY/WcljLQxQmhHYOQrtZXp
q8xh0pcgEPI3rUpGUjw7lDgr6QwSFq8uxL+n9fUhyAQ5A53tFDjKKMkXFGnU/lcHAKpBHgiBoEAN
lixqgwF96bv8yCWzRcLZDD1mPXzG+pqqqwPYyyCQGKfF8odS0D6VvPKtvNKMh1Zk4+x5A/mHxEcg
cOuwMIwYFIK4aC2CAgXyK8Gc6cBRHZ5bV4yKujgH3gQBAMLAEX9KTIM8CALt2gRi3eqOin/1TSYJ
peUmfPhZAXZ8UwyTyeG+2IchEBgo4sn5Sejfmy9Wf+YFIxauyEJRCeGxmY9AwDZ1ae+PCTdH4LJu
gQjwF1zasPThVQX454S+HpxesjW5FQA8BnkABEYMicITC9pASTKZJbywNhtf/VhCacF3ISCKwP13
xWHMTVGK+qwuHT+jx7ynspzXUfggBOpScKCIufdEYXC/EGgU3lnu/KkSaz8o9SoI1AOAx6BmhMCk
CQmYMjERvKmg0IBJ806hqtoMSSJfTdS34HsQGDowHI/MSmzQ5S1gnVPa+X0ZXt5YwLTVqSWBWJjb
X0GQrSAr3RAIANY5pk7tAvHcQ7EIDuQnwfGzBsxfkc/dTnNDwB4APAY1AwTmT2+F0SNjwZPKK0yY
OOME4VXflgGB5AQ/rF/VFsFBrk2M0pLeIGHN2wX49lebd+t9HAIAIIgCUuK1eG1pAjcIcgtNuO+J
PBjqwqB5MAScAcBjUBNCYPLEREwan8j032SSsHTVeezeVybzQMZ3IRASLOL1laloLRP/0B2prNyM
JatzcPSEjmqrr0EAggBRAC7rHojlD8RCq2HXySkwYfpiz4cAGQA8BjUBBG4cFo2H57amLuWtS5nZ
esx6+BTKKyxEOWI7PgKBoEARj85OwoDLQ6HhODFt0/99X4bzFwyYdU+conoAcC7LgCefz8GFfKMP
QECusr1GaIiIx2fE4PIegWClk+kGzF3u2bcDdADwGNSIEEhrG4i3VneCP2O2//MvC/Hy+mxIFmcN
X4aARiNg+p1xuHlYpOLL/b+OVmPNhjxk5Vgf+kdGaHDP6BjcOiJSkY7FAhz+rwZLX8pBZbVtuG/f
hgBgjSW4ZHYsREbX/3FIh6WvFsraQWujKSAgDwAegxoJAh9v6IrkRPlILS+9mY3PvywkKfPb4YUQ
uHFoJCaPi0VstLIFLTn5Rqx8NQfHThFejRWANsn+mD0pHn0vCVakq9Nb8O2vFXhzc5FNjEXfh0Bq
ih9efDQe4aHyFPhgRznet92pyIMgwAYAj0FuhsCyR9Iw5OpIWZPWvJmNbbsKndrxZQj06haMBfcl
IrUVRwgrm2QwSFj5Wg72HKyEySSzbl2w2tizcxDmT01AWmtl8wnFpWZ8sqsEW3eV2hnuyxBIjtfi
5cUJiAijQ8BolPDAMwU4fd5A1GC10ZgQEAYO3y/xToTIGuQmCHRsH4R1L3aGVubx1dqNF7Dl83zQ
Bo6vQaBNij/mTU1En0v4FvLYpvWbC/DFt6WorrFwzSvUHdZoBPTvHYpF9ycgLFTZLUZmjhEbtxTh
1/2VaAkQaJWoxerH4qmvGgNAdp4JUx7LZdpBy28sCFgBwCPEY5AbIPD+G12R2po+wfLVD8V4ds15
Zw2Bqsxvh4dBICZKi6kT4nDdoAhZIJLStq9K8NH2YhSVmKhN8Aw+P42IkcMjMOde5ROF/57U4fX3
C3G8Nh6fL0OgTZIfXn0qAUEB9Dof76rAO5+VMe2g5TcGBOoBwCPEY1ADIHDtoCg89XBbqsTpszWY
segUDAYLWcNHIODnJ2D8rTEYNyoGIcH8v76SBOz/uxLrPyhAeqb+op6cmTyDT6h9zDh5bAxuGR7J
nPiyTRYLsPvPSqz7sAi5BSafhsA1VwTjiZn0N1KraywY90BO/aNBGTto+e6GgKZN+/uWKhLiMchF
CLy4vANCgsmXUWazhMeWpyO/0CCr4e0QuH5IJFY+1hoD+4bB34//Vz8jS4/n1uZg09YilJSZ3f4W
odEI7P+7Gr/tq0RCrB9SEv25T5XUVv4YOSwCkeEaHD2pq52HcM2OpocA/3jIyDYiJkqLjm3Jcyd+
fgKiIzXY+7eOyw5avjshYH8F4ILTVIMUQqBrxxCse6kztdpnOwvw8rpsVg8Q870BAr0vCcGsexPQ
vi37+bJtKis3492tBdj+dalzK26GgK3vl3UPwvQ749CpnbIJSZ3ego1birHt6zJbYUV2sCGg8AfI
jRAIDhKwfnkS4mPIP2Q1Oglj5l2A0UR6v6LpIeB8BcAtZG9GQyGw+pmOiIokP9aqqjbjiWfOQm+Q
ZDXsMqhj0LMg0DrFHw/PSsbUifGIjuJ/rFdTY8G2r4rx2HOZOHayhmpIY0Egt8CEXT+U4UKuEZ3b
BXLfqmi1Avr2CsbN10YgO9eIzNq1CL4CAaMJKCk34+rLyY9S/bQCBAj4+zhhn4JmgAD5CoBbyN6M
hkDgh+2Xwt+PfBJt3JyDdz/Kda7oxRAQReC+OxMx4TZlUYxMZgm//FGBde/noaDIxDVwGvNKoC5N
GhOD266PRGiIsicG/57UYfHzOdb36X0EAgCwfkUi2qb4EYtUVFlwx9wLMrY1HQTkvy2F4ZFdDS92
0/AY6uA3miR8+X0RuaKbw4uxNOxzFWgQyky7M0Hx4P/nWDWmL0rHijXZ1sHvpNs84cUA4N2tRZgw
Jx3f/FJuHzOPkbp3CsS6Z1tbX731ovBi1FRbZtPnZdQiocHixWhM5AabLryYyI6B3vgQmHZnMlV+
994y5BcYyRXle4BRtPkgEBgoYuJtfG83AkB2jgGLnj6P+U9mID2DcOnoIRCorrFg1Rt5mPZIBg4e
qa4PlcVI8bFahIVpXLLDUyHw+8EaFJeR9yEQBGDWxEiZ+nIGOtvREAiI1v+bFwLhMgsovvq+qMkD
jbI07HOVQ0AjclxK1qbSMhMWPZ2BA4cr5dvxEAgAQE6uEc+vy8PhY9XcftrFLfARCHzyZQU1u29P
xmRvE0FArC/XPBAIChSpL/yYzRIOHamUad51CEgN1LDPbdjtgFyKjNDiwzc64oH7kuzXnHsoBMJC
RNw3MRYfv5aGy3ooe6fAW6MN09L+f+j7Efr7Cx6xA5FoX84NEGAZ5KBx6030FWZ/HamATmemVXVu
ReEAblYIKEy3XB+FHZs6Y9SIqPqQ1B4EAX8/ATcPi8Cn69ph/KgoFzyUGE17HwSycowoLCHfBmhE
4PIegc0OAdG5XAMhwGOQTf51g6OpMrv3ljmptGQIAMCC6YnY/m4nXN4rxPr+fzNDQBSsLw9tXdcO
C6bFK16yTGrUlyDw4x9V1Lybh4bWSij7YWXZqQQCWnI5CYILu5TQykggPK6QJAiigDSZhS9n0m0v
oepV3LkDkYOyyxr1uTbRhmU1XE+BASKeX9IGRSUmzHn8HHILCMH87domG9LQHYgiwjXYsCoVMQrW
L7CTtR1609SOly3THHsRAsC/p/SgpSts5gGYY47RjmM+/dSz19DStRofAgKsvyC0lJPruOzXlyDQ
8BQTpcVHb3RAZrYB98w749xOI0Jg7YrW6NoxqHEco0EAdQfcBQFA3l9qZUJz5PGQS9tcFNbiglD/
w9wcEJBdB9DYtwPWDqA7U1BEWvfvS7cD5PT30SpF5Vun+OOHrV2xdGEr2e5wx+3Ak/MT8d1HHRUP
/iPHdTiToZcvRLBDopZxx+0A219GZebtAG0OALCe+46nP3PMUdqh5bNuB0TW2dmYEJADWUamzibM
l5Mgo3nvhsADS85h3P0ncT5Lz11HFIFr+ofhq82dMW5UjH3fNhACggCMvTkaX77XEUMGhCt6jJlf
aMLEuelYsCyTb12Aj0GgosqC8kr6egDSGGhKCIg8s4yNBQEBdAiUlBpZ1jOa924I5BUYMemB01jw
1DkUl5q46wUGiJhxTzw+29DRPoCIixDo3TMYn67vgBl3xyEwgH+Zb0WlGQ+tyMKd89KRV2DiOkXk
bPU+CNQfKS4lk0/uCripIKC9mMvY4rgx5gTkUlGxzUnPuhenNq/wfh6k4o0wJ8BKtToWC3DoSBUm
zDiJa/pHYNHsZO6NPqIitHhhSRtk5xiw5IUsnM3QK5oTSEn0x8pHWykOM240SVi7KR/f/lq/JFhw
ZRKEYKuTxRcPuGNOgKLj8pwALo6pjAtGtG1Ffi9AtguaYE6gfhKwGSDQq2eYgt5A40OA+mfzQQCw
bsrx7S+l2HOgAqNvisbk8fHc3ZaS5I+3nm+Hf0/WYMmqTJSWm2UHRWS4Bk/MT0bvHiGKAn8AwMc7
irFlZwnKKmoveS+6K7VoCMjd+nRN88cRmScFjQ0B+6cATQwBhXNjvgUBF/ytrDJj0ycF+ObnMkyd
GI/rBkVwyYgi0LNLED5Z3xHf/1aG1zbmoVpnsWskwF/A/GmJGDogHAEBygbrr3srsG5zIXLyHR5H
2nUZ69uWuZT3dgjIJD8/MMdMY0LA+TFgU0LAleQDEFDkJEEnN9+AZ9Zk49P/K8a8qYno3plvRt5P
K+CGIZEYcHkYtu4sxpYvigABGHNzNMaNikFEmIZLpy6dPqfDmg35+O90Te3XqeDZOstv6mEvhACX
680DAfI6gCaCQGZWjWw+VaOpIEBW5tdoRAgAEk6crsHcJ9IxoG8YZk9KRFIC331mRJgG0ybG4cZr
I2CxAK2SlN3nF5WY8Nq7+fhtX4X18tbVBTaOZbg1fAcC5ZUWWQ37JtwPAfoSriaAQHGxkZoXU7fp
RXNCwCHfnRDgS2wIWCwCdu+rwN6Dlbjp2kjMnpwIf3++BpMTlA18g0HCWx8VYMc3pfZx/TgmF5Ux
z7cgIJfOZRuZGvbZ7oWA/BrORoaAJJOdEO/P1lAhcPGgySRhxzcl+PaXMtw7Lg5jR8YonsSjWiAB
W74oxnufFkKnt9/5h2Rmgxx2BwTIhbltdTcE0lopXCrdhBAQ+V7zZbXF0pCoh2k1kxIC7F8T5ogs
RMvw5HUCfInVTv3BGp0F697Lw52zTuGXP8q5g3KQksVineCbOPsM1n2QjxqdrRjBEAny+Yrd5fnO
JUbT/Bp2RyTZCvIuOlSOpbwrIUkyU2GMMcW1ToD1HUhS3ToA/ksGOYOUXglIkgTJIgGUXW3jYvyQ
naOX1bjop3olYOdrboERS1/IQq9uwZg6MR49uyp7N/+/UzVYv7kAf/9rH9SjoS8QKXfXxSsB3tsS
RVcCYGg4Vw4NFqlxEqW6S2CqdgOvBDiu4G3WATQ9BGQJCCC1TaA9AOTsaAYIuKzBSi634+zr4WPV
mLf4HIYMDMfk8fHMhT05eQZs2lqIb34pqz1CmBBtsRBQOMkpSYiPkZlmkyRYWOdDI0NAtL8Scu1S
3tEgXg1JAnbvLaUW7dUjjHLFq/R2gFXVtUt5yQ0aYBRVbiv51uen38txz9zT+GVPOU0Yv/xRjomz
z+Cbn8uIevR+bPjtAPXJiMfdDig79xJj6Y9WjSZYr4CZndPA2wGJbqDo7H/TQuCznfnUYjcMs77U
0nAISBxVmxgCPMnNEACAc5n0VWfnMg0kCagQcB0CvbrS413sO1xNEqdoNw4ERLt8zsbcCYFDhytg
NpPLRkVq0SEtyNk+lh1eDgH3xBhkTYJyJBUCynx1OCwIwMA+9LmXnT/YBnptHgiITvm8BrkRAtU1
9KnqIVdHke1j2eHxEGB0jc9CgMcV34BA2xR/JMSS5wDMZgmHj+uUjTmOMkohIBLzeQ1yEwR2flVA
zb59ZLzdG3DugQBrYLgOAUmRBqNrmhoCEkVDhYAyX2sPX9GLvkRbb5TICk0MAZGaz2uQGyDw+oZM
WCiTIaEhGowYGm0/eajEDo6B07zrBBh9524I8DXKOOYiBBS27c0QEEXg9hHhVDd/2efweJUuTrHP
PRCgLgRqaggUldCXBd87IQmiKKgQ4PSF5/aHo1HGMRUCcr727RmE6Ej6E4B1HxYzmmsaCIhyQk0J
gVfeOE/NS0oMwOhR8U7tqBBQaqvCewAVAi5D4O7bI6mulVdaUFVjIZ5wTQ0BkSXUVBD46bcSlJbR
Q1/dPzkFYaE2LwiR7GPZ4S0QoJrfVFcCLQwCLmjI+Xr1FcHo3C6A6tbO78tplQnNNS4ERB6hpoLA
B5/kUOsEBoh4/MG2xHa8EgLMTqIddgcEFBrg6xBwUYPm69x76Ls+1+gseG97qVxHEZprPAiIvELK
IaDcoC2f5SKvwECtc1X/yPpbAQc7fAsCkmxR74WAgvaoZng2BObcE0Pf+hvA/sM15Be0mgkCohIh
ZRAgd5KcQZIEPP/yOWp5QQBmT2uFTh2C7SuR7GPZ2VQQIBZXIcDVHtUMz4TA4CtDMPJa+sx/dY0F
z68vpH8XXBBo2BW445gTlQo1NgT2/lmGw0fp2yr7+4t4YXnH+vkABzs8DgK8xeU0mggCEk9OY0FA
4YScp0GgfRt/PDgtVjYGw9Yvy6E3yH+nbAjIVaZr2GfX54uuCDU2BBYvP4OqavqOKtFRfnjzpc7U
eAGeDAGNRsC4W2Nl++P6oVHOGu6CACNJroKEMYCvvCxU9k3Eh2cmIjhQlNVwNsMzIBAVIeKZhxIQ
HEQfThfyTfjoi1IZO2yPNx0EOAKCND0ESkqNWP1ahmz51NZBeGdtV6+CwA3XRmPzG11w7/hEWd8e
m98ab6/phJ7dQuw1vBAC7doEYO0zqXju8VayG4v07hmMbW91wOx74+HvJ8g0Ao+CQFSEBq8vT0Fs
NP21X6NJwopX82AmLXZrZghoWqdNXQqAHfmAki9wlLEvLF+m7t3mM+k1SIwPQMcO9JcpIiP8cMN1
Mfj+52LU2L5PYGOHQG5Exj75DHJVQVaj9yWhePrRNNx6YwzCQvki70ZHaXHjsGh06xyMYyerUVFp
rhenmi9vBwBc2iMEl/YIIeb9/W+VXQAQgaM/iO0IQGy0Fo/MSsbsyfFIiOULVqrRCOjWMQg3DImA
wSDhdLqu9hxmf18CswzPdy6QS1I0urYPwEtLkhETKR/y68MdZfjJbptwgi20viZ8Cc4muzZ2AdgA
gFFQLr+xILBnfxkuvzTcPj6gQwoJ1uDWG+NxPkuHjEwd0Y7mgkBKUgCeXJiKaXcnITZa+c4wANAq
KQCjRsQgNtoPx05WQ1+7246rEGADoIrDV7K/QO3WZHfH45HZyWjfNhAiM6SOcwoOEtGvdygG9g1D
br4RF/IM8DQI3HZ9BB6dFY/QYPm76D0Hq7FmYyGhHz0DAvYAkCnIym8MCEgS8NueUgwaEIWIcDpl
/fwEDLk6CrEx/tizr8xWhGwfy84GQiAyQovZU5Px6Pw2aNOK/j44b9JoBHTpGIybh0dDFIFjJ2sc
wnE7WkGHAP8VgDIIaP0E3DIiGiseboXLeoZAy7mFmVyKjtTiuqvD0b1zEE6l61BaTnlrtAkhEBos
4sl5ibhtRATTx5Nn9XjixTyY6zZKalQIcPQ3oYwzAHjE3AIBtsGCIEBvsODXPaUYcGWkLAQEQUCX
jiEYeX0szp2vQfYFvVM7jQ2BgAARE0cnYOkjbXFJt1Dr+wtuTAH+Ivr0CsWN10ahsMSE9AydYggo
uQXghcBVV4Rh2aJWGH5NhKINRHlTcoI/bh4WibgYP5xO15NfH28CCNwyIgIrFyUhrbU/8/RNzzRg
4cpc1Ojs78M9DQJkAPCINSEEqqvN+HVPKQYyIABYbwlGDI1Bl04hOHdeh+ISo51VjQWB4YOjsXJx
GgYNiLSfmGSkjEwdtu0qRFpqIPfgCQnWYPCACPTrE4Zz5/XILyK/SEWCgDIAkH2tS907B2HJgykY
f2sMIsL5dxWq0Vnw0fYiVNdYuDcfFUUBndoF4sahERAE4ORZ3cVfVoKpboOAKAJD+odi+YOJuHZg
GNfmrCfO6rFwZQ6qqsmXaQIBNPJ2UCsTTFYGAaHfkN8lgaOgkvzGgAAAxMX447llHdC5YwizTl06
cKgcH32Wh4N/V8BUG3nInRC4tGcoHri/Fdqn8W3PVZcqKs3YtCUPW3cUQIJ1fcO0OxNw+02xigAC
AN//Wop3t+QjM9s53JcjBCaNj8ekceTNRd/dko93t5BiM9hDICneD1MmxmPoVRFQcpFjMkn45pcy
vLIxD4ba5+H9eodi6oQ4dGgbwC8EoLjUhNffK8CPvzusGXETBCLDNRh0RSimjouhRvUlpb+P1eCp
l/KsL/uQjLLpR7l8ucPuhIDQb8jvcvOsHgeB4CANHn8oDYOvimLWsU3FJSZ8/n/52HewHGfSa2Aw
kC4j+b+I1imBmDE5BYP6Ryiyw2CU8NX3RXjx9Sx78dr/QkM0eGRua1zdL1zRLYTFAry7JQ+f/V8R
KqvsfxptB8KkCQwAfFxAIyQCA0SMvSUG946JhUajxDYJBw5XYc2GXJsNRO3rj7wuEveOiUVMlLJN
NM5l6rH6rXwcPWGzzVwDINC3VzBGXReBAX34f2Tq0ufflGHd5uKLPzRgDHJPgMBFAMi15WkQAIB7
JyZj0sRk+Pkp+AmqTUajhCdXnsHve8ucMxkQiIrU4u6xibhjVDxXH9clk1nC/oMVWLkmA+UVlGtX
G70ObYOwaE4rdOkYrKgdiwVY9VoWvv+tFEabqDN1A2HS+HhMGs8AgIMtogAM6h+OJQ+2Ujyvcfqc
Dq+/m49DR6ucdEln3Kx74nHTsEjZRTWk9PuBSrz5fgGyc41O0kogsPC+eNw4lL6cl5QqqyxYs7EQ
P++tdM70cAhoWrWdspQ5UcYj1sQQOHykAj/vLsaVfSIQHq7sV0OjEbB5ax6KSozctwP+/iJuGBaN
V57thB5dQ7kHpSQBJ05X4+GnzmLrFwX1S0FpPVX7X3GpCbu+LcaZczp07RTMvX5AEICrrgzH2Fti
cexkDfIKDBfXgwgQ2HMAR+vnAEQN0L1zMNY93w7XD41ib0dlkwoKjXh1Yx5eWp+LXMdtw2VO+j8P
V+GzXSVISw1AcrwfN3DaJPvjtuuj4O8n4FS63nqL4QIEKqssuH4wPwCOntBh3tJsnDirA98A9iwI
CP0G75YcC3oLBADr5NDUu5Mx9vZEBAXy/WoYTRKGjPxL3t/aNkQR6H1JGFY+2R5BCn+V8vINWPVq
Jv48VA6Hs5HmmVN+3Z/jbo3D3WMTuEFQl85l6vHUqvPIyLIuqpk8PoFxBZAPQRDQOjkAjz+Qgi4d
lM1tVFaZsW1XMd7ZUijvL8dJn5zgh6ceTEHHtEBFV0EA8PSaHPz+ZyWMZuerILotAsJCRWxb1465
r2JBsQkvbyzE3r+quHzxVAjUA8ChoDdBALA+InvhmU7o2T0UWsb96Z79pXj4qdN2VglO+kCb1kF4
5bmOiI5StoinotKMTR/n4pPtjnseuAaBuo9+WgHz70/BdYOjFD9u+2VPGV7ZkIObh0djsgwAtn9V
jAX3J+EahXMbRpOE3/dXYOXL2TBe3DmY4S/HSS8IwGXdg/H4vGTF8wM6nQUPPp2Fk+d0F1/B5YHA
0gVJuPoK8lVSeYUZn+wqw5b/K4FEnefzHgjYA8ChoLdBAAA0ooDXXuyCju2DqYPktfWZ+HhbHtXf
mGg/LF7UFpdfquxeUG+w4Ovvi/HC2kwZFxoGAcC6BHrpw6no1T1Y8T15br4RifFkoGXnGJCSpGzL
cEkC0s/rsXBpBkrLSRGd3AMBALjthihMHR+neH4g/bwBT76YjZx8o3WnLcbguvySEPzvsWS7rPJK
M97eUoxdP5bZLU+mm+8dEHAGgENBb4RAXbrj1gRMuSsZoSFau0u6WQuP459/K21FAFivImZOTcEd
o+KhJFksEv75twoPLztd+04C/dm5Uy+4CAFAQFKCP9b+rz1iXFxm3NBUXGLEwmXnkX5eL+Mrh78K
IAAAi2Ym4bqrwxWvNvxlbwVefCsPlVUWWQhEhmvw2bp2MBglnD1vwNLVOSgoNlFt9WYIkAHgUNCb
IVCnceeYREwck4jQUA1GjjuM8or6XytRFHDrTXF4cHYbZluOqaDIiJkLTxAiGTUNBADrRN2rz7VX
9GiuIclslvDYyvPYf6gOoixfOfxVCIHAAA2ee7wVenYJUjw/8N5nRXjvsyJIFvrgapXoh6xcEzXf
8YO3QoAOAMeCHGWU5DcHBADrpJ5tSKZLuofihRWdFF9WAsDUucdx6my1zEuZTQcBQQBGj4zF3GnJ
aMz02sZcbNtVRNjVtukhAAiIjdbizWdTEa1wfsBskbB8TQ5+219FLkB4RMiyVXCDBt1Xik4DISAP
AAchX4FAXRo6KBrLHmun+BdkxYsZ+P7nYvs9DTlO+saGAAAEBopYNKcVhg2KVOYUI33/WxleWHsB
Ov3F2TQXfOXw1wUIiCLQPjUA6/7XVpFPkgS8tCEPu36g7JjcAiDABoCDUPNCQNaCWgl+O958qQt6
dA1lul+XPvuiAOvfzUK1jtJlHgIBCNY3Ep9dnIZunZQ9xnNMGVl6zF+cbhOynWVr80AAsL4VOrh/
GB6bk6TAPwMmP3RO8YpBOVu9CQIiX3xKjnCWHhhZiGVHXCzfjLfJJOGWif9gzRvnrW+iNXegUbKy
3cHSMhNmLjqFDz+jb7/OSh9vL8S9c0857NfAspXlK4eG4hiD1g9Go4Tvfi3HqEmnUFZBDylnm9qk
+NcqeEZ4MVq0YZkKstKsyEKibGWKkC9BgCdptQI+2tAdc6e3qn+a0NwQIBav/6tDWhDeW9sZE25X
9kTDNo27JRabXu2IDmmOMQ08EwKCAIwaHon3X22HiDD+lZP1Ci0PAkK/a36TaJeXrB7z9tuBz97v
JRttiJQKi4x4f0sutu3Ml7ezKW4HCH+GBGvw9KOp6NMrTPHcBi1JEvDviWo8sjzDIVir59wO9OoW
jIX3J6KVwnUMADB03EkHM1rQ7YAVADa1WhAEXAFAXTqTXoNX1mXir8MVHgEBP62AufclY+TwmEZ7
HGg2S/j+1zI8/3o2TLyr/RoZAglxflg8Lxk9urg+1zF03EmCGS0DAjYAsKnVQiDQEADUpYOHK7Bq
TUZt3DqWM+SMhkBAFIHbbozFpIkJiAhT9ijM1VReYcY7H+djx9dFtY9Umx4CoSEazJsSj6EDwxsM
vIsAcDLD9yEg9Bv0m0RsrAVAwB0AAKy/jN/8UIzXN2ajjLQctpEgcGXvcMyYbA2+2RwpI0uPtRtz
sf9QBdNWd0HA30/AhFtjMPqmaISFKHsxipbsAOBkhm9DgDAJyNi5xK6oD00M2iSDwYK9f5Zxl9do
BNw4PAYfbeiOSROTEOD4DoKbJwbTUgPxwrJ2eH5ZO0WDv6bGgiP/VVPzj/xXbR9enZFSWwVg1ZJU
rF7WFmltbCL6NNLE4LUDw7FxdTtMGhunaPAfO1lTG56LM3nQvgNyHeKOiUGRXNBbIcBqhg8CBqOE
RUtO4aEnTyH9XA1XHQAIC9Vg6l1J+GBdN9x0ncMOsW6AQFSEFgtntcLGlzvjit7KXlT6ZEcBbp98
DAf+pm+7duDvStw+5T988kWhAmXrvgcbVrfHotnJiIqo28LdFV8dKtb+2b1zEF57JhWLH0hBSiL/
FVtBkRHPvHIB85acd4qSxExNBQEo17A70kAIaCXYXCWQPtgdowvVXVJQi9uUYWk42i40UMO+iMQV
2EKSrPsU7j9YjltvisP9k1MQEsz3q5MY749HF6TitpFxeH1DNv76p4LgDNlLkgv+/gJGj4zDnXfE
I1zhff7ufWV4dcMF5MrF1rexo7ragrVv5+DTnUWYNy0JV13JBxqNRsBNw6IwZGAE3vukANu+LHIK
ysHjq22ZpAQ/3DsmDiOGKHw12ShhyxfFeO/TwvrwXAy/iX1jc1iCRL4duFiGrVH3gTnmODTsstmn
PVVaK6/fsiEAWN/027YzH9t25mPejNa4fWQ8M95AXercIRgvP9cRv+4pxfpNF6wblyiEwOCBkZg5
OQVJCn75AODkmRqs3XgBh45U2jTHPxjy8g144tkMXNYjBLMmJ6FTe75Z9uAgETPuTcDtN0Vj7Tu5
+GVPuWIIhASLuP3GGNw9JpYrCm/9dwX8vKccz7+Ze3HJMv+jUF+CAI+GNWnZ+r4LAV5f6tIrb2bi
3c05eOSBVFzVL5J79nnQgEhc1S8CW7bnY/MnedaJQgYEunQMxgMzWqFbZ2XBKYuKjXjnw1x88U2x
zYkLlyAACTh0tAr3LTyNkcOjMXlCAndQjvhYPyxb1BonztRgzfoc/HeqhtyGQ1cPHRiBBTMSEcp5
tVVX/+jxaqx6IwdZOUanPBUCdBe1dl8EyyAfgwAzETTKK0x4YvkZtGsbhEXzUrnjA4qigAm3J+CO
kfF47a0s7PquCHpCZOKkBH9MuzsZw4dEKTJVp7dg5zdFePWt7NojgsOJy/dgx6m3a//b+W0xdn5b
jDlTkzByeDR3RKLO7YPwxv/a4YfdZdiwOR85do9L663q1ikIT8xPQbLCK53sXAPWf5CPX/fVzW0Q
zhsW8xQOPl+CgNbxiAoBPo2z52ow88HjuKJPOBbMaoPWKXyz8X5+AhbMao377knG8hfOYU/t04bQ
EA1Gj4rHpAmJ3LcYgPU9hX1/lWPZqoz6N/Vse8oBAgqdd9J47e0cbPggD08+2Br9Lg/jtvXaqyJw
Tf9wvLe1ANt2FV+clEtO8MPcqYnof3mYIsvKKsz4dGcRPthWZD3AOuk5XZXV8EEIaEkGqxDg19h/
sBwTph7F2NsScM/4JERG8F0ih4Zq8L+l7ZF9QY83N2Vj8cK2CFCwIUhdtOHlL2RYNwSRu6VwcUzQ
NHR6C554NgOtkwPw5IOt0bFdEDOIJgBoNQKmjI/H3aPjsGx1Jrp2DMadt8cqskZvkPDT7+V46a1c
+70deAYOp6uyGj4GAS3NYBUCyjQ++TwPn2zPw6MPtMWwwfyXyCnJAVj+WDtFpuQVGLD69Sz88WcZ
YPvz3oQQAIDMC3pMf+g0+l8ejgX3JyMhji80mZ+fgBWPKIu+ZLFY7/NXvnIBeQXOsf+d+0CFAA8E
COsAJOdDtA9c80kUPUoZJfmSGzQUJY61CM+9dA63TPwHfx4qt1kv755UXWPG+k0XMGbyv7WD36EX
6B3MyEeDNP44UI6x9x3HG+/kkjfubGDKKzBizuPpmP/kOfvQayRb6Sctf+LRsOt2Vhm2Rt0H5phT
aKvEyBfJYioEGqJRVWXCgsdP4c7p/yLrgr7BzZrMEn78tQQj7vgH73+SJ98LrAHM5SMjg5L/8Y4C
3DjhGL75qcQt8KvRWbB8dRbGzzjl8BSB4a9iCEiuafgABES6mAqBBmlIErJz9Bg/9SimP/AfDEbl
7UoScOZcDW4a/w+e+l86o3l3QKDhVxMSJDz7cjZG3X0cZzN0LnW32Sxh87ZC3DDxOH7YTQnXpUJA
ka00CGipYi18ToC5WIhnXqG2zH8nq3HtqL8wYmg0Fi9KA0/S6SyYNOc/ZOfY7vhr830Qm3eY8nfp
np9HQ/47lyChusaMKfNPIznRHxtf7sA9J7L3YAWeeiETej3LVw5bFfcBoYInzQlAuYZdttOcACCy
ieLLVwISo4gbriakeqJ//WMxht1yCNt2FshWeXjpaYy442+Hwe/spetXAkyjOTTYVwIAcCHXgBsn
HMOjKzJkWywqNuGOaSfw2MrztYOfx1cOW/m/InoFT7kScFGD7uvFW4AWCgEuU90HAQDQGSx4a1O2
bPE/9pdb37PnOOndDQFJkQYfBCwWYO+BCuz6voTa7uzHzqKwyCT7cosKAdc1aL6KcgVpjakQUGgn
r79MZ8gZ3gABVjddzGP44jIEePxugRAQlTmlQkCpHYrLMJ0hZ3gLBLicVCHQZBAQeQrSGlMhoNDO
htjRVBAgFlchAOoh74aAyFuQ1pgKAYV2NsAXb4cAX1IhwGrHnRAQeQvKNaZCQKGdfI1QjlMrMKq6
fA/QAiBAOW9aAARE3oKsxnwPAvJlfBcCrP7zUgi46rePQ0DkLchjsE9BQGIb7J0Q4GqU2IYKAUoZ
L4aAyDaGR8xLIcC0g11DhQCHHQ264GoCCHCc614FAQV2iHzG8Ij5MgRYzbgXApISDRUC7vHVlyCg
wA6x/rMKAaUa9kVUCHANYJcc52lHhYArEBCVGcNjkPdAQFFqCRBw+Ve84RCQlGqoEHALBJwWAqkQ
aJiGeyDA8FcxBJT4yMigjkEVAko1PAECxIVAKgQaptFgCDhMPjYcAjwDi6Vhk9EgCLCabgYISKxj
vgsB6kIgFQIN01CyFyFZwN5Lt0KAu32ZDBUCBG3vg4DsQqCWCgFBALSsfSm8FAJambidfn4C+Aaw
90GAKyZsC4SAyDK4JUIgJFiDHR/3Rru2QS5r1BfxDAgEBoq4844E3D02kdrUXWMSMHNyCkJC2G+J
0yBAtLQZIRATpcGbq9IQHysXsZjRjg9DgB4QpCVAQCZFRvrh/bcuwfpXuiNMbkPOpoIAy19KG6IA
XNE7HDs/vAQzJqcwm5owOh5ffNAT118bBa3WJtwW1TDnfCoE5LqBevq4BoGgQBEPz0nBZ293RpcO
PPsatkwIyKPexyHAMy67dw3F19v6YMaU1rWXyK4JNRgCPP46tBET7YetG3vgxeX8MfkA663A4wtS
8dUnPZHaOtB6+ewOCDD9ox3mh4BGI2DU8Ch8ubkrbhwaqaRL0RIhwA4I4sMQ+H1vCUcD1nT3hGT8
/OUVuHoAZc8+D4KAIACrV3TE9g96Ij5e2V57tinAX8T7b3TBjg96NAEE5L9zHgh0SAvED592w4Mz
krn3gdl7sMLhSMuCAF9AEB+FwEuvncOpM1UcDdSn55Z1wk+7+qJ1K8JegM0MAUEAJt+ZjJ++uAx9
eyvba08uRUZo8fMXl2L1ivYyewE2HwSSE/2xfVMXbFjdXpFfBqOEl97KpdtBs8WHIMAfEMQHIWCx
SJgy8wgeXXKCo4H65O8v4qONvbB5wyUIDXV4XNBUi4Uc/O3fNwJff3YpptyVxL1tuZIkCMDll4bh
m08vwfzpKZS9AFkQ4En8EAgN1uDN59vjg7WdEBnOtydjXXpvawFuvus48guMlBItAwLKAoL4IATM
Zgm//l6MoTftw4ZNmRyNWJMgAG1Tg/B/W/tg2eMd6ifMLrbR0Ik/PgikJAXgow3dserpDggOYj27
rE/FpSbc/+AJ3DXjGHLzDdz1/LQCRo+Mw66PeuL2m0kbezY+BDRa4MmFrfH5pi7o0oFvY9K69Puf
Fbjl3uPY+FH+xc1a3BNt2DshIFwx4CfnGgL1g9MhgbbzAkvD7rDAUU2QlbLXpehRytimuDh/LJid
hsFXR3M0VJ8qq8x494MsfPSpzSWlQLYgNESDbz7vQ9W6asSfTDvDQjV4YmEa+l8RofgXf8UL5/DT
byUw1G7fpdUK6H1JGJY+koawUH6IAEBBoRErVmfg0JFKxw52+vPhOa1x83Byv46d9l/9pp+OFW2k
7hkTj3G3xiI0RJmdp87q8PRLmci6YHAYlDbnCv1kIf5JPkYWERSMKfZ44Rl3fOOSDAAeg3wYAoIA
dOoQgqce74i2bXgeIdWnnDw9/vdSOv48WGbTuH07igDgYKdWK2DKXckYPTIeIQoHwcfb8vH+llyU
V5iI/RsUJOLaQVF4ZF6qIl0AOHm6GstXn0dGpg5EcQFYNKc1RioCQL3G0KsjMWtyIuJi+HYgrktl
5WY89XwmjhyrhtkiUU6GlgsB4YoBP0q8tGiYMTwGeQ4EAOsjpb59IrHyqU4IDOS/zrRYgCPHKrBq
TTrOZdQ4QUAxAGrtvG5INGZOaYX4OGUz+3/8WYZX12chM1tPLuDQBRHhWtx5RwImjE5Q1I7JJGH3
vjKsWZeF4hKTk/iiuTIAmPof8gqdAXBJtxDMm56MjmnKQAwAT6/OxO/7KqA3WMAcwC0UArUA4Gus
JUIAAPwDNBh9SyLmTG/D/XgJAAwGC37+rRgvvnoOldXmixYoBUDXziF4YFYbdO8Syt84gPwCA1au
PodDhytgqbvOE3i+I2tq0yoQMyen4Kp+EYraraoy4/++K8L693JgNEoXxZkAKDBetCMhzh8LZ6bg
8ktDFd3iSBLw7sf52LqzkLBVuQoBx3wbAPA11lIhAEFAfJw/Zk9PxbDBMYpAUF5hwifbcvHO5mwA
AjcAYmP8MGd6Gwy+Ksp+kpEjrXolA7u+LYLFTLjDUwABQRDQq3soFs1tjTakR58yqbjEiPe35uOz
nQUABC4A+PsLmD89BSOGRNEXXhGSJAE/7S7DWx/kISfPIPdFy/rb0iAg9O3/o8Q0hsegFgIBAOjZ
PQzzZ6WiW2dlv8iZ2Tq8sSETB/8ulwXAkJsP4N4JyRhzWwJCgvnv8y0WCZ98no93Nl9Alc2vH/k8
54dAXcZ1g6Pw4MzWzo8+GenceR3eeOcCruofKQOAYxgxNBrjbo1TPMH336kavPb2BRw9Xm1/7qkQ
cCjjnC/07W+9AlAh4FyGlX/t4BjMm5GKuFhl9+RHjlWiZzc6PPIKDEhQeJ+/e28p3ng7y34SjuWv
CxDw8xMw9pZ4TJ6YiAAFy4sBoKLSTH3KUFZhQkSYsmf52TkGvLEpB7/tLXewUoUAvYx9/kUAcBnD
Y1ALg4BWK+DOscm4Z2IyggKV/XK5I53P0mHtW1n4fV+pa/66AAHAujpw8oRE3HJjbKMsPJJLFZVm
vPNRHnZ8XQyTmfYQS4UAvYyNX7YA4DKGx6AWBgEAiI7yw6xpbXD9dbEQxcYfEOUVJry/JQcffZrH
sNPeS3dCAADapwXh/nuT0b9veKP7rNdbsPPbYrz1QS50OpuZfar5KgToZax/OAGAyxgeg1ogBACg
Q7tgLJybhl493bcW3zYZDBZ89V0R1r6VieoaM6edMv3H8pXjpK9bJjx7agrau/C4jpUsFgm/7S3H
6+9cQG4+e7GQfa4KAXoZAULf/j8Q1wGoECB2BHf+kKujMfO+NmiVrGzmXC4dOFSOF17NQFa2zjmz
ySEgEKuOuj4G945PQlyssgU7tHTsRDXWrMvGidM17AGsQoBghnyZWgCQxVQIEDuCO1+rEXDX+GSM
uz0JERHKJrhs0/ksHV5+PQP7DpbLF/QQCGi1AiZPTMKtN8YqXlpcl7JzDXh94wXs3lsG9uC0yVAh
QDCDXsYGAGQxFQLEjuDOFwCEhGiwYE5bDBscq+jZdnm5Ce99fMHu/QLBRTvsHWx8CABARJgW82e0
wuCBkdzrGMrKTfjo8wJs2V7gsIZBhYBSDZ5xJ/Tt94PEElMhQOwI7vy6o4kJAVixpBO6dg6RlTGZ
JPz2RwmW/+9s7TJWx2a8BwKAdUHTysXt0KVjMNUko0nCj7+V4vnXMi++pUdQZthhk6FCgGAGYWz3
7Vd7BaBCoEkgAAB9ekfgqUc7ICba+T75THo1Fjx6AkUltPfU65rxNAg46BHKdO4YgpVPpNnND1gs
Es6k12DWI6eh19Nn9omWqhBoMATqAcAhpkKA2BHc+bZHBVHA+DuScNe4JGi1IvR6Cx5efALHT1Xx
tQE3QIDHXzdDABDQp1coRt0QC0mS8Po7OcgvMJA1VAgQtN0LAXsAcIipECB2BHe+4AYN+yLeCQFu
DRUCBG33QUB2YxBSdBFmhBMODV+MLMSbL7lBw76I+8OLcWtwRNIhV+WP8ssdclyBRn2uAg165zC7
yZMjC4msgioEVAjIaqgQ4PCVQ6OZICC6OoBVCBA7gjtfhYBDRRUCzQIBkbegCgFPgIDEKKJCQIUA
n0bdYfYmcCoEPAgCbINVCCjXqM9teRBg7wzEIaZCgNgR3PkqBBwq8gxgYnEVAko1+HYG4iijQoDY
Edz5KgQcKiocOCoEKGUY+fw7A3GUUSFA7AjufBUCDhVVCDQ6BETmoFBokAoBYkdw56sQcKioQqBR
ISDK66sQoCYVAnwaTQUBsjK/RguFwMV1ACoEHD6oEFDub3NPDJKVXdaoz/VdCNitA1Ah4PDB6yDA
akaFgAoB+0NO6wBUCDh88CoIsI1VIaBcoz7X9yBAXAegQsDhgwoBWas8HQLu1PA1CFDXAagQcPig
QoBgkHdAQHKDhn2u70BAdh2ACgGHDyoECAa5GwKsqioEaNmuQEBkiakQcPigQoBgkDshIHFUVSFA
7zJlEOAKCKJCwOGDCgGCQSoEvBEC3AFBVAg4fFAhQDDIeyAgNVDDPtd7ISBSDVYhoEJAsZ0c/iqG
AGtguD6AVQgQnwLIi6kQcPigQkC5vx7ydMBB2WWN+lzvg4DIPJFVCKgQcEFDhQCfL80NAfuQYCoE
HA6rEGiIhgoBPl+aEwLOIcFUCDgcViHQEA0VAny+NBcERGJpFQIOh30ZAhKjiAoBX4aASC2tQsDh
sK9CgG2wCgHlGvW5ng0BUVZMhYDDYRUCSu1Q5G9zQ4BY3LchIAIMMRUCDodVCCi1Q5G/zR1ZqIVB
QJT/zlUIkMuoEFBqhyJ/VQjIdQ6zm5RAQH4hEIdTKgRUCCi2k8dfFQJyncPsJl4IiBLXSapCgFxG
hYBSOxT529wQICvza3gBBEQ7g1QIuGCrCgGldijyt7knBsnKLmvU53oGBMT6PBUCijTsDqsQUGqH
In9VCMh1DrOb5DT+H9VJKC+jrZ8aAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA1LTEzVDIzOjQ1
OjMwKzAwOjAwIdsu/wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wNS0xM1QyMzo0NTozMCswMDow
MFCGlkMAAAAASUVORK5CYII=" />
  </svg>
);

IconCDK.propTypes = {
  customClass: PropTypes.string,
};

export default IconCDK;
