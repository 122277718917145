import React from 'react';
import PropTypes from 'prop-types';

const IconWebpack = ({ customClass }) => (
  <svg className={customClass} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="256px" height="256px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
    <title>Webpack Icon</title>
    <image id="image0" width="256" height="256" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAy
  3UlEQVR42u3dfWBT5dk/8O91TkrC62Q8K/6ak9JJFVFRJ0x5FJoEUDcnzk3Fbb7OKfMNEWmbnBYx
  Im2SUsCBzg2n892Jc79NnM+YQpKWOXCgUxzKm5Ym4RH8KYq8pDQ51++PFkRFac9JcvJyf/7Ucue6
  T3uunJfrvi9AEARBEITiQ2YHIGSeyxeybO2gG5ioT6zR+auv+1mHGqrWgOSxFQPvX/fLMZ1mxy5k
  lmR2AEJmOTwrz9/SQa8z49cEfOuo/4CkY4hp4Y739rxV7glPNjt+IbMsZgcgZMaw2sjIlMTzGfg+
  WNcQJ2iE5x1q+CVN02riwQlvmD0nIf1EAigwdnXNEELi7hTzL5GG3y8zziWS1ilq+GFLkme3zXO/
  b/YchfQRCaBAVE570doxoN8tjP13gnFMmoeXwbghZZEut3vDgRIbL2zzuRNmz1kwTjwDKAAONXRx
  on+/9cyYn4GT/xBmHkRAYyqBt+11kSkAi4fIeU4kgDxmrwudrqjhlcz0fwEcn63PZVAFafyM4o1E
  lNoVZ5p9HAT9xC1AHqqoCR2blKkBGq6FuUl8PCR5teINP6nJVLe9wRk1+9gIvSMSQB4p8y3rJycG
  zUgSe8EYYHY83QjAlVKKf6yo4ebOzo6mHc3n7zU7KKFnxC1APmAmhzf8UykxYAOD5+bQyX+4fmDM
  LimxbrSrkWvg84m/rTwgfkk5rtwTGqeokX8w8BRAw8yO56gYdmJ+ROlwrSn3hMaZHY7w9cQtQI6q
  8IYqUpACGngK8rFkmzFGI2pR1PBzEsue9sD4d80OSfgykQByzIjaVQP3SUk1CcwA2GZ2PAYRGJdq
  SE1WvOFFfbiz4d3guZ+YHZTwGXELkCsuWyornvAN+6TkJgZUAPl+8h/OCqDmAPXZ5PCGbnT5QuKL
  J0eIBJADymvD5yrDS18DYQkDx5odT+ZwKYMe2JKg18q9oUlmRyOIWwBT2dXWEyRo8zTmi8yOJctG
  aaCXFG/orxZCdZvf/Y7ZARUrkQBMUO5tHcyszQa0m5m5j9nxmId+kGKcp6gtv0kC97zvr/rA7IiK
  jbgFyKLRU9eWKJ7wNA2pzUx8e3Gf/F0YKAFr0yysbXR4IzNP8i0t+mOSTSIBZIldDV2445t73gRh
  EYAhZseTgwYzuHl3R+kGRY382OxgioW4BcgwxRMZBUlbCKaJZseSFxjDAX5O8YbDsiTN3NZY9ZrZ
  IRUykQAyZGj18tI+JbY5zNr1YJLNjicPuVKa9qrdE35MYsusaNO47WYHVIhEAkizykWbrYn/jU8n
  Rj0zD8rHIr4cIhPh50zJSx2eUJPcF81iI5L0Es8A0obJ7g1fltge3wBGkIFBZkdUQAYy0T3JBG1S
  6iI/ExuRpI9IAGngqA+NcagtrQQsBXCc2fEUMAc0flLxRl4Z5llxttnBFAJxC2CAUr/CTim5kVN0
  JcAimWbP2BTJq+xq+Jk+Uon3vYZztpkdUL4SCUCHodXL+5dY+lQjRTUM9IfOfbcFQ4gYP+nUkhc5
  vJGFCZsW+MDn3mN2UPlGfGv1ho8luxq5psRifQcgH4D+ZodU9Jj7MbjelqDNihr6BXziSqw3xMHq
  IYcnUmVPtLxKzI8AUMyOR/g8Bo4F0++Ujsg6e23YbXY8+ULcAhyFo2bFcJbkJib+sXj0nAcYp5OE
  lYoa/jNkqo3NdW42O6RcJhLAV6jwhY5Jdkj1zHwbAFGfnm8YFyPJ37d7wveXdPA9bfe6PzY7pFwk
  bgG+wOULWRxq+KZkgjaBuRri5M9nViLckbTRFocncovYiOTLRAI4zOGddNGTTrpCvhjCxPdtTdCb
  irryArODySUiI6K7k66MZma+QLzRK1wMjARLf3WokeUgrTra6H7L7JjMVtQJ4HOddLm4j0UxYebz
  wTTRoYYf7IR0VzFvRFKUtwCV0160OtTwHYT9W8B8C4o8ERYpCzNusmjaJocaqj7J95+ifNZTdAkg
  W510hTxBOIaZ5u3u+GCD4l15idnhZFvRfPPZ60KnE9MCZogiEeHLGMMB6Y+KGolInJrZHpiwzuyQ
  sqHgE0AOddIV8gGzU4P0qkMNP4pU4W9EUrAJIEc76Qr5QWLGzyElL3WokSZOlMyPLTx7v9lBZWSi
  ZgeQdvnRSVfIDwOZ+R7YDmxUvJErwIW3EUlBJQB7fWisorasyptOukJ+YDgAfkKpa/nnME+koDYi
  KYhbgLL6iEPSOIAUfiq2ixIyhvmsFGGVXQ0/U8KstgXcbWaHZFReJ4ARtasG7pE6PZTimSisZppC
  7iJi/CRJdLGihhfZrLaGLb6xu80OSq/8vAVYulS2e1dO3SclNxGoHuLk74n9SNHRS19T2lsACvKB
  V1oxbGDUJhIdmxU1krcbkeTd5XK5t3WShlQzgNPMjiU/kAbwUxbwnT29ZFXqV9iRkucA4tVpL6xn
  0O3xgHOl2YH0Rt4kgIOddLn4OunqRsDLBM2rt6hF8URGgTgI4PtmzyWPvGAhrsmXjsc5nwAOddKV
  UOSddHtlPRPXxf3uF9IxWHld+NyURn4CjzZ7YvmAgE4GPQBbyd0x39kfmR3PUWLNTaOnri3ZMXjP
  jSDcBdFMs2cIcQB3x77jfBhTKJXWsS9bKtsrv3WlxORjoMLsqeaJXUw859iKgfev++WYTrODOZKc
  TAB2NXQhMc0DcKLZseSJT0Fo7uzsmL+j+fy9mfwgZcYrfWHrmA6GF6BvmD3xPLFRYtS0B13LzA7k
  i3IqASieyCiSeAEzJpkdSz4goBOgh+SUdnfbPPf72fxsu7pmCPH+2QDdCIhbs54g0N9ZoppYY9Wb
  ZsfyWUw54HOddCE66fYEAc/LxB6zHzY5Zq0YjqTsZ+BS5MjfU45LgfCwJcmzs520j8TUX1jlos3W
  RDw+nQh1DIjLyZ75l8R8R3vQvcrQKEuXysrrpdcRo2S4jZeEfe6kkeEc3vB3AV7IoHPMPkD5gIh2
  M+C3WLV7zex4bFICYLKrrZcQa0GIZpo9QoT3NI288WDVswAZ2rnQ7mn5AUlaAIxTuv/TRmhaXaxp
  wp+MRcnk8IQuYpICEM9veoTAbRpLnnT8XvV9fpY56kNjoEn3MrP4puiZXRLxnAHW0l9v8J18wMhA
  jvrQGE5REMCEI/8EtcgMdVvQ+YqRzxk9dW3J+9/89BcS6C4GjjXtyOWXVgJmRgOuf2XzQ7OWAA51
  0oXopNtDCQCLLQluNNrUosL7z4okdcwB0xU9OPZMhGdZpllGu+pUTntxUGJAv5lgzIToo9gDXVWb
  2gGq277AGc3KJ2b6A8p8a/tJHXuqwaiF+CPogd6X7n6Vcm/rYI3YC9ZuQ+/XS3QQ0QOdoEaju+Yq
  9Svs0OS7wHydeMjbI/u6X+s2Zfq1buYSgM8n2TtcVxCjEaKZZo8Q4SViTTW6H13ltBetBwb0vUlj
  mgXjRVS7QAhYrLzI6MMqxRMZBYkbwbgw3ceuIBHiDK6PWyOPw+fTMvMRGeDwRKqYuBnAdzN6gApH
  ekp3malMDU+RQI1I/8PVKBPq49bwk0b/GMu9oUkpSAFRWtxDhLUSpNvb/VX/SP/QaTSsOvJtzcJB
  8U64h9JYutuVdOEHOLM71hDWMpPH8Ko3n09S9k/4GZF2jygt7hEG4TmJZU97YPy76Ro0bSfpSb6l
  fXYnSh8B8FMzjk4+IaLdYDSlbLsXbvdN3mdkrLLalSMkSfID+FGWZ/FXkjSv0fZayoxX+sLaeRu6
  Nm89JrtzyD8EPFv60YAr1i1Jz9qCtH9LK96QC6BfATg12wcn16WzdHdo9fLSkhLbbDD/Eubt7JQC
  8HvSLHcZ3T774KpPJr4ZoiPzlxDwNknStPbGqhVpHjcDLlsq2yuHXk/M90B02QWQvtLdMt/afnJi
  7wwGe4Gc2fF4LwjzO6w87wOfe4+RgYZVR76tlXCAGZdB3EYCwC5IuKuyDz9gtFrzSDJ6gA+t5Se+
  hYGSTH5WDvuXRNIMww9wLlsqOypLr2bGXABlZk/qK+xk4tnHW/FQekqLsYCBcWZPyiRJAi3RqHN2
  3D/pw0x9SFYy7LDayMiUxAsAfC8bn5cL0lm66/CsPJ9JagYOle7murQtf3WokYsY3HBY2XLBI8JL
  TFJ1NlYNZvUSq2udP5oBGpHNz82yXUw85xvWD369wTfFUOluVz9DqRnME82elC5EEaSoNtZU9aqR
  YUZPXVvy/pC91xHzbOTu1U86vAuimpjfaXBNRs9l/R6rctqL1o7+fW8F6M4CWwGYttLdsvqIQ0px
  A9Cj0t1cxwRaSpDqjL6+GlG7auBeS2omNK0aoEKqKv2UAX+JjRdme2WgaQ9ZhlYvLy2xWBsAXIe8
  3nk2zaW70DwA3wagr9kzS7MOYnpAkzrnGr2nragJHZuUaA4IP0de97YgjQiPIyXXmdWEVHcCUDzh
  aZCxJdbo+h8jAXQ97KGFjPxbHUiElzRNrokHx79hZJzRv11bsuPdPTeCcCcK/63JLmJutO7bv3jL
  4gs6jAyU36XFvFqC5db2wHhDZd8O74rzmOURsaBrsZ5/rz8BqJH7wHwLwH+1EKoNvd5iJkd96+XM
  WlNXH7acl6Zdd5nsteFLSKJGAMebPalsInAbS1J9rKHqaZCxh6SKN+RioJlAuV9aTIhDY28s6HrS
  yMNhR82K4WyRm8D4MYjuj/mdt+oLR6fPEgAA4AAz7rOic867wXM/0TtmmW9tP+nA3lpoXItcvAQ+
  VLq782FMmWKsdFcNj2dGAEBBNZvsNcJaJtTGG10hQ+P4fJLjwIQp0DR/jpYW7yfQggPJhN/ICr8R
  tasG7pOSKhNmgLtXeOZAAjg43E4Qz4pZnQ/BR7oXjJTVRxxyCvMYPMVIjOlCRLtZ4/mdKeO77trV
  1hMIqQYwLsmFueUKIjwP4nqjpcWVizZbO/43fgtruBOUE6XFDObnJEp52gOT9D8E9fmk8g73VRpz
  I774JiR3EsChOb8hMW41um+dwxOpgsQLmXGGkXH0ykDpbj0x31TERVFHkySih5CS56SjtDgFrZ7A
  02BaaTG/AVhujwXGh42MYq9fNZZSyUX4qtW1uZcAumZPRM9aJEvtew3nbNM9+8uWykpl6XXEfA+D
  huoep3eYgGXpKd1d1o8SA6dLgKfAXntm0qcAze+wafONlhZ374bUAMZPkb0rrg8lovr27+z4nZFb
  xe4ejQEAV3xt7DmaAA4Gtw9gw7ubVPhCx3QmpFkSYVqGW4SlrXTXXjn0SgLfkycPNnMOAe8zYXal
  lX9vuLS4PjSGNWoCw53BeDvBdH8J+vveDY7R/SxMmfFKX7J23sFgFT3ZRSunE8BnnxQFsxoLuJ4y
  8vTTrraeQKwtAPgHesc4YnjpLN31Rs5jcBNEB+O0IOBtjbg2Hb0OM1ZaTPibBTzD6BWjXQ1dSiwF
  Ae75hi55kQA++8BWpGH3U8Ub+h5AC2F8++m0le4qdS+fSpplPkN0NsqQsAS52ui7c5cvZNncIf0i
  TaXFm0A0I+Z3vmhkELun9TSSUgt1XaHkUwLopjHj0RKN64w8XOuqEf/0FmL4dPSpS1vprlK/wk6a
  pYGZr0JeVzXmA9JA/IyFuc5o5eWI2lUD90qdM0E0E9zLpdWMj4lobum3+y8y0vjTrq4ZInFiLoNv
  AKBvw9Q8TADdcdNu1rhhUN+d9xr59rWra4ZISDQw8/U46kFMX+lupW/1oP2J/bUEugO5WLdQyAgJ
  MBZbbNzY5jOWwIdW/6O0T0lyDjP/AkcvLU5La69DX16guwzvhJSvCeCwKLYStDui/gnPGxnGXhM6
  nWRaCMB15OOUnl13R09dW7Lzm3tuYNBdAJem5RgIeu2SSJozwDrEcOOUstqVI2RJmsfA5CP+AFFE
  JrpjW2PVa0Y+x1HXcj5r2r1IV/ekvE8An01kuaTJM9uD4/6jfxAmuzdyKYEChz1IWc8sqfFg1V+N
  BcjkUMM/5K6HNCekde6CIUR4TyPyxhuNP8RV1JVOQG4C85nd/ynKElUbHTtTD7ALJwHgYPEN7pNt
  PMfIpV2Zb1k/af/AaiZuj9tcjxmpTAQAe21orCRLzaKlWc5LT/NUn09SEu6fguAw2sCzwhc6JpWg
  2QBuzUgRWCElgMN8SMx3RUe7fmN0y2wjRPvr/JQT7dMvWyorx5VeR4Q5Ge2RaCAB5PIT6yFMdJ/y
  WuR1e104Y8UbX8WurhmieCO/QsqygSE2qMw3DFyUYnrT4Y08UFETynqDUocnUuU4rvRVEJbkcoPU
  fNhMYRRpWKmo4T+muynCkZT5lvWTOgbcBt7nBegbnPWGzUK6dF1u841Jma5QPOHmdCzmOpqy+ohD
  0jjAzNksPdYtl68APo9xqYbUWw5vZO7Q6jfSvx2UjyW7GrpGTgz8D5j8OuoKhNw1EIS7S0qsGxU1
  fAOWLk17g9Iy37J+iic8W0rxO2D8DHlw8gP5lAC69GVwfUnJro12NXwVmNNykMvrwufaEy2vEtMj
  ObqWXEgHhh2MJcprpa/bPS1pehLPVF4XmSIlBmwA4W4A/cyeZm/kWwLowrAT4zFFjbyi1K440/Bw
  KUkiaL1tny3kLytgvMeGvS50usMbbtU0fgagYWZPSo/8TACfGQtJXu3whh911K7SXdMdDVYtH/rR
  wO+AMB2Ej8yelJAhhI+JuWaQbeeoeHCC7pqQodXLSxVv6EHSaB2D8vq1cL4nAKCrMuNqlpLvOLwr
  b9Y7yLolYzpjftciRt8TQHQ/0vEVIeSKFAgPWpI8Mhp0NxspO3d4Qzf2sVg3AXQ9CuD8yfsJHGYg
  k3Sl0UHi/rM+jPmdt4LpDADGWmAL5iOKyJJ0Zszvmmp0VycAYKKrC2ljl3x4DWiKWNC5HsBEhxq6
  mJmaUGS79uY7Ato0iTzpKA0uZIV0BZARUb/7z7a9+0aB4CHCbrPjEY5qDzHdKdt4ZLzRuVSc/F9P
  XAH0QHcDi6aKmtBjSYs0F4yfF0DLrkLDAJ7UDlDd9gXOqNnB5Iui+yM+ybdU936CbfPc78f8zutJ
  084CeLXZcxEO4tUy4ZxYwHWVkZPfyN9Gviq6BPBJYug4hxr+u1LXcqreMaJN7rUxv+tskqSfgiC+
  bUzStWkoXRuzRc7Z5nf9U+84w2ojIxVv+MVPEkPHmT2nbCvKWwBmnAvW1jm8kSUadc7W1aySiKPA
  H8p8a5/P6W5GhYiQgEb3JvpqDR/4XLq3DS/3tg5m1mZrxLega5lus9lTy7aiuwI4jIXBNxNbNivq
  yttcvpCuZLjdN2ZfrNHp02QaAcJT6LoXFTKF8EeJ5ZNjQaequ2fA0qWyXY38UkNqIxPfXsyNWoo5
  ARw0GCz9amuC3uzaaVif7Q3OaMzvuoIITgCGdjwWjugNljAh5nddZmRFaLk3NEl5rfQ1Yv4NCr8T
  81GJBNCNgZEA/Y/iDS+rUEO692qL+l2tMZtzLBGuI8Bw4YmADwl8U+yMnaONNBB11KwYrnjCz2mg
  lwDofv5TaIryGcBRXJhkOtfujdxn5QP36Op27CMtCvy+ctqLzyX69asH4XaY1p8uP6Wry84Ru+kK
  h4grgCOzEnjmAeqzSVHDN8Cn753/lsUX7I4FXR5KpU4iwNCOx0XmbzLxqdGgc4buk9/nk8rVyDV7
  peQ7DKji5D8ykQC+FpeCsURJhF9TvK0uvaNE503cGg24fihJOA+EN82eVQ57BxIuiAVc3zeyl59D
  DY9XEq7VGvMjMN75p6CJBNAjdBqQWqmo4WfLvS/3vGfbF7Q3ul6qtPJoAm4B0PtXj4Vrl0TSjKHf
  HnBqrNH1P3oHKauPOBQ1/CQzIviqVtrC54hnAD1HXduSWX7g8EYWHEge49/RfFqv95fr7nL763Jv
  69Mape4G4yYU7+8hRUS/6wTd+b6/6oN2nYMc3AIeKa5FT7rpCoeIK4DeO2xbstA18Pl0HcP2wPhd
  Mb/rNlmjUwl42exJmSAMSToj6nfe+L6/6gNdIzCTo67lJ4dtxyVO/l4SCUAvhp2YHlES7leGqeH/
  1jvMtibn29GA61yNcRGAzWZPK9MI3AZIl8YCLnessUr38xBHbWiMQ21pZU17Ol+348oFIgEYxmel
  GP9QvOHHy+6IOPSOsj3oWmbbu28UgasJ0P3aK4d9KgF1sg0jY4Gq5/QOUlETOlZRI79jSVrDEF2a
  jCrWe890IwBXSn34YocnEpT7as16Wkl1LzueP7R6+eMllj4NAF2HvE/SpBH4CWgWtb1p3Ha9o1RO
  e9GaGNBvegqoB/Mgs2dVKPL8jyvnDGDie1IJetteF5kC6Nu2fEfz+TtjAfcNLPFoAoz1uDPXagKP
  jQZc10QNnPwONXRxon+/9WAEmSFO/jQSVwAZwEAFafyMorbcLFOL7nbS8Ub3vwGuKq9ruYw1DuZN
  zwJCHBp7YwHXkyD9O/IonsgoEN/LjAlmT6lQiSuATGJ2pjTtVUUNL9Hfn464vdG5NGX79GQw7gKQ
  0dZWBu0ncENnZ8eIWND9hN6T366uGaKokftA/BogTv5MElcAmSeDcUPSQpc51FDDQOsHi/RsS73d
  N3kfgDlK/YqHkJIDAK5A7rSfYgL+KINr2wLuNr2DjJ66tmTHkD03AYm7wPxNsydVDMQVQLYwjmGm
  ebs7Sjc41JUX6R0m1jAxHgu4rmLZcjZyYdkx4d8Sc1U04Jpi5OR31LWcv+Obe94E41fi5M8ekQCy
  jTGcWfqLQ438rdyz6mS9w8Qbxq2O2cJjmekaALofsOlHO8GYGrM6R7cH3bofVNrV1hMUb+QF1rS/
  AdC9DFvQRyQAkzDz+UzJ1xU1vEjxvaLvG8/n0+JB52P9NcuJBPgB9PrVow4dDJrfhw+cEAu6HoSP
  ND2DVPhCx9i9kWYJ2nqA09SoU+gtkQBMxEAJGNOQOLBJ8YZu1bst2camcZ9GA646SqVOAeNPGQz5
  BY349HjAWa1rnwQAuGyprKjhG1IJvEPgmcws9kkwkUgAuWEIQIu3JujN8rqWiXoHic6buDUWdF0C
  yG4gfcuOCXhbAp8bC7gmbzewTFdRW5zK8NLXwFjCoKFpPoaCDiIB5BAGRmqa9rLDG35emRXR3Yos
  Fhgfjp2x8wwmuhGAvoU2XXaBtOnDbXxqe8Cte8FShfefFYo39EewFobYjiuniNeAOYiByUjyeYo3
  vMhms83d4hvb+5ZkU6ak4sBvy72tS5m12SC+pRe73yYJdGjL9JjOefxX7aqBVqnTk0THTIDEjjw5
  SFwB5C4rgJpEomOz4m25Xu+2ZO2B8buiQecMSaPTABx1sw0ivARJGh0NOG/R1S8BAJjJ7olcbZOS
  7xCoHhDbceUqkQByHpcC2oNKR2Rdudqie/Xbtibn27GA6wImngzgy/fxhK0guiTqd51nZJmuvTY0
  VlEja4j4UYjtuHKeSAD5gnG6xlqrQ408U+ENVegdJu53vzDI9q3TiDAT4E8AfAoi1fZ/7CfH/E7d
  bxCU+hV2xRt+nCR6BWI7rrwhngHkF2LmKUnQhYoabu7s7Gja0Xx+r9cGbPCdfADAgm/XvfwkALzX
  OGmH3oDKfMv6yYmBMzkFL4B+Zh8goXfEFUB+6gfG7JIS60bFE7oSrG/Z8XuNk3boP/mZyusiU+TE
  wP8wMAfi5M9LIgHkM4YdRI8rauQVhzectctue13odIc30qJp/EzeLFEWjkjcAhSGsQxa7fCGn4Bm
  UY1svvF1hlYvLy2x9GmARtex+PIoCCIBFAyWGLgaUvJHDk+oSe4LXduSHUnltBetif79biOgnoFv
  mD1TIX1EFi88A5nonlQCb9trQ5caHazME56c6N9vPYAmcfIXHnEFUKAYVEESnlXUUIQ1nh4PTnij
  N/9+WG1kZErWFoOhe22CkPtEAih0TE4iWqd4ww8lSZp1tCYc5d7WwRql7k4x3wQm8fdR4MQtQHGQ
  AUwtIZ5+tB9kSZsJxjSIL4eiIBKAIBQxkQAEoYiJBCAIRUwkAEEoYiIBCEIREwlAEIqYSACCUMRE
  AhCEIiYSgCAUMZEABKGIiQQgCEVMJABBKGIiAQhCERMJQBCKmEgAglDERAIQhCImEoAgFDGRAASh
  iIkEIAhFTCQAQShiIgEIQhETCUAQiphIAIJQxEQCEIQiJhKAIBQxkQAEoYiJBCAIRUwkAEEoYiIB
  CEIREwlAEIqYSACCUMSKLgGQreTfIDwIcMrsWIScwcRYmiJab3Yg2WYxO4Bsi/nO/gjAVMUTWQzi
  RgAXmh2TYKpWmcm7Leh8xexAzFB0CeCgWNC5HsDkcm9oUgoIEGi02TEJWbURmlYXa5rwJ7MDMVPR
  3QJ8UXvA/XLcFjkTLF1FQJvZ8QiZRjuJ+dZKG59S7Cc/UFgJYB+AP+j6lz6fFgtWPSHbeCSAWhA+
  NnsyQtrtI1BDQpMro0H3/WGfO6lrFJaeAmGP2ZNJl0JIAAzgae0AnRjzuxYZGajN507EAq55EsvH
  EdO9AA6YPTnBsBQzfk+a5fhowDnr/zWN+9TIYLFA1X2WJB9P4EcB0syenFH5/QyAsFbWaHq6H+C0
  B8bvAjBjWHVkkVbCAWZcBoDMnq7QS8TLiVAda3S/lc5h2+a53wdwraM2dB9L0q8APtvsqeqVl1cA
  BLwvEV0bs4bPyuTT223NzveiftflBJxFwCqz5y30EOPfDJoY87u/F03zyX+4aJN7bcxfNY6AnwGI
  mT1tPfItAXSAKJCw8fHtfuej8PmycgkWDbj+FQ24xhPRDwG8Y/ZBEL5SlAlXx/qGR8cDzpVZ+UQi
  jgZcT2u2ASNAmANgv9kHoTfyJwEQ/ixBPinmd6of+NymPISJ+p3PD/1owKkMvomA980+JMIhu0Dw
  WGx8QtzvejxbXwyH2+4bsy/md92lyTSCGEvR9Wwq5+XDM4D1DLo97jeW0R21oTGQpHuZ+H8hU11s
  rnOznnHWLRnTCeA3I2pXPblXTlaDMRNAf7MPUpHqIKYHNKlzbtw/6UMjAymeyCgQB4lIIk2e2R4c
  9x8942xvcEYBXO7wRO4HeCETzjD7IH2dXL4C+JCYbq208RlGLucqakLHKmrkdyxJaxh8DhiXUpL/
  o6jhRceqLd/SO+7GpnGfxvyuuyCnRojS4qxjAj0jQT4pGnTOMHLyK/Ur7IoaXgLi1wF8n5nPZ0q+
  rqjhRYrvlW/qHTcadLZE3915JhhTc/lqMeeuAAjoZMJvYO3ji/rO/iiqc5zKaS9aE/36TU9JqAfz
  oMP/HwMlYEyzQLtS8YaC6LAuii08W9e9W6xhYhwHS4slbgSL0uIMa4Em1USbql41MsiI2lUD98rJ
  aqS+fAV38O8DiQM/U7whX6UNv9FVN/DslFQMeLDCF3o22SHVg3EbwH3MPoCHy6krACJaTmz5Tszv
  uq27Zl+XMk94cqJ/v/UgBJkx6Gt+dDBAAVgPbLSr4avg8+k+HrGgc33M75osaTiPQetMPZCFaaPE
  uCgWcDljBk7+0VPXlti9oRv3SclNYMzG19++DQFo8dYEvVle1zJR72e2+dwfx/zOGiZpFBE9b+ZB
  /KLcSACErUTaD6N+5/f03nsBwLDayEhFDb0sEZ4HcHwv/qmDGI8pHa519tqVun/RANDe5HopbguJ
  0uL02cnEN1ba+JT2oGuZkYEcauSiHd/c8yaBHmDg2J7+OwZGapr2ssMbfl6ZFenN39XnxP3jN0X9
  zh8SS98DWPffeTqZmgCIaDdY8wyy7jwp6p+gOzOWe1sHK2p4UUriN8Gk/wRmnE6S9LJDjfzNURc6
  Rfc4orQ4HfaCMKfDxsPjfvdvdZfuAnB4w991eMOtzPwXACfqHYeByUjyers30nyc56Vv6B0nGqxa
  PvSjgd8BYToIuq9008GsZwApgB6Vk1p927wJuh+QuHwhy9YETdWQmgPGkHQFx8zng2mS3RN+TGLL
  rGjTuO16xmnzuRMA5pV7W3/HrM1mws25dg+Yg1IAfk+a5S69x/2gYdWRb2egktNK4JkHqM9Viic8
  K9bX+RB8vS8J7n6btMiurnmSKHE3mH8JE85HM64AWmRJOjMWcP6iu6RSl/K68LlbOqR1DNwPpO/k
  P4xMhJ+zlNzs8EbmfssXGqB3oPbA+F3RoHOGnMSJRPnzjjj7+K8k8emxgOsGIyd/ubd1sN0baU5Z
  +B1mTEFGyri5FIQlSiL8Wrln1Ti9o8T9Z30Y8ztvBdMZALJTvHSY7GUcQpSYa6IB11KAdJ8AjpoV
  wyHLCzQNF2XpPOrH4HprQrrB4QnNGd4Xui9HtzU73wNwucMbbgZ4IYPOycYEch5hLRNq443ukJFh
  KhdttnZsj92qUWoWMY7JUvCnaZRsUTyh5yRKedoDk97VM0r3/hQTHWroYmaaD+C4bESfjSuAfQD5
  kOgzIhpwP6P35B9Ru2qg4gkHWZY3MHBRNg7O53EpE923JUFvKd7wj4yM1FVa7B5X7KXFBG6DJF0V
  s4bPije69J/8zOSoa/lJx/b4OwxqRtZO/sOmQnSpBstbDm9k7tDqN3QXhkX97j/b9u47CcQqkPll
  x5lMAAzC05pMJ8YCzrv1vmeHjyWHGv75Pim5CYRaAGbfQ48A8CfFG44otS1nGhmoiEuLdxGo2rp3
  /4mxxqonjJTuKmqL066G/8Wa9jQDFSbPqy+D60tKdm20q6Fr9L5W3rL4go6Y3x2wpPh4AA9nctlx
  ZhIAYa3MNC7md/2suzRSF4caHq8kIquZ8XBvXttkSRUkbbXDG/lDubdV9+XauiVjOuMB92+se/cd
  XEyy1+yJZQ53MGOBBHl4NOCcv2XxBR16RxpWGxmpeEPLwFo457ZzY9iJ6REl4X5lmBr+b73DtM1z
  vx8LuH5BmnYWgIysek1rAiDgfRBfb3SZbll9xKGo4SeZEQHw3UxMPF1TZvDlGlIbHJ7IQrv6su6H
  kVsWX7D7s9Ji6UF0PQ0vFMyEP1iAE+NB18zu/RZ0qagJHat4I79NSfwmQDledclnpRj/ULzhx8vu
  iDj0jtK17NjZteyYoPsL9UjS9RCwA8Aiq802d4tv7G69g5T5lvWT9g+sRoprkV8LbKxMfDux5RpF
  DQcsVl7U/Qqw1z5XWkwcBPB9sydnUFiCXN3uH2+oOnJo9fL+JbJ1ZlJCDZh1v5ExAQG4UurDFzvU
  SJATJfN13Q4TcRR4usy39i9Sxx4PGDUA+hoNzvAVAAHLYKFRsYCrVv/Jz1ReF5kiJQZsAOFu5NfJ
  f7jBYARTCbydltLigOsCScrP0mIC3maSJ8cCLnd7wMDJv3SprHjCN5RYrJtAuBuMfDr5DzeAme+B
  9cDG8rrIFIB1vZr83LJj0DMw+CpM9x8oMW+QNG1SNOC6SO/SWqBrma7DG27VNH4GoGFGJpMrGFTR
  XVq8RlFXOo2M1d7oeiluqzqTia/Nh9LirttATB1u41Pj/vEvGBnLoUYuUl4v/TcISwCUmT23NHFo
  Gj+jeCNhe13odL2DbG9wRqMB50+IycWs6d71yLR97oZWLy8tsfRpAOg65MqahIzhv5IEr9HtqZQZ
  r/RFn47pIHgB6nUpKknUEG10zvq6n3HUReayxvU6wvsU4PkdNsw3umGLoz40hlMUBDDByDh5IAXQ
  o5aUVm+kKM6IrCeAymkvWjv6970VoDsZ0F1PnYfSVuJqV9cMId4/G6Abe1NanKEEkCSih5CS5xid
  V4U3VJEkmgOmKwAu8C+FzxDRbgY3DLJ+694NvpOzuhN1VhOAXW29kDjZDNCIbH5ujtkLwvwOK88z
  /E05a8VwJGU/A5eiB7/LdCcAAp6HxPVGr2zKva2DNWIvWLsNgM3IWHmNsJVAd0T9zqwtGc5KAhhW
  GxmpSbyIgUnZmliuI+B9DXTX8TbtYSMr3QBAUVvPItbmM/hrS4vTmAD+BXBtLOAOG4m7ctqL1gMD
  +t6kMc1CZtZz5CUivMQkVccaq97M9Gdl9DLr8GW64uT/PAaOJfBvtyboTbvaauh9dsw/fk00UDWe
  iH8E0KZMxUyE91iiy2N+51mGTn5mKvOGLk/077deY1oIcfJ/DjPOhaatc3gj9xupLemJjCQAly9k
  cXjDN2tIbQZjGnJw67FcwcBI4tQyRQ2Fy72tBiraiKN+95+HftT/FAJuAWhnGsPcJZE0Y6D1WyfG
  G51LQQYWc3lWVClqyyoJ9Af0btOWYmNh8M3Els12NXT76KlrSzLyIZkYdMt+aSSIr4XI7D3H5NSg
  vap4w09ZwHe2BdxteobpXmf+60rfi0/s7+hfQ8wzobdghJAAY7HFxo1tvqqPjUyvrHblCFmWmpiz
  toqzUAwm0DU7Bu9dAWB9ugfPyBVALOhcH7OFxzLhauRpxxRzsATgyiTwjt0baa7whY7RO9IW3wW7
  437nnZBT3QtK0IsFJaQBeMLCPDIWcNW2+dwf641jaPXyUkWN3CdJ0ltdJ7/QU101FXR9zOoc3b1c
  OBOfkVllvrX9pP17qrtX8uVrhZ9ZdhGoYaDtvxYbfT2keCKjCHRcNFj1l6/7OYcaupiYo+2BCYaq
  D8t8a/vJib0zGOwBMNCEY5e/iPYRY2HCpgUy3QQna68BlfoVdtIsDcy4qpje8aYDEd5jolmxhqqn
  jdx/Z8VlS2VHZenVDNwDht3scPIMM+GZEmZV7y1gb2W9EMhRHxqDlHTv0V5ZCUeUltdvmeLwRs5j
  8DwAp5odSx5aLXNq5rbgxIw1uz0Sk0qBmexq+BJiCiJLWx8VEgKel4k9bX53TuwmpNS9fCpS8gKQ
  gR2Zi1cUYDUWcD1lZKs8vUzteV+5aLM1EY9PJ0JdkZUFG0ZAJ4gflpPwmVVH/tltHV+Fgl/PkXaf
  EnMTH7DqWx6cJqYmgIOGVi8v7VNim8OsXQ+QbHY8eeZTEJo7Ozvm72g+Pyu7CVX6Vg/qSCS8DNyO
  NKxJLzIpZhjabj6dciIBHKR4IqOIeIGoGtSBEAdwd+w7zocxhTKym9DoqWtLdgzecyMIdwLQ3Vi1
  iIVlSZq5rbHqNbMDOSinEsBBdjV0ITHNg4EuLkVsPRPXxf1uQ2vxP4/JXhu+hCSpAeATzJ5g3iFs
  Bag25nf+yexQvhxajjrs2+YuiIrCXiPCS8SaavR9vr02NJYkmg/gbLPnlIe66zh2LN7gm5LVZb49
  lbMJ4KByb+tgZm02JNzMLNpq9Q5pAOsqLe7tUmPhMwR0AvRgJ5HvfX/VB2bHc5RY84NdbT2BODUf
  QI7vBJuTEmAs6oPOxneD537ydT84tPofpSUlyXoCbhQJVw9+wUKoyZVXtEeTN6v04v7xmwBMLq8N
  n6tJmAfgNLNjyiM2EGo7pT7t6Oql+JX69EnexhrfltvlhjlpvQS+oz3gftnsQHoj797dtje5Xopt
  3TkajKlF1k1HyEm0k8A3Vdr4jHw7+YE8ugL4nGenpGLAgyNqV/1hn5RUGZiBYt5KSjBDB4BFffhA
  w7vBcz9Ja7eOLMrPBNBtY9O4TwHUVXhDS1KQAgzOUCtoQTiEQXhOYtnTHhivqxNwLsnrBHBQ9xPu
  n9jrV91LqeQCALr7sQnCVyKslTSe0R5wrzI7lHTJu2cAXyfeMG51zO88h4CfAbzN7HiEAkGIM/G1
  MWv4rPZg4Zz8QIFcAXzOoR5qy/4iJwbNYGJvHreTEsy1r3udRVO21llkW+ElgG7bfZP3AWioqAk9
  lJSpAcC1KLArHiFTugqoNJnqjLS3zwcFmwAO6l4q+wt7XWgxaVgIkMvsmISc1krgmdGA619mB5IN
  BZ8ADoo3uv8NwO1QQxczUxPEltTCYQjcprHkiQernjVjYw6zFN0lcdTv/rNt775RBK4G+BPjIwr5
  jIh2g0iVbRgZDzqXFtPJDxTRFcDhtiy+oAPAfLu65hFC4m4w/7JYj0URS4HwsJzUZpu1o1IuKOo/
  +rj/rA8B3DqsNnJ/SuL5AL5vdkxC5hHo7yxRTTZ67+W6ok4AB21rcr4N4AJHXcv5zFozGKeYHZOQ
  ERslRk170LnM7EByRdE9A/g60caq5ZVW/k5Xbz3k9DpuoVd2QaLbh340YFR70CVO/sOIK4Av6G7V
  /etyb+vTKWj1BJ4GQKyLz0MEdDLoAdhK7o75zv5I9Kj7MpEAvkJ7YPwuANWOmhUPsEVuAuPHZsck
  9ByB/6JRqjbun5SxdumFQCSAo4jOm7gVwCWK2uIkTVvAhDPMjkn4OvwGQ7ojFnCtNDuSfCCeAfRQ
  zF8Vib6780wiXAfA9P3chc871EnX5jojHnCKk7+HRALojWenpKJ+1+/7a5YTmWguANM6ugiH7CdQ
  Q8LGx8f8zofgo160QRfELYAO3RuR3FlWH1kiaRwA46cQG5Fk22GddF1tZgeTr0QCMKB7pdgVitq6
  CJxaALF3fpbwGpmlO7YFnFntpFuIRAJIg5h//Bowj3PUhy+HRn4GKsyOqSARomBWY37XU6DiqtnP
  FPEMIF2IONro/gN39DmJwLMA7DE7pALyKTHdiUSfEbGA+0lx8qePuAJIs+5Wz10bkVikuWD+OUSi
  1SvFhMekVG500i1EIgFkSPcKs+vtNaH7SJbmAzzB7JjyC0dkSb4jlzrpFiKRADIsPs/9bwATHZ6W
  HzJxk+iuexSHOum6cq6TbiESl6ZZEg1W/WWQ7b9GEWEmCB+bHU8O2kWg6kHWnSflYhvtQiWuALJo
  g+/kAwAW2NU1jxISPoBvRJH/DvKpk24hKuo/PrN0b0Qyrax25X2yJM1jYLLZMZmCeDkI1dFG11tm
  h1KsRAIw0famCRsBXOTwRs5j8DwAp5odUzYQ8DaD74j53X8zO5ZiJ54B5IBowPn32Bk7zwAVfMfj
  D4n51uE2PjUWECd/LhBXALliSlfH40rf6mcSHR0qwLeDC6bjcQeD7rPygXvyuZNuIRIJIMds8Y3d
  DUCt8P7ztyk6EGTmy5C/C40YhOcgU118rnOz2cEIXyYSQI5qC/x3G4DLHZ4V9zPJzQC+a3ZMvVKA
  nXQLkXgGkOOiwYktMVt4LBOuBpD729oR4kxUkJ10C5G4AsgHPp8WBx4v8619TurYUw1GLYD+Zof1
  BftAUnNn5zeadjSfVpCddAuRSAB5ZLtvzD4Ac5T6FQ+RZmlgxlUAm30VxwCe7OqkWyWe7+UZkQDy
  UKxhYhzAtY760H3QpHuZ+Rwz4iBCK6dS1bGmia+afUwEfUQCyGPRBvdagMfb1dZLiLUggOOy8bkE
  btMkyRNrLK5OuoXI7MtHwTDiuL/qj7Yy+0kgeAjIWMdjItrNQJ1sw8h4Y/F10i1E4gqgQGy57fgO
  AE1Dq5c/0qfENodZux4gOU3DH+qkGy3iTrqFSCSAArOj+fydAG5UPJH7IWkLwTTRyHhEeEnTtJp4
  YMIbZs9NSD+RAApULOhcD2CSXQ1dSEzzAJzYyyE2SYzq9oBoplnIxDOAAhf3u18Y+tGAU0GYzsCu
  o/28pvGHIEwf+u0Bp4hOuoIgCIIgCIXo/wO6o4LkzdaGFQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAy
  My0wNS0xM1QyMzo1MzozMCswMDowMO1LgXgAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMTNU
  MjM6NTM6MzArMDA6MDCcFjnEAAAAAElFTkSuQmCC" />
  </svg>

);

IconWebpack.propTypes = {
  customClass: PropTypes.string,
};

export default IconWebpack;
