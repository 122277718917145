import React from 'react';
import PropTypes from 'prop-types';

const IconBookmark = ({ customClass }) => (
  <svg className={customClass} xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 460.002 460.002">
    <title>Bookmark Link</title>
    <g>
      <g>
        <path d="M427.138,0C411.926,0,47.952,0,32.866,0C14.744,0,0.001,14.743,0.001,32.865v394.272c0,18.122,14.743,32.865,32.864,32.865h394.272c18.121,0,32.864-14.743,32.864-32.865V32.865C460.002,14.743,445.259,0,427.138,0zM241.368,30h128.993v171.091l-55.86-39.338c-2.59-1.824-5.613-2.736-8.637-2.736s-6.047,0.912-8.637,2.736l-55.859,39.337V30zM430.002,427.137L430.002,427.137c0,1.58-1.285,2.865-2.864,2.865H32.866c-1.58,0-2.864-1.285-2.864-2.865V32.865C30.001,31.285,31.286,30,32.866,30h178.503v200c0,5.601,3.12,10.735,8.092,13.314c4.973,2.58,10.967,2.174,15.545-1.05l70.859-49.901l70.859,49.901c4.586,3.23,10.584,3.625,15.545,1.05c4.972-2.58,8.092-7.714,8.092-13.314V30h26.777c1.579,0,2.864,1.285,2.864,2.865V427.137z" />
      </g>
    </g>
  </svg>
);

IconBookmark.propTypes = {
  customClass: PropTypes.string,
};

export default IconBookmark;
